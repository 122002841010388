import { Container, Form, Row } from "react-bootstrap";

import { useContext, useState } from "react";
import { langContext } from "../../context/LangProvider";

export default function Subscribe({ text, bgColor }) {
  const [email, setEmail] = useState("");
  const handleSumbitEmail = (e) => {
    e.preventDefault();
    console.log("submitted");
  };
  const { t } = useContext(langContext);
  return (
    <div className="footer-top" style={{ backgroundColor: bgColor }}>
      <Container>
        <Row>
          <div>
            <div dangerouslySetInnerHTML={{ __html: `<h1>${text}</h1>` }}></div>
            <Form
              onSubmit={(e) => handleSumbitEmail(e)}
              className="footer-cont"
            >
              <Form.Group className="control-cont">
                <Form.Control
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t("enterYourEmailAddress")}
                  type="email"
                />
              </Form.Group>
              <button type="submit" className="ms-2 pop-up">
                {t("subscribe")}
              </button>
            </Form>
          </div>
        </Row>
      </Container>
    </div>
  );
}
