import { Link, useNavigate } from "react-router-dom";

import { logout } from "../../../redux/auth";
import { useDispatch, useSelector } from "react-redux";
import useWindowWidth from "../../../Hooks/useWindowWidth";
import axios from "axios";
import { useContext } from "react";
import { langContext } from "../../../context/LangProvider";

export default function PatientNav({ title, tabTitle, imgs, onHide }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [screenWidth] = useWindowWidth();
  const currentTitle = window.location.pathname.slice(9);
  const { token } = useSelector((state) => state.user);
  const { t } = useContext(langContext);
  const select = async (link = "Logout") => {
    console.log("select===>", link);
    if (link === "dashboard") {
      navigate("/dashboard/profile");
    } else if (link === "Logout") {
      console.log(link);
      try {
        await axios.post(
          `${process.env.REACT_APP_AUTH_URL}/logout`,
          {},
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        dispatch(logout());
        navigate("/login");
      } catch (err) {
        console.log(err);
      }
    } else {
      navigate(`/profile/${link}`);
    }
  };
  return (
    <div className="patientNav">
      {screenWidth >= 768 && (
        <img
          src={
            title === "Logout"
              ? "/assets/logout.svg"
              : `${tabTitle === currentTitle ? imgs[0] : imgs[1]}.svg`
          }
          alt="logo"
          className="patientNav-img"
        />
      )}
      {screenWidth >= 768 ? (
        <button
          onClick={() => select(tabTitle)}
          className={`patientNavBtn ${title === "Logout" && "logout"} ${
            tabTitle === currentTitle ? "selected" : ""
          }`}
        >
          {t(title)}
        </button>
      ) : (
        <Link to={`profile/${tabTitle}`}>
          <button
            onClick={
              title === "Logout" ? () => dispatch(logout()) : () => onHide()
            }
            className={`patientNavBtn ${title === "Logout" && "logout"} ${
              tabTitle === currentTitle ? "selected" : ""
            }`}
          >
            {t(title)}
          </button>
        </Link>
      )}
    </div>
  );
}
