import { useContext } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { langContext } from "../../context/LangProvider";

import SearchDoctor from "./SearchDoctor";
import SearchTab from "./searchTab";

export default function Searchbar({ doctorslistPadding, activeTab, title }) {
  const { t, lang } = useContext(langContext);
  return (
    <Container className={`searchbar ${doctorslistPadding}`}>
      <div className="py-2">{title}</div>
      <Tabs defaultActiveKey={activeTab} className="mb-3">
        <Tab
          eventKey="appointment"
          title={t("bookAppointment")}
          className="tab-box"
        >
          <SearchTab />
        </Tab>

        <Tab eventKey="doctors" title={t("searchbyDoctor")} className="tab-box">
          <SearchDoctor />
        </Tab>
      </Tabs>
    </Container>
  );
}
