import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

import axios from "axios"

export const  getFaqs=createAsyncThunk(
    'faqs/getFaqs',
    async ()=> {
        let res=await axios(`${process.env.REACT_APP_URL}/faq`)
        return res.data.data
    }
)

const faqsSlice=createSlice({
    name:'faqs',
    initialState:{
        faqs:[],
        status:null
    },
    
    extraReducers:{
        [getFaqs.pending]:(state)=> {
            state.status='loading'
        },
        [getFaqs.fulfilled]:(state,action)=> {
            state.status='success'
            let sortedFaqs=action.payload.sort((a,b)=>a.order-b.order)
            state.faqs=sortedFaqs
        },
        [getFaqs.rejected]:(state)=> {
            state.status='failed'
        }
    }
})
export default faqsSlice.reducer
