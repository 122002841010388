import { Form, Offcanvas } from "react-bootstrap";
import { useEffect, useState } from "react";

export default function SearchCompMobile({
  data,
  placehold,
  loading,
  highlighter,
  all,
  allToggle,
  selectAll,
  selections,
}) {
  const [viewdData, setViewdData] = useState([]);
  const [search, setSearch] = useState("");
  const [serviceSelection, setServiceSelection] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (data) {
      setViewdData(data);
    }
  }, [data]);
  useEffect(() => {
    if (selections) {
      setServiceSelection(selections);
    }
  }, [selections]);

  const searchHandler = (e) => {
    let tmpSearchValue = e.target.value;
    setSearch(tmpSearchValue);
    const filteredSearch = data.filter((s) =>
      s.name.toLowerCase().startsWith(tmpSearchValue.toLowerCase())
    );
    setViewdData(filteredSearch);
  };
  const makeSelection = (item) => {
    highlighter(item, data);
    setSearch("");
  };

  console.log("selections", selections);
  return (
    <div className="search-select-box">
      <div onClick={handleShow}>
        <img className="icon" src="assets/search-sml.svg" alt="" />
        <img className="arrow" src="assets/arrow-down.svg" alt="" />
        <div className="selection-box">
          {serviceSelection.slice(0, 1).map((ser, i) => (
            <span key={i}>
              {ser.name.length > 20
                ? ser.name.substring(0, 20) + ".."
                : ser.name}
            </span>
          ))}
          {serviceSelection.length > 1 && (
            <span>+ {serviceSelection.length - 1}</span>
          )}
        </div>
        <button className="mobile-searchbar-button" type="button">
          {serviceSelection.length > 0 ? "." : placehold}
        </button>
      </div>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="top"
        name={placehold}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{placehold}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mobile-searchbar-selection-box">
            <Form.Group>
              <Form.Control
                value={search}
                placeholder={serviceSelection.length > 0 ? "" : placehold}
                type="text"
                onChange={searchHandler}
              />
            </Form.Group>
            <div className="selection-box">
              {serviceSelection.slice(0, 1).map((ser, i) => (
                <span key={i}>
                  {ser.name.length > 20
                    ? ser.name.substring(0, 20) + ".."
                    : ser.name}
                </span>
              ))}
              {serviceSelection.length > 1 && (
                <span>+ {serviceSelection.length - 1}</span>
              )}
            </div>
          </div>
          {all !== "All Areas" ? (
            viewdData.length === 0 ? (
              <h6 className="text-center">{loading}</h6>
            ) : (
              <>
                <h6
                  onClick={selectAll}
                  className={`${
                    allToggle ? "all-selected" : ""
                  } text-center my-2`}
                  style={{ cursor: "pointer" }}
                >
                  {all}
                </h6>

                <div>
                  {viewdData.map((item, i) => (
                    <div
                      className={
                        Array.isArray(selections)
                          ? selections?.includes(item)
                            ? "mobile-searchbar-item active"
                            : "mobile-searchbar-item"
                          : "mobile-searchbar-item active"
                      }
                      key={i}
                      onClick={() => makeSelection(item)}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              </>
            )
          ) : (
            <>
              <h6
                onClick={selectAll}
                className={`${
                  allToggle ? "all-selected" : ""
                } text-center my-2`}
                style={{ cursor: "pointer" }}
              >
                {all}
              </h6>

              <div>
                {viewdData.map((item, i) => (
                  <div
                    className={
                      selections.includes(item)
                        ? "mobile-searchbar-item active"
                        : "mobile-searchbar-item"
                    }
                    key={i}
                    onClick={() => makeSelection(item)}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}
