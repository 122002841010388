import { Container } from "react-bootstrap";
import React from "react";

export default function FullText({ description, bgColor }) {
  return (
    <div className="py-5" style={{ backgroundColor: bgColor }}>
      <Container>
        <div dangerouslySetInnerHTML={{ __html: description }}></div>
      </Container>
    </div>
  );
}
