import { areasHighlight, selectAllAreas, selectAreas } from "../../redux/areas";
import { citiesHighlight, selectAllCities } from "../../redux/cities";
import { selectAllServices, serviceHighlight } from "../../redux/specialities";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import DateSelection from "./DateSelection";
import { Form } from "react-bootstrap";
import SearchComp from "./searchComp";
import SearchCompMobile from "./SearchCompMobile";
import ServicesSearchComp from "./ServicesSearchComp";
import Spinner from "../Spinner";
import { clearParams, setSearchParams } from "../../redux/search";
import { useNavigate } from "react-router-dom";
import useWindowWidth from "./../../Hooks/useWindowWidth";
import { t } from "i18next";

export default function SearchTab() {
  console.log("nowaday");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [screenWidth] = useWindowWidth();
  const [firstRender, setfirstRender] = useState(true);
  const { cities, citiesSelection, allCities } = useSelector(
    (state) => state.cities
  );
  const { services } = useSelector((state) => state.services);

  const { specialities, servicesSelection, allServices } = useSelector(
    (state) => state.specialities
  );
  const { areas, areasSelection, allAreas } = useSelector(
    (state) => state.areas
  );
  const [filters, setfilters] = useState({
    citiesSelection: [],
    areasSelection: [],
    servicesSelection: [],
  });
  useEffect(() => {
    dispatch(clearParams([null, null]));
  }, [dispatch]);

  const [areasData, setAreasData] = useState([]);
  const [activeDate, setActiveDate] = useState([null, null]);
  const [dateWarning, setDateWarning] = useState("");
  useEffect(() => {
    if (!firstRender) setAreasData(areas);
  }, [cities, areas]);

  useEffect(() => {
    citiesSelection.length > 0 && chooseAreasCaseDecider(citiesSelection);
  }, [citiesSelection]);
  const chooseAreasCaseDecider = (city) => {
    if (citiesSelection.length >= 1) {
      chooseAreas(citiesSelection, "multiple");
      return;
    }
    chooseAreas(city, "one");
  };
  const chooseAreas = (city, modifier) => {
    switch (modifier) {
      case "one":
        dispatch(selectAreas({ areas: city.areas }));
        break;
      case "multiple":
        dispatch(selectAreas({ areas: city.map((city) => city.areas).flat() }));
        break;
      case "all":
        dispatch(selectAreas({ areas: city.map((city) => city.areas).flat() }));
        break;
      default:
        dispatch(selectAreas({ areas: city.areas }));
        break;
    }
  };
  const handleSearch = (e) => {
    setDateWarning("");
    dispatch(clearParams([null, null]));
    e.preventDefault();
    const activeServices = services
      .filter((service) => service.active)
      .map((ele) => ele);
    const activeCities = cities.filter((city) => city.active).map((ele) => ele);
    const activeAreas = areas.filter((area) => area.active).map((ele) => ele);
    let searchParams = {
      services: activeServices,
      cities: activeCities,
      areas: activeAreas,
      date: activeDate,
    };
    if (
      (!activeDate[0] && activeDate[1]) ||
      (activeDate[0] && !activeDate[1])
    ) {
      setDateWarning("Please select start & end date");
    } else {
      if (!activeDate[0] && !activeDate[1]) {
        dispatch(setSearchParams(searchParams));
        navigate("/doctors");
      } else {
        if (
          new Date().getDate() > activeDate[1].getDate() ||
          new Date().getDate() > activeDate[0].getDate()
        ) {
          setDateWarning("Choose date at least Today");
        } else {
          dispatch(setSearchParams(searchParams));
          navigate("/doctors");
        }
      }
    }
  };

  console.log(firstRender);
  const selectDate = (date) => {
    setActiveDate(date);
  };
  useEffect(() => {
    console.log(window.location.pathname, window.location.pathname === "/");
    if (window.location.pathname === "/") setfirstRender(false);
  }, []);
  useEffect(() => {
    if (!firstRender)
      setfilters({ areasSelection, servicesSelection, citiesSelection });
  }, [areasSelection, servicesSelection, citiesSelection]);

  return (
    <Form className="search-select-container" onSubmit={handleSearch}>
      {screenWidth >= 768 ? (
        <>
          <ServicesSearchComp
            data={specialities}
            placehold={t("selectServices")}
            loading={<Spinner height="80" />}
            highlighter={(service, speciality, data) => {
              dispatch(serviceHighlight({ data, speciality, service }));
            }}
            all={t("allServices")}
            selectAll={() => dispatch(selectAllServices())}
            allToggle={allServices}
            selections={filters.servicesSelection}
          />

          <SearchComp
            data={cities}
            placehold={t("selectCity")}
            loading={<Spinner height="80" />}
            highlighter={(city, data) => {
              dispatch(citiesHighlight({ data, city }));
              chooseAreasCaseDecider(city);
            }}
            all={t("allCities")}
            allToggle={allCities}
            selectAll={() => {
              dispatch(selectAllCities());
              chooseAreas(cities, "all");
            }}
            selections={filters.citiesSelection}
          />
          <SearchComp
            data={areasData}
            placehold={t("selectAreas")}
            loading=""
            highlighter={(area, data) => {
              dispatch(areasHighlight({ data, area }));
            }}
            selectAll={() => dispatch(selectAllAreas())}
            all={t("allAreas")}
            allToggle={allAreas}
            selections={filters.areasSelection}
          />
        </>
      ) : (
        <>
          <SearchCompMobile
            data={services}
            placehold={t("selectServices")}
            loading={<Spinner height="80" />}
            highlighter={(service, data) => {
              dispatch(serviceHighlight({ data, service }));
            }}
            selectAll={() => dispatch(selectAllServices())}
            all={t("allServices")}
            allToggle={allServices}
            selections={servicesSelection}
          />
          <SearchCompMobile
            data={cities}
            placehold={t("selectCity")}
            loading={<Spinner height="80" />}
            highlighter={(city, data) => {
              dispatch(citiesHighlight({ data, city }));
              chooseAreasCaseDecider(city);
            }}
            all={t("allCities")}
            selectAll={() => {
              dispatch(selectAllCities());
              chooseAreas(cities, "all");
            }}
            allToggle={allCities}
            selections={!firstRender && citiesSelection}
          />
          <SearchCompMobile
            data={areasData}
            placehold={t("selectArea")}
            loading=""
            selectAll={() => dispatch(selectAllAreas())}
            highlighter={(area, data) => {
              dispatch(areasHighlight({ data, area }));
            }}
            all={t("allAreas")}
            allToggle={allAreas}
            selections={areasSelection}
          />
        </>
      )}

      <DateSelection selectDate={selectDate} error={dateWarning} />
      <button className="pop-up search">
        <img
          src={
            screenWidth >= 1024
              ? "/assets/search-lrg.svg"
              : "/assets/search-lrg2.svg"
          }
          alt=""
        />
        <span className="bold">{screenWidth >= 1024 ? "" : " Search"}</span>
      </button>
    </Form>
  );
}
