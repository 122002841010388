import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import produce from "immer";

export const getDoctors = createAsyncThunk("doctors/getDoctors", async () => {
  let res = await axios(`${process.env.REACT_APP_URL}/doctors`);
  const doctors = res.data.data.map((doctor) => {
    doctor.services.map((service) => (service.count = 0));
    return doctor;
  });
  return doctors;
});

const doctorsSlice = createSlice({
  name: "doctors",
  initialState: {
    doctors: [],
    filteredDoctors: [],
    secondPhaseFilter: [],
    filterParams: {
      specialities: [],
      availabilities: [],
      gender: [],
      title: [],
      entity: [],
      patientCondition: [],
    },
    status: null,
  },
  reducers: {
    sortDoctors: (state, action) => {
      if (state.filterParams.specialities.length ||
        state.filterParams.gender.length ||
        state.filterParams.title.length ||
        state.filterParams.entity.length ||
        state.filterParams.availabilities.length ||
        state.filterParams.patientCondition.length) { //if any of these is true that means the user has checked the filter by , and we will sort the "secondPhaseFilter" array because that's where the data goes
        if (action.payload === 'lth') {
          console.log('lth');

          state.secondPhaseFilter = [...state.secondPhaseFilter].sort((a, b) => a.examination_fee - b.examination_fee)
        }
        else if (action.payload === 'htl') {
          console.log('htl');

          state.secondPhaseFilter = [...state.secondPhaseFilter].sort((a, b) => b.examination_fee - a.examination_fee)
        }
        else if (action.payload === 'wt') {
          state.secondPhaseFilter = [...state.secondPhaseFilter].sort((a, b) => a.waiting_time.split(' ')[0] - b.waiting_time.split(' ')[0])
          console.log('waiting time');

        }
      }
      else {
        if (action.payload == 'lth') {
          console.log('lth');

          state.filteredDoctors = [...state.filteredDoctors].sort((a, b) => a.examination_fee - b.examination_fee)
        }
        else if (action.payload == 'htl') {
          console.log('htl');

          state.filteredDoctors = [...state.filteredDoctors].sort((a, b) => b.examination_fee - a.examination_fee)
        }
        else if (action.payload == 'wt') {
          console.log('waiting time');
          state.filteredDoctors = [...state.filteredDoctors].sort((a, b) => a.waiting_time.split(' ')[0] - b.waiting_time.split(' ')[0])
        }
      }
    },
    setDoctors: (state, action) => {
      state.filteredDoctors = action.payload;
    },
    set2ndPhaseDocs: (state, action) => {
      state.secondPhaseFilter = action.payload;
    },
    setFilterParams: (state, action) => {
      state.filterParams = action.payload;
    },
    editDoctorServiceCount: (state, action) => {
      const index1 = action.payload.doctors.findIndex(
        (doctor) => doctor.id === action.payload.dataId
      );
      const index2 = action.payload.doctors[index1].services.findIndex(
        (service) => service.id === action.payload.serviceId
      );
      let newDoctors = produce(state.doctors, (draft) => {
        draft[index1].services[index2].count = parseInt(
          action.payload.serviceCount
        );
      });
      state.doctors = newDoctors;
    },
  },
  extraReducers: {
    [getDoctors.pending]: (state) => {
      state.status = "loading";
    },
    [getDoctors.fulfilled]: (state, action) => {
      state.status = "success";
      state.doctors = action.payload;
    },
    [getDoctors.rejected]: (state) => {
      state.status = "failed";
    },
  },
});
export default doctorsSlice.reducer;
export const {
  setDoctors,
  set2ndPhaseDocs,
  setFilterParams,
  editDoctorServiceCount,
  sortDoctors
} = doctorsSlice.actions;
