import { Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { getDoctors } from "../../../redux/doctors";
import { toast } from "react-toastify";
import { useState } from "react";

export default function ReviewModal(props) {
  const [review, setReview] = useState("");
  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const submitReview = async () => {
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_URL}/submit_review`,
        {
          rate: props.rating,
          comment: review,
          appointment_id: props.appointment_id,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.data.message) toast.success("Review Submitted Successfully");
      dispatch(getDoctors());
      setReview("");
      props.onHide();
      props.setSubmitted(true);
    } catch (err) {
      if (err.response.data.errors) {
        console.log(err.response.data.errors);
      }
    }
  };
  return (
    <Modal
      {...props}
      className="doc-services"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Review</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center my-2">
        <Form.Control
          as="textarea"
          rows="5"
          value={review}
          onChange={(e) => setReview(e.target.value)}
          placeholder="Write Your review here (required)"
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          className="pop-up"
          style={{ padding: "0.5rem 1rem", borderRadius: "0.4rem" }}
          onClick={submitReview}
        >
          Submit
        </button>
        <button
          className="pop-up"
          style={{ padding: "0.5rem 1rem", borderRadius: "0.4rem" }}
          onClick={props.onHide}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
