import { Container } from "react-bootstrap";
import ScanLabs from "../components/AllLabs/labs";
import { useSelector } from "react-redux";

export default function LabsPage() {
  const { labs } = useSelector((state) => state.labs);

  const title = `${labs.length || 0} Recommended Labs`;
  return (
    <div className=" p-top-100">
      <Container>
        <ScanLabs numPerPage={10} title={title} />
      </Container>
    </div>
  );
}
