import { Form, Spinner } from "react-bootstrap";
import {
  clearCart,
  resetCurrentAppointment,
  successfullBooking,
} from "../../redux/cart";
import { editDoctorServiceCount, getDoctors } from "../../redux/doctors";
import { editLabServiceCount, getLabs } from "../../redux/labs";
import { getIntervalsBetweenShifts, isItToday } from "doitfast";
import {
  getLocationsOfCurrentDetailsPage,
  getProviderDetails,
} from "../../Helpers/getProviderDetails";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BookAgainLocation from "./BookAgainLocation";
import BookAgainStatus from "./BookAgainStatus";
import BookingCardAddedServices from "../BookingCardAddedServices";
import CardBody from "./CardBody";
import CardTitle from "./CardTitle";
import Confirmation from "../SingleDoctor/services/confirmation";
import GoogleFaceBook from "../auth/GoogleFaceBook";
import LoginForm from "../auth/LoginForm";
import OrLine from "../auth/OrLine";
import SelectLocation from "./SelectLocation";
import arrangeDaysFromToday from "../../Helpers/arrangDaysFromToday";
import axios from "axios";
import { bookAgain } from "../../redux/cart";
import { getMyAppointments } from "../../redux/myappointments";
import { toast } from "react-toastify";
import { appendItem } from "../../Helpers/appendItem";
import { useDropzone } from "react-dropzone";
import { CCard, CCardBody, CCardHeader } from "@coreui/react";
import moment from "moment-timezone";

export default function OfferCard({
  overLay,
  data,
  dataName,
  confirmation,
  handleConfirm,
  isShowingModal,
  myAppointment,
}) {
  const formData = [
    {
      label: "E-Mail",
      placeholder: "E-Mail Address",
      type: "email",
      name: "email",
    },
    {
      label: "Password",
      placeholder: "Password",
      type: "password",
      name: "password",
    },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginStatus, token, user, doctor } = useSelector(
    (state) => state.user
  );
  const { myAppointments } = useSelector((state) => state.myAppointments);
  const { cart, fees, feesWithNoDiscount, dataExistId, currentAppointment } =
    useSelector((state) => state.cart);
  const { doctors } = useSelector((state) => state.doctors);
  const { labs } = useSelector((state) => state.labs);
  const { id } = useParams();
  const [dataInput, setDataInput] = useState("d-none");
  const [appointmentVisibilty, setappointmentVisibilty] = useState("d-none");
  const [bookedServices, setBookedServices] = useState([]);
  const [viewdAppointments, setViewdAppointments] = useState([]);
  const [viewdDays, setViewdDays] = useState([]);
  const [notes, setNotes] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedAppointment, setSelectedAppointment] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [labAppointMent, setLabAppointMent] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [options, setOptions] = useState([]);
  const [disabledSelection, setDisabledSelection] = useState(false);
  const [sendingAppointment, setSendingAppointment] = useState(false);
  const [sendingAppointmentText, setSendingAppointmentText] =
    useState("Book Appointment");
  // initial selected Day & Date

  const [images, setImages] = useState([]);
  const onPictureDrop = useCallback(
    (acceptedFiles) => {
      setImages(acceptedFiles);
    },
    [images]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onPictureDrop,
  });
  useEffect(() => {
    if (viewdDays) {
      setSelectedDay(
        new Date(viewdDays[0]?.date).toLocaleString("default", {
          weekday: "long",
        })
      );

      setSelectedDate(new Date(viewdDays[0]?.date).toLocaleDateString());
    }
  }, [viewdDays]);
  console.log("OfferCard", data);

  useEffect(() => {
    console.log("useEffect", data);
    // Getting the data depending on the current page (doctor/lab)
    let dataDetails;
    // if (dataName === "doctor") {
    dataDetails = { ...data?.provider, locations: data?.locations };
    console.log("dataDetails", dataDetails);
    // } else {
    // dataDetails = getProviderDetails(dataName, id, dataExistId, labs);
    // }
    // Getting the locations of data(doctor/lab)

    dataDetails &&
      setOptions(
        getLocationsOfCurrentDetailsPage(
          dataDetails,
          dataName,
          setappointmentVisibilty,
          setViewdDays,
          setSelectedLocation,
          setViewdAppointments
        )
      );
  }, [dataExistId, dataName, id, doctors]);

  console.log("options>>>", options);
  useEffect(() => {
    setBookedServices(cart);
  }, [cart, data]);
  useEffect(() => {}, [bookedServices]);
  useEffect(() => {
    if (loginStatus) {
      setDataInput("d-none");
    }
  }, [loginStatus]);

  const handleChooseLocation = (selection) => {
    setappointmentVisibilty("");
    let sortedAppointments = arrangeDaysFromToday([
      ...selection.location.availabilities.filter(
        (availability) => availability.closed === false
      ),
    ]);
    if (sortedAppointments.length) {
      let isToday = isItToday(sortedAppointments[0].date);
      if (dataName === "lab") {
        if (
          isToday &&
          +sortedAppointments[0].close_hour.substring(0, 2) <
            new Date().getHours() + 1
        ) {
          sortedAppointments = sortedAppointments.slice(1);
        }
        isToday = false;
      }
      let viewdAppointments;
      if (sortedAppointments.length) {
        viewdAppointments = getIntervalsBetweenShifts(
          sortedAppointments[0].open_hour,
          sortedAppointments[0].close_hour,
          "00:30",
          isToday
        );
      } else {
        viewdAppointments = [];
      }
      setViewdAppointments(viewdAppointments);
      setViewdDays(sortedAppointments);
    } else {
      setViewdAppointments([]);
      setViewdDays([]);
    }
    setSelectedLocation(selection);
  };

  const appointmentHandler = (selection) => {
    if (bookedServices.length > 0) {
      overLay("");
      setappointmentVisibilty("d-none");
      setDisabledSelection(true);
      setDataInput("");

      if (dataName === "doctor") {
        setSelectedAppointment(selection);
      } else {
        const startShift = viewdAppointments.find(
          (appointment) =>
            appointment.includes("AM") && appointment === viewdAppointments[0]
        );
        const endShift = viewdAppointments.find(
          (appointment) =>
            appointment.includes("PM") &&
            appointment === viewdAppointments[viewdAppointments.length - 1]
        );
        setSelectedAppointment(startShift);
        setLabAppointMent(`${startShift} - ${endShift}`);
      }
    } else {
      toast.error("Please select at least one service");
    }
  };

  const cancel = () => {
    setDataInput("d-none");
    overLay("d-none");
    setappointmentVisibilty("");
    setSelectedAppointment("");
    setDisabledSelection(false);
  };

  const bookAgainHandler = () => {
    dispatch(bookAgain(currentAppointment));
    if (dataName === "doctor") {
      currentAppointment.services.forEach((service) => {
        dispatch(
          editDoctorServiceCount({
            dataId: data?.provider.id,
            serviceCount: parseInt(service.pivot.quantity),
            serviceId: service.id,
            doctors,
          })
        );
      });
    } else {
      currentAppointment.services.forEach((service) => {
        dispatch(
          editLabServiceCount({
            dataId: data.provider.id,
            serviceCount: parseInt(service.pivot.quantity),
            serviceId: service.id,
            labs,
          })
        );
      });
    }
    navigate(window.location.pathname.replace("myAppointment", ""));
    dispatch(resetCurrentAppointment());
  };
  const bookAppointmentHandler = async () => {
    // if (
    //   myAppointments?.filter((appointment) => appointment.status !== 2).length >=
    //   1
    // ) {
    //   toast.error("You Already Have An Appointment Cancel/Attend it First");
    //   return;
    // }
    let selectedServices = [];
    cart.map((ele) => {
      const { doctor_id, lab_id, created_at, updated_at, ...serviceProps } =
        ele.service.pivot;
      serviceProps.quantity = ele.count;
      selectedServices.push(serviceProps);
    });
    setSendingAppointment(true);
    setSendingAppointmentText(<Spinner animation="border" size="sm" />);
    try {
      console.log(data.services);
      let formData = new FormData();
      let values = {
        // gallery: images,
        new_gallery: images.length > 0 ? images : [""],
        fees: data.total,
        user_id: user?.id,
        provider_id: data?.provider?.id,
        provider_type: dataName,
        patient_notes: notes,
        services: data?.services?.map((service) => {
          return {
            service_id: service.id,
            fees: service.pivot.fees,
            package: service?.pivot?.fees,
          };
        }),
        location_id: selectedLocation?.id,
        time: `${selectedDay} , ${selectedDate} , ${selectedAppointment}`,
        timezone: moment.tz.guess(),
      };
      Object.keys(values).forEach((key) => {
        appendItem(formData, key, values[key]);
      });
      let res = await axios.post(
        `${process.env.REACT_APP_URL}/book_an_appointment`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (res.data.success) toast.success("Appointment created successfully");
      let dataDetails;
      if (dataName === "doctor") {
        dataDetails = getProviderDetails(dataName, id, dataExistId, doctors);
      } else {
        dataDetails = getProviderDetails(dataName, id, dataExistId, labs);
      }
      dispatch(
        successfullBooking({
          fees: data?.total,
          feesWithNoDiscount: data.subtotal,
          data,
          waiting_time: data.waiting_time,
          dataName,
          phone: dataName === "lab" ? dataDetails.phone : "",
          workingHours: labAppointMent,
          date: `${selectedDay} , ${selectedDate} , ${selectedAppointment}`,
          locations: data?.locations?.length > 1 ? options : [selectedLocation],
        })
      );
      dispatch(clearCart());
      dispatch(getDoctors()); // to reset services count
      dispatch(getLabs()); // to reset services count
      dispatch(getMyAppointments()); // to update myAppointments
      navigate("/success");
    } catch (err) {
      console.log(err);
    } finally {
      setSendingAppointment(false);
      setSendingAppointmentText("Book Appointment");
    }
  };
  return (
    <div className="booking-card">
      {/* {JSON.stringify(viewdDays)} */}
      <CardTitle dataName={dataName} isShowingModal={isShowingModal} />
      {!confirmation ? (
        <>
          <BookingCardAddedServices
            bookedServices={bookedServices}
            fees={data?.total}
            feesWithNoDiscount={data?.subtotal}
            myAppointment={myAppointment}
          />
          {myAppointment && (
            <BookAgainStatus currentAppointment={currentAppointment} />
          )}
          <div className="booking-item d-flex align-items-center">
            {false ? (
              <BookAgainLocation
                options={options}
                currentAppointment={currentAppointment}
              />
            ) : (
              <SelectLocation
                options={options}
                handleChooseLocation={handleChooseLocation}
                disabledSelection={disabledSelection}
              />
            )}
          </div>
          <div className="booking-item ">
            {myAppointment ? (
              <>
                <img
                  src="/assets/edit-inactive.svg"
                  alt=""
                  className="mx-2 icon-img-size"
                />
                <span className="me-1">Doctor Note :</span>
                <span className="bold">{currentAppointment.patient_notes}</span>
              </>
            ) : (
              <>
                <img
                  src="/assets/booking.svg"
                  alt=""
                  className="mx-2 icon-img-size"
                />
                <span className="me-1">Selected Appointment :</span>
                {selectedAppointment !== "" && dataName === "doctor" ? (
                  <span className="bold">{`${selectedDay} , ${selectedDate} , ${selectedAppointment}`}</span>
                ) : (
                  selectedAppointment !== "" &&
                  dataName === "lab" && (
                    <span className="bold">{`${selectedDay} , ${selectedDate} , ${labAppointMent}`}</span>
                  )
                )}
              </>
            )}
          </div>
          <div className="booking-item ">
            <img
              src="/assets/waiting-time.svg"
              alt=""
              className="mx-2 icon-img-size"
            />
            <span className="me-1">
              {dataName === "doctor" ? "Waiting Time: " : "Working Hours "}
            </span>
            {dataName === "doctor" && (
              <span className="bold">{data?.provider?.waiting_time}</span>
            )}
          </div>
          {myAppointment && (
            <div className="booking-item">
              <div className="d-flex justify-content-center">
                <button className="mb-3 w-60 pop-up" onClick={bookAgainHandler}>
                  Book Again
                </button>
              </div>
            </div>
          )}
          {!myAppointment && (
            <div className={`booking-item no-bottom-border slidz `}>
              <CardBody
                dataName={dataName}
                viewdAppointments={viewdAppointments}
                viewdDays={viewdDays}
                setSelectedDay={setSelectedDay}
                setViewdAppointments={setViewdAppointments}
                setSelectedDate={setSelectedDate}
                appointmentHandler={appointmentHandler}
                isShowingModal={isShowingModal}
                data={data}
                confirmation={confirmation}
                handleConfirm={handleConfirm}
              />
            </div>
          )}
          {loginStatus && bookedServices.length ? (
            <div className={`${dataInput} p-3`}>
              <Form.Control
                as="textarea"
                rows="5"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Write Your Notes here (optional)"
              />

              <div className="d-flex justify-content-center my-2">
                <CCard>
                  <CCardHeader>Submit images</CCardHeader>
                  <CCardBody>
                    <div
                      {...getRootProps()}
                      className="p-4 bg-light border border-dark border-dashed"
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p className="m-0 text-center">Drop files here</p>
                      ) : (
                        <p className="m-0 text-center">
                          Drag file or click to upload file
                        </p>
                      )}
                    </div>
                    {images?.map((image) => {
                      return (
                        <img
                          className="m-2"
                          height="50"
                          src={
                            image instanceof File
                              ? URL.createObjectURL(image)
                              : typeof image === "string"
                              ? image
                              : image
                          }
                          alt="item"
                        />
                      );
                    })}
                  </CCardBody>
                </CCard>
              </div>

              <div className="mail-phone">
                <button
                  className="mb-3 w-60 pop-up"
                  onClick={bookAppointmentHandler}
                  disabled={sendingAppointment}
                >
                  {sendingAppointmentText}
                </button>
                <button className="mb-3 w-30" type="button" onClick={cancel}>
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <div className={`booking-item no-bottom-border ${dataInput}`}>
              <GoogleFaceBook />
              <div>
                <OrLine />
                <LoginForm
                  formData={formData}
                  formPadding="bookingFormPadding"
                  formWidth="bookingFormWidth"
                  dataName={dataName}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <Confirmation handleConfirm={handleConfirm} />
      )}
    </div>
  );
}
