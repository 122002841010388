import { CButton, CCard, CCardBody, CCardHeader } from "@coreui/react";

import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import UserService from "../../services/userService";

export default function ProfileImages({ doctor, setDoctor }) {
  const onPictureDrop = useCallback(
    (acceptedFiles) => {
      setDoctor({
        ...doctor,
        profile_picture: acceptedFiles[0],
      });
    },
    [doctor]
  );

  const onPersonalGalleryDrop = useCallback(
    (acceptedFiles) => {
      setDoctor({
        ...doctor,
        new_personal_gallery: acceptedFiles,
      });
    },
    [doctor]
  );

  const onEntityGalleryDrop = useCallback(
    (acceptedFiles) => {
      setDoctor({
        ...doctor,
        new_entity_gallery: acceptedFiles,
      });
    },
    [doctor]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onPictureDrop,
  });

  const {
    getRootProps: getPersonalGalleryRootProps,
    getInputProps: getPersonalGalleryInputProps,
    isDragActive: isPersonalGalleryDragActive,
  } = useDropzone({ accept: "image/*", onDrop: onPersonalGalleryDrop });

  const {
    getRootProps: getEntityGalleryRootProps,
    getInputProps: getEntityGalleryInputProps,
    isDragActive: isEntityGalleryDragActive,
  } = useDropzone({ accept: "image/*", onDrop: onEntityGalleryDrop });
  const removeImageFromGallery = (gallery, image_index) => {
    let tmpGallery = [...doctor[gallery]];
    tmpGallery.splice(image_index, 1);
    setDoctor({ ...doctor, [gallery]: tmpGallery });
  };
  return (
    <div className="profile-images">
      <CCard>
        <CCardHeader>
          {(UserService.checkUserRole("Doctor") && "Doctor Picture") || "Logo"}
        </CCardHeader>
        <CCardBody>
          <div
            {...getRootProps()}
            className="p-4 bg-light border border-dark border-dashed"
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p className="m-0 text-center">Drop files here</p>
            ) : (
              <p className="m-0 text-center">
                Drag file or click to upload file
              </p>
            )}
          </div>
          {doctor?.profile_picture && (
            <img
              className="m-2"
              height="50"
              src={
                doctor?.profile_picture instanceof File
                  ? URL.createObjectURL(doctor?.profile_picture)
                  : typeof doctor?.profile_picture === "string"
                  ? doctor?.profile_picture
                  : doctor?.profile_picture.original_url
              }
              alt="item"
            />
          )}
        </CCardBody>
      </CCard>
      {UserService.checkUserRole("Doctor") && (
        <CCard>
          <CCardHeader>Doctor Gallery</CCardHeader>
          <CCardBody>
            <div
              {...getPersonalGalleryRootProps()}
              className="p-4 bg-light border border-dark border-dashed"
            >
              <input {...getPersonalGalleryInputProps()} />
              {isPersonalGalleryDragActive ? (
                <p className="m-0 text-center">Drop files here</p>
              ) : (
                <p className="m-0 text-center">
                  Drag file or click to upload file
                </p>
              )}
            </div>
            <div className="images-wrapper">
              {doctor?.personal_gallery &&
                doctor?.personal_gallery.map((gallery_image, i) => (
                  <CButton
                    key={i}
                    className="image-with-remove-icon"
                    onClick={(e) => {
                      e.preventDefault();
                      removeImageFromGallery("personal_gallery", i);
                    }}
                  >
                    <img
                      height="50"
                      src={gallery_image.original_url}
                      alt="item"
                    />
                  </CButton>
                ))}
              {doctor?.new_personal_gallery &&
                doctor?.new_personal_gallery.map((gallery_image, i) => (
                  <CButton
                    key={i}
                    className="image-with-remove-icon"
                    onClick={(e) => {
                      e.preventDefault();
                      removeImageFromGallery("new_personal_gallery", i);
                    }}
                  >
                    <img
                      height="50"
                      src={URL.createObjectURL(gallery_image)}
                      alt="item"
                    />
                  </CButton>
                ))}
            </div>
          </CCardBody>
        </CCard>
      )}
      <CCard>
        <CCardHeader>
          {UserService.checkUserRole("Doctor") && "Clinic"} Gallery
        </CCardHeader>
        <CCardBody>
          <div
            {...getEntityGalleryRootProps()}
            className="p-4 bg-light border border-dark border-dashed"
          >
            <input {...getEntityGalleryInputProps()} />
            {isEntityGalleryDragActive ? (
              <p className="m-0 text-center">Drop files here</p>
            ) : (
              <p className="m-0 text-center">
                Drag file or click to upload file
              </p>
            )}
          </div>
          <div className="images-wrapper">
            {doctor?.entity_gallery &&
              doctor?.entity_gallery.map((gallery_image, i) => (
                <CButton
                  key={i}
                  className="image-with-remove-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    removeImageFromGallery("entity_gallery", i);
                  }}
                >
                  <img
                    height="50"
                    src={gallery_image.original_url}
                    alt="item"
                  />
                </CButton>
              ))}
            {doctor?.new_entity_gallery &&
              doctor?.new_entity_gallery.map((gallery_image, i) => (
                <CButton
                  key={i}
                  className="image-with-remove-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    removeImageFromGallery("new_entity_gallery", i);
                  }}
                >
                  <img
                    height="50"
                    src={URL.createObjectURL(gallery_image)}
                    alt="item"
                  />
                </CButton>
              ))}{" "}
          </div>
        </CCardBody>
      </CCard>
    </div>
  );
}
