import { useDispatch, useSelector } from "react-redux";

import getCommon from "../Helpers/getCommonInArray";
import { setDoctors } from "../redux/doctors";
import { useEffect } from "react";

export default function useSearchHelper(servicesFilter) {
  const dispatch = useDispatch();
  const { doctors, filteredDoctors } = useSelector((state) => state.doctors);
  const { searchParams } = useSelector((state) => state.searchParams);

  const handleFilter = () => {
    let filterArrayByService = [];
    let filterArrayByArea = [];
    let filterArrayByCity = [];
    let filterArrayByDate = [];

    if (searchParams.services.length) {
      searchParams.services.map((service) => {
        doctors.map((doc) => {
          let matchingService = doc.services.find(
            (serv) => serv.id === service.id
          );
          if (matchingService) {
            filterArrayByService.push(doc);
          }
        });
      });
    } else {
      filterArrayByService = doctors;
    }

    if (searchParams.areas.length) {
      searchParams.areas.map((area) => {
        doctors.map((doc) => {
          let matchingLocation = doc.locations.find(
            (loc) => loc.area.id === area.id
          );
          if (matchingLocation) {
            filterArrayByArea.push(doc);
          }
        });
      });
    } else {
      filterArrayByArea = doctors;
    }

    if (searchParams.cities.length) {
      searchParams.cities.map((city) => {
        doctors.map((doc) => {
          let matchingLocation = doc.locations.find(
            (loc) => loc.area.city_id == city.id
          );
          if (matchingLocation) {
            filterArrayByCity.push(doc);
          }
        });
      });
    } else {
      filterArrayByCity = doctors;
    }
    if (searchParams.date[0] && searchParams.date[1]) {
      console.log(searchParams.date[1].getDate());
      doctors.map((doc) => {
        doc.locations.forEach((loc) => {
          loc.availabilities.forEach((availability) => {
            if (
              new Date(availability.date).getDate() >=
                searchParams.date[0].getDate() &&
              new Date(availability.date).getDate() <=
                searchParams.date[1].getDate()
            ) {
              filterArrayByDate.push(doc);
            }
          });
        });
      });
    } else {
      filterArrayByDate = doctors;
    }

    let finalFilter;
    if (
      //filter by Services
      servicesFilter
    )
      finalFilter = filteredDoctors;

    finalFilter = getCommon(filterArrayByService, filterArrayByArea);
    finalFilter = getCommon(finalFilter, filterArrayByCity);
    finalFilter = getCommon(finalFilter, filterArrayByDate);
    finalFilter = Array.from(new Set(finalFilter));
    dispatch(setDoctors(finalFilter));
  };
  useEffect(() => {
    if (
      searchParams.services.length > 0 ||
      searchParams.areas.length > 0 ||
      searchParams.cities.length > 0 ||
      (searchParams.date[0] && searchParams.date[1])
    ) {
      handleFilter();
    } else {
      if (!servicesFilter) dispatch(setDoctors(doctors));
      //if no result or search params
      // coming from all-doctors link sets viewed doctors (doctors)
      //coming from service link sets viewed doctors ([])
    }
  }, [searchParams, doctors]);

  return null;
}
