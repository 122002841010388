import { Accordion } from "react-bootstrap";
import CheckBoxItem from "../CheckBoxItem";
import React, { useContext } from "react";
import { langContext } from "../../../../context/LangProvider";

export default function Gender({ selectedGender, setSelectedGender }) {
  const handleChangeGender = (e) => {
    if (e.target.checked) {
      setSelectedGender([...selectedGender, e.target.value]);
    } else {
      setSelectedGender(selectedGender.filter((ser) => ser !== e.target.value));
    }
  };
  const { t } = useContext(langContext);
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>{t("gender")}</Accordion.Header>
        <Accordion.Body>
          <CheckBoxItem
            checkBoxToggle={handleChangeGender}
            label={t("genderType1")}
            value="male"
          />
          <CheckBoxItem
            checkBoxToggle={handleChangeGender}
            label={t("genderType2")}
            value="female"
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
