import BookingCard from "./BookingCard";
import { Modal } from "react-bootstrap";

export default function BookingModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body
        className="booking-modal booking-card"
        style={{ margin: 0, padding: 0 }}
      >
        <BookingCard
          data={props.data}
          dataName={props.dataName}
          confirmation={props.confirmation}
          handleConfirm={props.handleConfirm}
          overLay={props.overlay}
          isShowingModal={props.isShowingModal}
        />
      </Modal.Body>
    </Modal>
  );
}
