import areas from "./areas";
import cart from "./cart";
import cities from "./cities";
import { configureStore } from "@reduxjs/toolkit";
import dashboard from "./dashboard";
import doctors from "./doctors";
import faqs from "./faq";
import labs from "./labs";
import myAppointments from "./myappointments";
import offers from "./offers";
import pages from "./pages";
import reviews from "./reviews";
import searchParams from "./search";
import services from "./services";
import specialities from "./specialities";
import user from "./auth";

const store = configureStore({
  reducer: {
    doctors,
    labs,
    cities,
    offers,
    services,
    areas,
    searchParams,
    cart,
    faqs,
    user,
    myAppointments,
    pages,
    reviews,
    dashboard,
    specialities,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
export default store;
