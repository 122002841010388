import ContactUsPage from "../components/contact-us/ContactUsPage";
import { Container } from "react-bootstrap";
import FAQ from "../components/faq/FAQ";
import Searchbar from "../components/searchbar/Searchbar";
import { useSelector } from "react-redux";
import { useContext } from "react";
import { langContext } from "../context/LangProvider";

export default function ContactUs() {
  // const { faqs } = useSelector((state) => state.faqs);
  const data = {
    phone: "111116",
    email: "dentist@gmail.com",
    location: {
      name: "39 Kasr Al Nile, Al Fawalah, Abdeen, Cairo",
      embed:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3453.599013351569!2d31.244190684943856!3d30.048359781880887!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145840bf23102dfd%3A0xa41cba5411eddad7!2zMzkg2LTYp9ix2Lkg2YLYtdixINin2YTZhtmK2YTYjCDYp9mE2YHZiNin2YTYqdiMINi52KfYqNiv2YrZhtiMINmF2K3Yp9mB2LjYqSDYp9mE2YLYp9mH2LHYqeKArNiMINmF2LXYsQ!5e0!3m2!1sar!2sde!4v1640787595863!5m2!1sar!2sde",
      link: "https://www.google.com/maps/place/39+%D8%B4%D8%A7%D8%B1%D8%B9+%D9%82%D8%B5%D8%B1+%D8%A7%D9%84%D9%86%D9%8A%D9%84%D8%8C+%D8%A7%D9%84%D9%81%D9%88%D8%A7%D9%84%D8%A9%D8%8C+%D8%B9%D8%A7%D8%A8%D8%AF%D9%8A%D9%86%D8%8C+%D9%85%D8%AD%D8%A7%D9%81%D8%B8%D8%A9+%D8%A7%D9%84%D9%82%D8%A7%D9%87%D8%B1%D8%A9%E2%80%AC%D8%8C+%D9%85%D8%B5%D8%B1%E2%80%AD/@30.0483598,31.2441907,17z/data=!3m1!4b1!4m5!3m4!1s0x145840bf23102dfd:0xa41cba5411eddad7!8m2!3d30.0483598!4d31.242002?hl=ar",
    },
  };
  const { t } = useContext(langContext);
  const formData = [
    {
      label: t("First Name"),
      props: { placeholder: t("Type name") },
      type: "text",
      name: "firstName",
    },
    {
      label: t("Second Name"),
      props: { placeholder: t("Type name") },
      type: "text",
      name: "secondName",
    },
    {
      label: t("E-Mail"),
      props: { placeholder: t("E-Mail") },
      type: "email",
      name: "email",
      validation: { required: false },
    },
    {
      label: t("Phone"),
      props: { placeholder: t("Phone") },
      type: "text",
      name: "phone",
    },
    {
      label: t("Message"),
      props: { placeholder: t("Type your message") },
      type: "textarea",
      name: "message",
    },
  ];
  const initialValues = {
    firstName: "",
    secondName: "",
    email: "",
    phone: "",
    message: "",
  };
  return (
    <div className=" p-top-100">
      <Searchbar dlPage="p-top-100" activeTab="appointment" />
      <Container>
        <ContactUsPage
          data={data}
          formData={formData}
          initialValues={initialValues}
        />
        {/* {faqs.length > 0 && (
          <FAQ fullWidth="w-100" faqData={faqs} title="FAQs" />
        )} */}
      </Container>
    </div>
  );
}
