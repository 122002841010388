import { Form } from "react-bootstrap";
import PatientInput from "../components/patient-profile/profile components/PatientInput";
import React, { useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import useWindowWidth from "../Hooks/useWindowWidth";
import validate from "../Helpers/validate";
import SideBar from "../components/dashboard/SideBar";
import Header from "../components/dashboard/Header";
import { CCard, CCardBody, CCardHeader } from "@coreui/react";
import { langContext } from "../context/LangProvider";

export default function DashboardSecurity() {
  const { token } = useSelector((state) => state.user),
    { visible, narrow } = useSelector((state) => state.dashboard);
  const { t } = useContext(langContext);
  const formData = [
    {
      label: "Current Password",
      placeholder: "Current Password",
      type: "password",
    },
    {
      label: t("New Password"),
      placeholder: t("New Password"),
      type: "password",
    },
    {
      label: "confirmPassword",
      placeholder: "confirmPassword",
      type: "password",
    },
  ];
  const updatePassword = async (e) => {
    e.preventDefault();

    try {
      let res = await axios
        .post(
          `${process.env.REACT_APP_URL}/update_password`,
          {
            current_password: e.target[0].value,
            new_password: e.target[1].value,
            new_password_confirmation: e.target[2].value,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .catch(function (error) {
          if (error.response) {
            if (error.response?.data?.message) {
              toast.error(error.response.data.message);
            }
            console.log(error?.response?.data);
            toast.error(error?.response?.data?.errors.new_password[0]);
          }
        })
        .then(function (success) {
          console.log(success);
          if (success.data.success) toast.success(success.data.message);
        });
    } catch (err) {
      if (err.response.data.errors) {
        let apiErrors = { ...err.response.data.errors };
        validate(apiErrors["current_password"]);
        validate(apiErrors["new_password"]);
      }
      if (err?.response?.data?.message === "Invalid password")
        toast("Incorrect Password");
    } finally {
      document.getElementById("password-form").reset();
    }
  };

  return (
    <div className="" style={{ minHeight: "100vh" }}>
      <SideBar />
      <div
        className="dashboard-container"
        style={
          window.innerWidth > 768 && visible
            ? narrow
              ? { marginLeft: "65px" }
              : { marginLeft: "256px" }
            : { marginLeft: "0" }
        }
      >
        <Header />
        <div className="dashboard-wrapper bg-transparent">
          <CCard>
            <CCardHeader>{t("Password & security")}</CCardHeader>
            <CCardBody>
              <Form id="password-form" onSubmit={updatePassword}>
                {formData.map((input, i) => (
                  <PatientInput
                    key={i}
                    label={input.label}
                    type={input.type}
                    value={input.value}
                    placeholder={input.placeholder}
                  />
                ))}
                {/* <div style={{ paddingBottom: "1rem", marginTop: "2rem" }}> */}
                <button
                  className="text-white bg-success rounded p-2 save"
                  style={{ marginRight: "1rem" }}
                  type="submit"
                >
                  {t("Save")}
                </button>
              </Form>
            </CCardBody>
          </CCard>
        </div>
      </div>
    </div>
  );
}
