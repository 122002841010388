import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormLabel,
  CFormSwitch,
  CFormTextarea,
  CRow,
} from "@coreui/react";
import { cilPlus, cilTrash } from "@coreui/icons";
import { days, hours } from "../../Helpers/staticData";
import { useEffect, useState } from "react";

import CIcon from "@coreui/icons-react";
import Mapselector from "../Mapselector";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export default function LocationEdit({ location, setLocation, errors }) {
  const { id } = useParams();
  const { cities } = useSelector((state) => state.cities);
  const [areas, setAreas] = useState([]);
  const [areaChanged, setAreaChanged] = useState(false);

  const [closeHours, setCloseHours] = useState(hours);
  useEffect(() => {
    if (id && location.area) {
      const city = cities.find((city) => +city.id === +location.area?.city_id);
      city && setAreas(city.areas);
    }
  }, [cities]);
  return (
    <CCard className="dashboard-locations">
      <CCardBody>
        <CRow>
          <CCol xs="12" className="my-2">
            <CCard>
              <CCardBody>
                <CRow>
                  <CCol xs="12" md="6">
                    <CFormLabel>Address</CFormLabel>
                    <CFormInput
                      placeholder="Address"
                      value={location.street}
                      onChange={(e) =>
                        setLocation({ ...location, street: e.target.value })
                      }
                    />
                    <div className="text-danger">{errors?.street}</div>
                  </CCol>
                  <CCol xs="12" md="6">
                    <CFormLabel>Address Line 2</CFormLabel>
                    <CFormInput
                      placeholder="Address 2"
                      value={location.street_2}
                      onChange={(e) =>
                        setLocation({ ...location, street_2: e.target.value })
                      }
                    />
                  </CCol>
                  <CCol xs="12" md="6">
                    <CFormLabel>City</CFormLabel>
                    <Select
                      options={cities}
                      onChange={(item) => {
                        const tmpLocation = {
                          ...location,
                          area: {
                            ...location.area,
                            city: item,
                          },
                        };
                        setLocation(tmpLocation);
                        setAreas(item.areas);
                        setAreaChanged(true);
                      }}
                      value={location.area.city}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      isSearchable
                      placeholder="Select city"
                    />
                  </CCol>
                  <CCol xs="12" md="6">
                    <CFormLabel>Area</CFormLabel>
                    <Select
                      options={areas}
                      onChange={(item) => {
                        setLocation({
                          ...location,
                          area: { city: location.area.city, ...item },
                          area_id: item.id,
                        });
                        setAreaChanged(false);
                      }}
                      value={
                        !areaChanged &&
                        areas.find((area) => +area.id === +location.area_id)
                      }
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      isSearchable
                      placeholder="Select area"
                    />
                  </CCol>
                  <CCol xs="12" md="6">
                    <CFormLabel>Longitude</CFormLabel>
                    <CFormInput
                      placeholder="Longitude"
                      value={location.longitude}
                      onChange={(e) =>
                        setLocation({ ...location, longitude: e.target.value })
                      }
                    />
                  </CCol>
                  <CCol xs="12" md="6">
                    <CFormLabel>Latitude</CFormLabel>
                    <CFormInput
                      placeholder="Latitude"
                      value={location.latitude}
                      onChange={(e) =>
                        setLocation({ ...location, latitude: e.target.value })
                      }
                    />
                  </CCol>
                  <div className="text-danger">{errors?.latLng}</div>
                </CRow>

                <CRow>
                  {location.longitude && location.latitude && (
                    <CCol xs="12" md="6">
                      <CFormLabel>Choose Location</CFormLabel>
                      <Mapselector
                        location={location}
                        setLocation={setLocation}
                        index={location.id}
                      />
                    </CCol>
                  )}
                  <CCol xs="12" md="6">
                    <CFormLabel>Note</CFormLabel>
                    <CFormTextarea
                      placeholder="Enter note"
                      value={location.note}
                      rows={8}
                      onChange={(e) =>
                        setLocation({ ...location, note: e.target.value })
                      }
                    />
                  </CCol>
                </CRow>

                <CCol className="mt-4" xs="12">
                  <CCard>
                    <CCardHeader>
                      <CRow className="align-items-center">
                        <CCol>Availability</CCol>
                        <CCol xs="auto">
                          <CButton
                            onClick={() => {
                              let tmpAvailabilities = [
                                ...location.availabilities,
                              ];
                              tmpAvailabilities.push({
                                day: "",
                                open_hour: "",
                                close_hour: "",
                                closed: false,
                              });
                              setLocation({
                                ...location,
                                availabilities: tmpAvailabilities,
                              });
                            }}
                            variant="ghost"
                            size="sm"
                            color="primary"
                          >
                            <CIcon icon={cilPlus} />
                            Add new Availability
                          </CButton>
                        </CCol>
                      </CRow>
                    </CCardHeader>
                    <CCardBody>
                      <CRow>
                        <div className="text-danger">
                          {errors?.availabilities}
                        </div>
                        <div className="text-danger">
                          {errors?.availability_range}
                        </div>
                        {location.availabilities?.map((availabilty, index) => (
                          <CCol xs="12" md="6" key={index} className="mb-3">
                            <CCard style={{ minHeight: "220px" }}>
                              <CCardHeader>
                                <CRow className="align-items-center">
                                  <CCol>Day {index + 1}</CCol>
                                  <CCol xs="auto">
                                    <CButton
                                      onClick={() => {
                                        let tmpAvailabilities = [
                                          ...location.availabilities,
                                        ];
                                        tmpAvailabilities.splice(index, 1);
                                        setLocation({
                                          ...location,
                                          availabilities: tmpAvailabilities,
                                        });
                                      }}
                                      variant="ghost"
                                      size="sm"
                                      color="danger"
                                    >
                                      <CIcon icon={cilTrash} />
                                    </CButton>
                                  </CCol>
                                </CRow>
                              </CCardHeader>
                              <CCardBody>
                                <CRow className="reversed-switch-row">
                                  <CCol xs="12" lg="6">
                                    <CFormLabel>Day</CFormLabel>
                                    <Select
                                      options={days}
                                      onChange={(item) => {
                                        let tmpAvailabilties = [
                                          ...location.availabilities,
                                        ];
                                        tmpAvailabilties[index].day =
                                          item.value;
                                        setLocation({
                                          ...location,
                                          availabilities: tmpAvailabilties,
                                        });
                                      }}
                                      value={days.filter(
                                        (day) => day.value === availabilty.day
                                      )}
                                      getOptionValue={(option) => option.value}
                                      getOptionLabel={(option) => option.label}
                                      isSearchable
                                      placeholder="Select day"
                                    />
                                    <div className="text-danger">
                                      {errors
                                        ? errors[`availabilities.${index}.day`]
                                        : ""}
                                    </div>
                                  </CCol>
                                  <CCol xs="12" lg="6">
                                    <div className="closed-switch">
                                      <div className="closed-switch-box">
                                        <span className="pr-3">Closed</span>
                                        <CFormSwitch
                                          size="lg"
                                          variant="3d"
                                          color="primary"
                                          checked={availabilty.closed}
                                          onChange={(e) => {
                                            let tmpAvailabilties = [
                                              ...location.availabilities,
                                            ];
                                            tmpAvailabilties[index].closed =
                                              e.target.checked;
                                            setLocation({
                                              ...location,
                                              availabilities: tmpAvailabilties,
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </CCol>
                                </CRow>
                                {!availabilty.closed && (
                                  <div>
                                    <CRow>
                                      <CCol xs="12" lg="6">
                                        <CFormLabel>Open Hour</CFormLabel>
                                        <Select
                                          options={hours}
                                          onChange={(item) => {
                                            let tmpAvailabilties = [
                                              ...location.availabilities,
                                            ];
                                            tmpAvailabilties[index].open_hour =
                                              item ? item.value : item;
                                            tmpAvailabilties[index].close_hour =
                                              null;
                                            setLocation({
                                              ...location,
                                              availabilities: tmpAvailabilties,
                                            });
                                            setCloseHours(
                                              hours.slice(
                                                hours.findIndex(
                                                  (i) => i === item
                                                ) + 1
                                              )
                                            );
                                          }}
                                          value={hours.filter(
                                            (hour) =>
                                              hour.value ===
                                              availabilty.open_hour
                                          )}
                                          getOptionValue={(option) =>
                                            option.value
                                          }
                                          getOptionLabel={(option) =>
                                            option.label
                                          }
                                          isSearchable
                                          placeholder="Open Hour"
                                        />
                                        <div className="error">
                                          {errors
                                            ? errors[
                                                `availabilities.${index}.open_hour`
                                              ]
                                            : ""}
                                        </div>
                                      </CCol>
                                      {console.log(location)}
                                      <CCol xs="12" lg="6">
                                        <CFormLabel>Close Hour</CFormLabel>
                                        <Select
                                          options={closeHours}
                                          onChange={(item) => {
                                            let tmpAvailabilties = [
                                              ...location.availabilities,
                                            ];
                                            tmpAvailabilties[index].close_hour =
                                              item ? item.value : item;
                                            setLocation({
                                              ...location,
                                              availabilities: tmpAvailabilties,
                                            });
                                            setCloseHours(hours);
                                          }}
                                          value={hours.filter(
                                            (hour) =>
                                              hour.value ===
                                              availabilty.close_hour
                                          )}
                                          getOptionValue={(option) =>
                                            option.value
                                          }
                                          getOptionLabel={(option) =>
                                            option.label
                                          }
                                          isSearchable
                                          placeholder="Close Hour"
                                        />
                                        <div className="error">
                                          {errors
                                            ? errors[
                                                `availabilities.${index}.close_hour`
                                              ]
                                            : ""}
                                        </div>
                                      </CCol>
                                    </CRow>
                                  </div>
                                )}
                              </CCardBody>
                            </CCard>
                          </CCol>
                        ))}
                      </CRow>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  );
}
