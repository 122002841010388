import { useCallback, useEffect, useState } from "react";
import { Editor as TinyMCE } from "@tinymce/tinymce-react";
import Header from "../components/dashboard/Header";
import SideBar from "../components/dashboard/SideBar";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormInput,
  CFormLabel,
  CRow,
} from "@coreui/react";
import { useDropzone } from "react-dropzone";
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { cilMinus, cilPlus } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import Select from "react-select";
import { getTotalFeesAfterDiscount } from "../Helpers/getTotalFees";
import DatePicker from "react-datepicker";
import moment from "moment";
// import { appendItem } from "../Helpers/appendItem";

import { getOffer, offersService } from "../services/offersService";
//@ts-ignore
import { appendItem } from "doitfast";
import { Toast } from "@coreui/coreui";
import { useDispatch } from "react-redux";
import { getLoggedInDoctor } from "../redux/auth";

export default function DashboardNewOffer() {
  //

  const [image, setImage] = useState();
  const [newOffer, setNewOffer] = useState({ provider_type: "doctors" });

  const onPictureDrop = useCallback(
    (acceptedFiles) => {
      setImage(acceptedFiles[0]);
    },
    [image]
  );

  function preventNegativeNumber(e) {
    if (e.code == "Minus" || e.target.value < 0) e.preventDefault();
  }
  const { id } = useParams();
  console.log("id===>", id);
  const navigate = useNavigate(),
    [loading, setLoading] = useState(true),
    [locations, setLocations] = useState([]);
  const {
      token,
      doctor,
      doctorStatus,
      status: userStatus,
    } = useSelector((state) => state.user),
    { visible, narrow } = useSelector((state) => state.dashboard);
  useEffect(() => {
    setNewOffer({ ...newOffer, provider_id: doctor?.id });
  }, [doctor]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getLoggedInDoctor());
    }
  }, []);

  async function getSingleOffer() {
    try {
      let res = await getOffer(id);
      let o = res.data;
      console.log("ooooo", o);
      let locations = res.data.locations.map((item) => item.id);
      o = { ...o, locations };
      setNewOffer(o);
    } catch (error) {}
  }
  console.log("doctor>>>", doctor);
  useEffect(() => {
    if (id) getSingleOffer();
  }, []);
  console.log("newOffer", newOffer);
  useEffect(() => {
    console.log("useEffect for services");
    let totalFees = getTotalFeesAfterDiscount(
      newOffer?.services,
      doctor?.examination_fee,
      newOffer?.discount
    );
    setNewOffer({ ...newOffer, totalFees: totalFees });
    console.log("offer : ", newOffer);
  }, [
    newOffer?.services,
    doctor?.examination_fee,
    newOffer?.discount,
    newOffer?.services?.length,
  ]);

  function handleLocationsChange(event) {
    console.log(event);

    console.log("locations : ", newOffer.locations);
    let value = Array.from(event, (option) =>
      option?.name ? option.name : option
    );
    console.log(value);
    setNewOffer({
      ...newOffer,
      locations: event.map((location) => location.id),
    });
  }
  console.log("image", image);
  // i will refactor this remaining boilerplate code to fetch offers when the api is ready
  // useEffect(() => {
  //     if (userStatus === "success" && doctorStatus === "success") {
  //         if (!doctor) {
  //             navigate("/");
  //             return;
  //         }
  //         getLocations();
  //     }
  // }, [userStatus, doctorStatus, doctor]);
  // async function getLocations() {
  //     setLoading(true);
  //     try {
  //         let res = await axios(
  //             process.env.REACT_APP_API_URL + "/doctor_dashboard/locations",
  //             {
  //                 headers: {
  //                     Authorization: "Bearer " + token,
  //                 },
  //             }
  //         );
  //         setLocations(res.data.data);
  //     } catch (error) {
  //         toast.error("failed to get locations");
  //         navigate("/");
  //     } finally {
  //         setLoading(false);
  //     }
  // }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onPictureDrop,
  });

  console.log(image);
  useEffect(() => {
    // console.log(newOffer.services.map((service) => [ service.id, service.quantity, service.fees ]));
  }, [newOffer?.services]);
  async function handleSubmit() {
    // let temp = []
    // newOffer?.services.forEach((service) => {
    //     temp.push({ service_id: parseInt(service.id), quantity: parseInt(service.pivot.quantity), fees: parseInt(service.pivot.fees) })
    //     // temp.push({ service_id: 2, quantity: 1, fees: 200 })
    // })
    let data = new FormData();
    let values = {
      title: newOffer.title,
      provider_id: 2,
      provider_type: "doctors",
      description: newOffer?.description ? newOffer?.description : "null",
      start_time: moment(newOffer?.start_time).format("MM/DD/YYYY") + " 15:10",
      end_time: moment(newOffer?.end_time).format("MM/DD/YYYY") + " 15:10",
      discount: newOffer?.discount,
      services: newOffer?.services?.map((service) => {
        return {
          service_id: parseInt(service.id),
          quantity: parseInt(service.pivot.quantity)
            ? parseInt(service.pivot.quantity)
            : 1,
          fees: service.pivot.fees,
        };
      }),
      // services: [{ service_id: 2, quantity: 1, fees: 200 }],
      // services: temp,
      locations: newOffer.locations,
      image: image,
    };
    if (!id) {
      try {
        Object.keys(values).forEach((key) => {
          appendItem(data, key, values[key]);
        });

        offersService.insertOffer(data);
        // await axios.post(
        //     `${process.env.REACT_APP_API_URL}/doctor_dashboard/offers`,
        //     data,
        //     {
        //         headers: {
        //             "content-type": "application/json",
        //             Accept: "application/json",
        //             Authorization: "Bearer " + token,
        //         },
        //     }
        // ).then((response) => {
        //     console.log(response);
        //     if (response.status == '200') {
        //         toast("New offer inserted successfully")
        //     }
        //     else {
        //         toast("Something went wrong.")
        //     }
        //     console.log();
        // }).catch((e) => {
        //     // toast("Something went wrong.")
        // });
      } catch (e) {
        console.log(e);
        console.log("error", e);
      }
    } else {
      console.log("update");
      Object.keys(values).forEach((key) => {
        if (key !== "image") {
          appendItem(data, key, values[key]);
          console.log(key);
        }
      });
      console.log(data.get("image"));
      try {
        let res = await offersService.updateOffer(data, id);
        console.log(res);
        if (res.success) toast(res.message);
        else toast.error("something went wrong");
      } catch (error) {
        toast.error(error.message);
      }
    }
  }
  return (
    <div className="" style={{ minHeight: "100vh" }}>
      <SideBar />
      <div
        className="dashboard-container"
        style={
          window.innerWidth > 768 && visible
            ? narrow
              ? { marginLeft: "65px" }
              : { marginLeft: "256px" }
            : { marginLeft: "0" }
        }
      >
        <Header />
        <div className="d-flex p-3 justify-content-start">
          <CCard className="d-flex w-100">
            <CCardHeader>Offer info</CCardHeader>
            <CCardBody className="p-2">
              <CRow className="my-3">
                <div>
                  <b>Title</b>
                  <CFormInput
                    onChange={(e) => {
                      setNewOffer({ ...newOffer, title: e.target.value });
                    }}
                    type="text"
                    value={newOffer?.title}
                  />
                </div>
              </CRow>

              <CRow className=" my-3">
                <div>
                  <div>
                    <b>Text</b> (This will be displayed on the offer)
                  </div>
                  <TinyMCE
                    apiKey="b1vcvjhh4syuhjpk0kha4o4rcxfxv7w33nuugdm1sn9kiw9m"
                    initValue="Slide text"
                    value={newOffer?.description}
                    init={{
                      width: "100%",
                      menubar: false,
                      plugins: [
                        "advlist autolink lists image charmap print link preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic forecolor backcolor | removeformat| link | help",
                    }}
                    // value={slide.text}
                    onEditorChange={(content, editor) => {
                      console.log(content);
                      //     let sections = [...page.sections];
                      //     sections[index].content.slides[i].text =
                      //         content;
                      //     setPage({
                      //         ...page,
                      //         sections,
                      //     });
                      setNewOffer({ ...newOffer, description: content });
                    }}
                  />
                </div>
              </CRow>

              <CRow className=" my-3">
                <div>
                  {
                    <div
                      {...getRootProps()}
                      className="p-4 bg-light border border-dark border-dotted"
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p className="m-0 text-center">Drop files here</p>
                      ) : (
                        <p className="m-0 text-center">
                          Drag file or click to upload file
                        </p>
                      )}
                    </div>
                  }

                  <CRow>
                    {image ? (
                      <>
                        {/* <CButton onClick={() => setImage()} className="d-flex bg-info justify-content-center align-items-center" style={{ display: 'flex', cursor: 'pointer', width: '20px', height: '20px', justifySelf: 'flex-end' }}>X</CButton> */}
                        <img
                          className="m-2 w-50 h-50"
                          src={
                            image instanceof File
                              ? URL.createObjectURL(image)
                              : typeof image === "string" && newOffer?.image
                          }
                          alt="item"
                        />
                      </>
                    ) : (
                      newOffer?.image_url && (
                        <img
                          className="m-2 w-50 h-50"
                          src={newOffer?.image_url}
                          alt="item"
                        />
                      )
                    )}
                  </CRow>
                </div>
              </CRow>
            </CCardBody>
          </CCard>
        </div>
        <CCard>
          <CCardHeader>Start / End dates</CCardHeader>
          <CCardBody>
            <CRow>
              <CCol>
                <CRow>
                  <CCardTitle>Start Date : </CCardTitle>
                </CRow>
                <CRow>
                  <DatePicker
                    className="p-1 rounded"
                    selected={
                      newOffer?.start_time ? new Date(newOffer?.start_time) : ""
                    }
                    onChange={(e) => {
                      console.log(
                        "startDate: ",
                        e.toLocaleDateString(),
                        "offer: ",
                        newOffer
                      );
                      setNewOffer({
                        ...newOffer,
                        start_time: e,
                      });
                    }}
                    value={
                      newOffer?.start_time
                        ? new Date(newOffer?.start_time).toLocaleDateString()
                        : ""
                    }
                  />
                </CRow>
              </CCol>
              <CCol>
                <CCardTitle>End Date : </CCardTitle>
                <DatePicker
                  selected={
                    newOffer?.end_time ? new Date(newOffer?.end_time) : ""
                  }
                  onChange={(e) => {
                    // if (new Date(e.toLocaleDateString()) < new Date(newOffer?.startDate)) {
                    //     alert('the end date must be after the start date...')
                    //     return
                    // }
                    setNewOffer({
                      ...newOffer,
                      end_time: e,
                    });
                  }}
                  value={
                    newOffer?.end_time
                      ? new Date(newOffer?.end_time).toLocaleDateString()
                      : ""
                  }
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader>Locations</CCardHeader>
          <CCardBody>
            <CRow className="d-flex flex-row w-100 justify-content-center p-3">
              <CCol md="6" lg="6">
                <>
                  <p>
                    <b>Locations</b> ( You can select multiple locations )
                  </p>
                  <Select
                    isClearable
                    isSearchable
                    isMulti
                    // value={newOffer?.selectedLocations}
                    options={doctor?.locations?.map((location) => {
                      console.log("locations", doctor?.locations);
                      return location?.area.name
                        ? location?.area
                        : { name: "hello" };
                    })}
                    onChange={handleLocationsChange}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                  />
                </>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader>
            <CRow className="align-items-center">
              <CCol>Offer Services</CCol>
              <CCol xs="auto">
                <CButton
                  onClick={() => {
                    let tmpServices = newOffer?.services || [];
                    tmpServices?.push({ pivot: { quantity: 1 } });
                    setNewOffer({
                      ...newOffer,
                      services: tmpServices,
                    });
                  }}
                  variant="ghost"
                  size="sm"
                  color="primary"
                >
                  <CIcon icon={cilPlus} />
                </CButton>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              {/* <div className="text-danger">{errors?.no_services}</div> */}
              {/* {JSON.stringify(newOffer)} */}
              {newOffer?.services &&
                newOffer?.services?.map((service, i) => (
                  <CCol xs="auto" key={i} className="mb-3">
                    <CCard>
                      <CCardHeader>
                        <CRow className="align-items-center">
                          <CCol>Service {i + 1}</CCol>
                          <CCol xs="auto">
                            <CButton
                              onClick={() => {
                                let tmpServices = newOffer?.services;
                                tmpServices.splice(i, 1);
                                setNewOffer({
                                  ...newOffer,
                                  services: tmpServices,
                                });
                              }}
                              variant="ghost"
                              size="sm"
                              color="danger"
                            >
                              <CIcon icon={cilMinus} />
                            </CButton>
                          </CCol>
                        </CRow>
                      </CCardHeader>
                      <CCardBody>
                        <Select
                          options={doctor?.services}
                          onChange={(item) => {
                            let tmpServices = [...newOffer?.services];
                            tmpServices[i] = {
                              ...item,
                              service_id: item && item.id,
                            };
                            console.log("services");
                            setNewOffer({
                              ...newOffer,
                              services: tmpServices,
                            });
                          }}
                          value={service && service}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.name}
                          isSearchable
                          placeholder="Select a service"
                        />
                        <CFormLabel>Fees</CFormLabel>
                        <CFormInput
                          disabled
                          type="number"
                          min={0}
                          onChange={(e) => {
                            // let tempServices = newOffer.services;
                            // console.log('test: ',tempServices[i]);
                            // tempServices[i].pivot.fees = e.target.value
                            // console.log('test: ',tempServices[i]);
                            // setNewOffer({ ...newOffer, services: tempServices })
                          }}
                          placeholder="fees"
                          value={service.pivot?.fees}
                        />
                        <CFormLabel>Package</CFormLabel>
                        <CFormInput
                          disabled
                          type="number"
                          min={0}
                          placeholder="package"
                          value={service.pivot?.package}
                        />
                        <CFormLabel>Quantity</CFormLabel>
                        <CFormInput
                          type="number"
                          min={0}
                          placeholder="quantity"
                          value={service?.pivot?.quantity}
                          onChange={(e) => {
                            let tmpServices = [...newOffer?.services];
                            tmpServices[i] = {
                              ...tmpServices[i],
                              pivot: {
                                ...service.pivot,
                                quantity: e.target.value,
                              },
                            };
                            setNewOffer({
                              ...newOffer,
                              services: tmpServices,
                            });

                            console.log(newOffer);
                          }}
                        />
                      </CCardBody>
                    </CCard>
                  </CCol>
                ))}
            </CRow>
          </CCardBody>
          <CRow className="mx-2">
            <CCol>
              <div>
                Examination fee :{" "}
                {newOffer?.provider?.examination_fee || doctor?.examination_fee}{" "}
              </div>
            </CCol>
          </CRow>
          <CRow className="m-3">
            <b>Discount</b>
            <CCol lg="4">
              <CFormInput
                min={1}
                value={newOffer?.discount || ""}
                onChange={(e) => {
                  setNewOffer({ ...newOffer, discount: +e.target.value });
                  preventNegativeNumber(e);
                }}
                onKeyPress={(e) => {
                  setNewOffer({ ...newOffer, discount: +e.target.value });

                  preventNegativeNumber(e);
                }}
                type="number"
              />
            </CCol>
            <CCol lg="2">
              <b>EGP</b>
            </CCol>
          </CRow>
          <CRow className="mx-3">
            <CCol>
              <CFormLabel>
                Total fees :
                {newOffer?.totalFees
                  ? newOffer.totalFees
                  : "Please select a service."}
              </CFormLabel>
            </CCol>
          </CRow>
          <CRow className="mx-3">
            <CCol lg="6">
              <CButton
                onClick={() => handleSubmit()}
                color="success"
                className="text-white"
              >
                Submit
              </CButton>
            </CCol>
          </CRow>
        </CCard>
      </div>
    </div>
  );
}
