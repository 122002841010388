import { Form, Row } from "react-bootstrap";

import { Modal } from "react-bootstrap";
import Service from "./ServiceItem";
import useSearchServices from "../../../Hooks/useSearchServices";
import { useState } from "react";

export default function MoreServicesmodal(props) {
  const [search, setSearch] = useState("");
  const [searched, handleSearch] = useSearchServices(props.data, search);
  return (
    <Modal
      {...props}
      size="lg"
      className="doc-services"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Doctor<span className="mainColor">{props.doctorName}'s</span> Services
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="search-services">
          {search.length > 0 ? null : (
            <img className="icon" src="/assets/search-sml.svg" alt="" />
          )}
          <Form.Group className="mb-4" controlId="formBasicEmail">
            <Form.Control
              value={search}
              type="text"
              placeholder="    Search in services..."
              className="search"
              onChange={(e) => {
                handleSearch(e.target.value);
                setSearch(e.target.value);
              }}
              style={{ width: "100%" }}
            />
          </Form.Group>
        </div>
        <Row>
          {searched.map((s, i) => (
            <Service
              service={s}
              key={i}
              handleConfirm={props.handleConfirm}
              extraFees={props.extraFees}
              dataId={props.dataId}
              dataType={props.dataType}
              myAppointment={props.myAppointment}
            />
          ))}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="pop-up"
          style={{ padding: "0.5rem 1rem", borderRadius: "0.4rem" }}
          onClick={props.onHide}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
