import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";

import CIcon from "@coreui/icons-react";
import SpicyDatatable from "spicy-datatable";
import { Spinner } from "react-bootstrap";
import { cilPlus } from "@coreui/icons";
import { useNavigate } from "react-router-dom";

export default function Locations({ locations, loading }) {
  const navigateFunction = (item) => {
    navigate(`edit/${item}`);
  };
  const config = {
    itemsPerPageLabel: "Locations Per Page",
    noEntriesLabel: "No Locations To Show",
    entryCountLabels: ["Showing", "to", "of", "Locations."],
  };
  const columns = [
    { key: "street", label: "Street", sort: true },
    { key: "area", label: "Area", sort: true },
    { key: "availabilities", label: "Availabilities", sort: false },
    { key: "lat_lng", label: "Lat/Lng", sort: true },
  ];
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const getRows = () => {
    const rows = locations.map((location, i) => {
      return {
        street: location.street,
        area: location.area.name,
        availabilities: location.availabilities
          .slice(0, 2)
          .map((availability) => availability.day.toUpperCase())
          .join(" , "),
        lat_lng: location.latitude + " - " + location.longitude,
        onClickHandler: () => navigateFunction(location.id),
      };
    });
    setRows(rows);
  };
  useEffect(() => {
    !loading && getRows();
  }, [loading]);
  return (
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>Locations</CCardHeader>
          <div className="d-flex justify-content-end my-2">
            <CButton
              variant="ghost"
              size="sm"
              color="dark"
              onClick={() => navigate("create")}
            >
              <CIcon icon={cilPlus} />
              Add New Location
            </CButton>
          </div>
          <CCardBody>
            {loading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" />
              </div>
            ) : (
              <div className="booking-table">
                <SpicyDatatable
                  tableKey="booking table"
                  columns={columns}
                  rows={rows}
                  config={config}
                />
              </div>
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
}
