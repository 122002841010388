import Spinner, { SpinnerError } from "../components/Spinner";
import { useEffect, useState } from "react";

import About from "../components/SingleDoctor/about";
import BookingCard from "../components/BookingCard/BookingCard";
import ClinicPhotos from "../components/SingleDoctor/ClinicPhotos";
import { Container } from "react-bootstrap";
import Direction from "../components/SingleDoctor/direction";
import FAQ from "../components/faq/FAQ";
import LabInfo from "../components/SingleLab/LabInfo";
import Services from "../components/SingleDoctor/services/services";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useContext } from "react";
import { langContext } from "../context/LangProvider";

export default function SingleLab({ myAppointment }) {
  let { id } = useParams();
  const { labs, status } = useSelector((state) => state.labs);
  const [lab, setLab] = useState(null);
  const [spinner, setSpinner] = useState(<Spinner height="100" />);
  const { t } = useContext(langContext);
  useEffect(() => {
    if (status === "success") {
      const lab = labs.find((l) => l.id === parseInt(id));
      setLab(lab);
    } else if (status === "failed") {
      setSpinner(<SpinnerError />);
    }
  }, [id, labs, status]);
  // const { faqs } = useSelector((state) => state.faqs);
  const [confirmation, setConfirmation] = useState(false);
  const [over, setOver] = useState("d-none");
  const overLay = (s) => {
    setOver(s);
  };
  const entity_gallery = [
    { original_url: "/assets/clinic.png" },
    { original_url: "/assets/clinic.png" },
    { original_url: "/assets/clinic.png" },
    { original_url: "/assets/clinic.png" },
    { original_url: "/assets/clinic.png" },
    { original_url: "/assets/clinic.png" },
    { original_url: "/assets/clinic.png" },
    { original_url: "/assets/clinic.png" },
  ];
  return (
    <div className=" p-top-100">
      <div className={`black-overlay ${over}`}></div>

      {lab ? (
        <Container>
          <div className="doc-profile-info-cont">
            <LabInfo lab={lab} />
            <BookingCard
              overLay={overLay}
              confirmation={confirmation}
              data={lab}
              dataName="lab"
              handleConfirm={(value) => {
                setConfirmation(value);
                setOver(value ? "" : "d-none");
              }}
              myAppointment={myAppointment}
            />
          </div>
          <Services
            services={lab.services}
            data={lab}
            extraFees={0}
            dataType="lab"
            handleConfirm={(value) => {
              setConfirmation(value);
              setOver(value ? "" : "d-none");
            }}
            myAppointment={myAppointment}
          />
          <About title={`${t("About")} ${lab.name}`} about={lab.about} />
          <ClinicPhotos
            title={t("Scan Lab Photos")}
            gallery={lab.gallery?.length ? lab.gallery : entity_gallery}
          />
          <Direction data={lab} />
          {/* {faqs.length > 0 && <FAQ faqData={faqs} title="FAQs" />} */}
        </Container>
      ) : (
        <>{spinner}</>
      )}
    </div>
  );
}
