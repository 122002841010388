import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

export const getHomePage = createAsyncThunk("pages/getHomePage", async () => {
  try {
    let homeResponse = await axios(`${process.env.REACT_APP_URL}/pages/home`);
    let homePage = false;
    if (homeResponse.data.data.length) homePage = true;

    return homePage;
  } catch (e) {
    console.log(e);
  }
});
export const getPages = createAsyncThunk("pages/getPages", async (slug) => {
  let res;
  try {
    res = await axios(`${process.env.REACT_APP_URL}/pages${slug}`);
    return res?.data?.data;
  } catch (e) {
    console.log(e);
  } finally {
    if (!res.data.data.length) return false;
  }
});
const pagesSlice = createSlice({
  name: "pages",
  initialState: {
    homePage: false,
    sections: [],
    status: null,
    homepage_status: "loading",
  },

  extraReducers: {
    [getPages.pending]: (state) => {
      state.status = "loading";
    },
    [getPages.fulfilled]: (state, action) => {
      if (action.payload?.length) {
        state.sections = action.payload[0].content;
      } else {
        state.sections = [
          { layout: "not-found", content: "Page Doesn't Exist" },
        ];
      }
      state.status = "success";
    },
    [getPages.rejected]: (state) => {
      state.status = "failed";
    },
    [getHomePage.pending]: (state) => {
      state.homepage_status = "loading";
    },
    [getHomePage.fulfilled]: (state, action) => {
      state.homePage = action.payload;
      state.homepage_status = "success";
    },
  },
});
export default pagesSlice.reducer;
