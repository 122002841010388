const arrangeDaysFromToday = (days) => {
  try {
    days.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    return days;
  } catch (error) {
    console.log("arrangeDaysFromToday Error", error);
  }
};
export default arrangeDaysFromToday;
