import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { faqsService } from "../services/faqsService";

export const getOffers = createAsyncThunk("offers/getOffers", async () => {
  // let res = await axios(`${process.env.REACT_APP_API_URL}`)
  // console.log('offersssssssss : ', res);
  const offers = [
    {
      id: 1,
      image: "/assets/Laser Teeth Whitening.png",
      name: "Laser Teeth Whitening",
      doctorId: 2,
      fees: 150,
      offer: "1 session Laser Teeth Whitening",
      about: "asdasd asdasd dwqfq ffqwf wqdwd",
      waiting_time: "23 minutes",
      locations: [
        {
          id: 1,
          area_id: 1,
          area: {
            id: 1,
            name: "Heliopolis",
          },
          street: "12 as",
          street_2: "15 as",
        },
      ],
      online_payment: "1",
    },
    {
      id: 2,
      image: "/assets/Laser Teeth Whitening 2.png",
      name: "Laser Teeth Whitening",
      doctorId: 3,
      fees: 150,
      offer: "1 session Laser Teeth Whitening",
      about: "asdasd asdasd dwqfq ffqwf wqdwd",
      waiting_time: "23 minutes",
      locations: [
        {
          id: 1,
          area_id: 1,
          area: {
            id: 1,
            name: "Heliopolis",
          },
          street: "12 as",
          street_2: "15 as",
        },
        {
          id: 2,
          area_id: 2,
          area: {
            id: 2,
            name: "Nasr City",
          },
          street: "12 as",
          street_2: "15 as",
        },
      ],
      online_payment: "0",
    },
  ];
  return offers;
  // let res=await axios(`${process.env.REACT_APP_URL}/offers`)
  // return res.data.data
});

const offersSlice = createSlice({
  name: "offers",
  initialState: {
    offers: [],
    status: null,
  },

  extraReducers: {
    [getOffers.pending]: (state) => {
      state.status = "loading";
    },
    [getOffers.fulfilled]: (state, action) => {
      state.status = "success";
      state.offers = action.payload;
    },
    [getOffers.rejected]: (state) => {
      state.status = "failed";
    },
  },
});
export default offersSlice.reducer;
