import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import UserService from "../services/userService";
import { getPages } from "../redux/pages";
import { setUserDoctor } from "../redux/auth";
import { useLocation } from "react-router-dom";

export default function useCheckPreLoading() {
  let dispatch = useDispatch();
  let location = useLocation();
  const {
    status: userStatus,
    user,
    doctorStatus,
  } = useSelector((state) => state.user);
  const { doctors } = useSelector((state) => state.doctors);
  const { labs } = useSelector((state) => state.labs);
  const { homepage_status } = useSelector((state) => state.pages);
  const inHomePage = location.pathname === "/";
  const [fullyLoaded, setFullyLoaded] = useState(false);

  useEffect(() => {
    setFullyLoaded(false);
    if (userStatus === "loading" || homepage_status === "loading") return;
    if (inHomePage) {
      getHomePage();
      return;
    }
    getOtherPage();
  }, [homepage_status, doctorStatus, inHomePage, userStatus, location]);
  async function setDoctorUser() {
    if (UserService.checkUserRole("Lab")) {
      dispatch(setUserDoctor(labs.find((lab) => +lab.user_id === +user.id)));
    } else {
      dispatch(
        setUserDoctor(doctors.find((doctor) => +doctor.user_id === +user.id))
      );
    }
    setFullyLoaded(true);
  }
  async function getHomePage() {
    await dispatch(getPages("/home"));
    checkDoctorsStatus();
  }
  async function getOtherPage() {
    await dispatch(getPages(location.pathname));
    checkDoctorsStatus();
  }
  async function checkDoctorsStatus() {
    setFullyLoaded(false);
    if (doctorStatus === "loading") return;
    if (UserService.checkUserRole("Doctor") || UserService.checkUserRole("Lab"))
      await setDoctorUser();
    setFullyLoaded(true);
  }

  return [fullyLoaded];
}
