import { useEffect, useState } from "react";

import { CButton } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import Header from "../components/dashboard/Header";
import ProfileImages from "../components/dashboard/ProfileImages";
import ProfileInfo from "../components/dashboard/ProfileInfo";
import SideBar from "../components/dashboard/SideBar";
import { appendItem } from "doitfast";
import axios from "axios";
import { cilCloudUpload } from "@coreui/icons";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import UserService from "../services/userService";
import { useDispatch } from "react-redux";
import { getLoggedInDoctor } from "../redux/auth";

const initValues = {
  name: "",
  speciality: "",
  speciality_id: "",
  about: "",
  examination_fee: "",
  availability: "",
  note: "",
  waiting_time: "",
  title: "",
  gender: "",
  entity: "",
  patient_condition: [],
  online_payment: false,
};
export default function DashboardProfile() {
  const navigate = useNavigate(),
    { visible, narrow } = useSelector((state) => state.dashboard),
    {
      token,
      doctor,
      doctorStatus,
      status: userStatus,
    } = useSelector((state) => state.user),
    [doctorProfile, setDoctorProfile] = useState(initValues),
    [errors, setErrors] = useState({}),
    [loading, setLoading] = useState(false);
  let dispatch = useDispatch();
  console.log(">>>>>>", doctor);
  const getDoc = () => {
    dispatch(getLoggedInDoctor());
  };
  useEffect(() => {
    getDoc();
  }, []);
  useEffect(() => {
    if (userStatus === "success" || doctorStatus === "success") {
      console.log("Dashboard");
      UserService.getMyProfile().then((res) => {
        console.log(">>>>>", res);

        setDoctorProfile(doctor);
      });
    } else {
      UserService.getMyProfile();
    }
  }, [userStatus]);
  useEffect(() => {
    UserService.getMyProfile().then(() => {
      if (
        !UserService.checkUserRole("Doctor") &&
        !UserService.checkUserRole("Lab")
      ) {
        navigate("/"); // this causes the doctor to navigate out for the first time
      }
    });
  }, [doctor]);

  const updateDoctor = async () => {
    setLoading(true);
    let data = new FormData();
    console.log("updateDoctor", doctorProfile);
    let values = {
      ...doctorProfile,
      personal_gallery: doctorProfile.personal_gallery?.map((p) => p.id) ?? [],
      entity_gallery: doctorProfile.entity_gallery.map((p) => p.id),
    };
    Object.keys(values).forEach((key) => {
      appendItem(data, key, values[key]);
    });
    if (UserService.checkUserRole("Doctor")) {
      if (!data.get(`patient_condition[0]`)) {
        setErrors({
          ...errors,
          no_patient_condition: "Please add at least one patient Condition",
        });
        toast.error("The given data was invalid");
        setLoading(false);
        return;
      }
    }
    try {
      let json = await axios.post(
        process.env.REACT_APP_API_URL + "/doctor_dashboard/doctor",
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/x-www-form-urlencoded",
          },
        }
      );
      console.log(json);
      toast.success("Updated Successfully");
    } catch (error) {
      setErrors(error.response.data.errors);
      toast.error("The given data was invalid");
    } finally {
      setLoading(false);
    }
  };
  console.log("doctorProfile", doctorProfile);
  return (
    <div className="" style={{ minHeight: "100vh" }}>
      <SideBar />
      <div
        className="dashboard-container"
        style={
          window.innerWidth > 768 && visible
            ? narrow
              ? { marginLeft: "65px" }
              : { marginLeft: "256px" }
            : { marginLeft: "0" }
        }
      >
        <Header />
        <div className="dashboard-wrapper">
          <ProfileInfo
            doctor={doctorProfile || doctor}
            setDoctor={setDoctorProfile}
            errors={errors}
          />
          <ProfileImages
            doctor={doctorProfile || doctor}
            setDoctor={setDoctorProfile}
          />
        </div>
        <CButton
          onClick={updateDoctor}
          type="submit"
          size="lg"
          color="success"
          disabled={loading}
          style={{ width: "fit-content", alignSelf: "center", color: "white" }}
        >
          Update <CIcon icon={cilCloudUpload} />
        </CButton>
      </div>
    </div>
  );
}
