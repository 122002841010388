import { useContext, useEffect, useState } from "react";

import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { login } from "../../redux/auth";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { langContext } from "../../context/LangProvider";

export default function GoogleFaceBook() {
  const { t } = useContext(langContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [facebookLogingIn, setFacebookLogingIn] = useState(false);
  const [facebookLoginText, setFacebookLogininText] = useState(t(""));
  const [googleLogingIn, setGoogleLogingIn] = useState(false);
  const [googleLoginText, setGoogleLogininText] = useState(t(""));
  const responseFacebook = async (response) => {
    socialLogin(response.accessToken, "facebook");
  };
  const responseGoogle = (response) => {
    socialLogin(response.accessToken, "google");
  };
  const socialLogin = async (accessToken, provider) => {
    if (provider === "facebook") {
      setFacebookLogingIn(true);
      setFacebookLogininText(<Spinner animation="border" size="sm" />);
    } else {
      setGoogleLogingIn(true);
      setGoogleLogininText(<Spinner animation="border" size="sm" />);
    }
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_AUTH_URL}/social-login`,
        {
          provider,
          token: accessToken,
        }
      );
      dispatch(login(res.data.data));
      navigate("/profile/update");
    } catch (err) {
      console.log(err);
      toast.error("login Error");
    } finally {
      if (provider === "facebook") {
        setFacebookLogingIn(false);
        setFacebookLogininText(() => t("facebook"));
      } else {
        setGoogleLogingIn(false);
        setGoogleLogininText(() => t("google"));
      }
    }
  };
  useEffect(() => {
    if (window.location.search.startsWith("?code=")) {
      socialLogin(
        window.location.search.substring(6).slice(0, -21),
        "facebook"
      );
    }
  }, [window.location.search]);
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <GoogleLogin
        clientId="1095356598723-tr2gc2e23adf93oolpropq1f4ioa7h5l.apps.googleusercontent.com"
        render={(renderProps) => (
          <button
            onClick={renderProps.onClick}
            className="my-google-button"
            disabled={googleLogingIn}
          >
            {!googleLogingIn && <img src="assets/google.webp" alt="" />}
            {googleLoginText || t("google")}
          </button>
        )}
        onSuccess={responseGoogle}
        cookiePolicy={"single_host_origin"}
      />

      <FacebookLogin
        textButton={facebookLoginText || t("facebook")}
        appId="1899364887117709"
        fields="name,email"
        cssClass="my-facebook-button"
        icon={!facebookLogingIn && "fa-facebook"}
        callback={responseFacebook}
        isDisabled={facebookLogingIn}
      />
    </div>
  );
}
