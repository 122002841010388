import { Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import PatientBtn from "../components/patient-profile/profile components/PatientBtn";
import PatientInput from "../components/patient-profile/profile components/PatientInput";
import PatientNavGroup from "../components/patient-profile/profile components/PatientNavGroup";
import axios from "axios";
import { editProfile } from "../redux/auth";
import { toast } from "react-toastify";
import useWindowWidth from "../Hooks/useWindowWidth";
import validate from "../Helpers/validate";
import { useContext, useState } from "react";
import { langContext } from "../context/LangProvider";

export default function ProfileEdit() {
  const { user, profile } = useSelector((state) => state.user);
  const [screenWidth] = useWindowWidth();
  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [errors, seterrors] = useState(null);
  console.log("user", user);
  const { t } = useContext(langContext);
  const formData = [
    {
      label: t("yourName"),
      placeholder: t("yourName"),
      value: user?.name || "",
      type: "text",
    },
    {
      label: t("mobileNumber"),
      placeholder: t("mobileNumber"),
      value: user?.profile?.mobile || "",
      type: "text",
    },
    {
      label: t("E-Mail"),
      placeholder: t("E-Mail"),
      value: user?.email || "",
      type: "email",
    },

    { label: t("gender"), type: "gender", value: user?.profile?.gender || "" },
    {
      label: t("birthdate"),
      placeholder: t("birthdate"),
      type: "date",
      value: user?.profile?.birth_date || "",
    },
  ];
  const handleEditProfile = async (e) => {
    e.preventDefault();
    console.log(e.target[0].value);
    let gender;
    if (e.target[3].checked) {
      gender = e.target[3].value;
    }
    if (e.target[4].checked) {
      gender = e.target[4].value;
    }
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_URL}/update_profile`,
        {
          user: {
            name: e.target[0].value,
            email: e.target[2].value,
            // password: e.target[6].value,
            // password_confirmation: e.target[7].value,
          },
          profile: {
            mobile: e.target[1].value,
            gender,
            birth_date: e.target[5].value,
          },
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log(res);
      if (res.data.message) toast.success("Profile Updated Successfully");
      dispatch(editProfile(res.data.data));
    } catch (err) {
      console.log(err);
      seterrors(err.response.data.errors);
      if (err.response.data.errors) {
        let apiErrors = { ...err.response.data.errors };
        validate(apiErrors["profile.mobile"]);
        validate(apiErrors["profile.gender"]);
        validate(apiErrors["profile.birth_date"]);
        validate(apiErrors["user.name"]);
        validate(apiErrors["user.email"]);
        validate(apiErrors["user.password"]);
        Object.keys(errors).forEach((item) => {
          toast.error(errors[item][0]);
        });
      }
    }
  };
  console.log(errors);
  console.log("formData", formData);
  return (
    <div className=" p-top-50">
      <Container>
        <div className="patient-profile-col">
          <Row className="justify-content-center">
            {screenWidth <= 767 ? null : <PatientNavGroup />}
            <Col
              md={8}
              lg={9}
              className={`${screenWidth >= 768 ? "ps-5" : ""} `}
              style={{ padding: "0 12px" }}
            >
              <h2 style={{ marginBottom: "2rem" }}>{t("Edit Profile")}</h2>
              <Form onSubmit={handleEditProfile}>
                {formData.map((input, i) => (
                  <>
                    <PatientInput
                      key={i}
                      label={input.label}
                      type={input.type}
                      value={input.value}
                      placeholder={input.placeholder}
                    />
                    <div className="err-box">
                      <div style={{ width: "26%" }}></div>
                      <div className="err">
                        {errors &&
                          errors[
                            input.label.toLowerCase().replace(" ", "_")
                          ] && (
                            <div
                              className="text-danger mt-2"
                              style={{ lineHeight: "25px", width: "400px" }}
                            >
                              {
                                errors[
                                  "user." +
                                    input.label
                                      .toLowerCase()
                                      .replace(" ", "_")[0]
                                ]
                              }
                            </div>
                          )}
                      </div>
                    </div>
                  </>
                ))}
                <PatientBtn />
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
