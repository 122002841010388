import { Form, Row } from "react-bootstrap";
import { getIntervalsBetweenShifts, isItToday } from "doitfast";
import { useCallback, useContext, useEffect, useState } from "react";
import AppointmentButton from "./AppointmentButton";
import DaysSlider from "./DaysSlider";
import GoogleFaceBook from "./auth/GoogleFaceBook";
import { Link } from "react-router-dom";
import LoginForm from "./auth/LoginForm";
import OrLine from "./auth/OrLine";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { CCard, CCardBody, CCardHeader } from "@coreui/react";
import { appendItem } from "../Helpers/appendItem";
import Spinner from "./Spinner";
import moment from "moment-timezone";
import { langContext } from "../context/LangProvider";

export default function CustomerSupport() {
  const { loginStatus, token, user } = useSelector((state) => state.user);
  const [images, setImages] = useState([]);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedAppointment, setselectedAppointment] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const onPictureDrop = useCallback(
    (acceptedFiles) => {
      setImages(acceptedFiles);
    },
    [images]
  );

  const onPersonalGalleryDrop = useCallback(
    (acceptedFiles) => {
      setImages(acceptedFiles);
    },
    [images]
  );

  const { t } = useContext(langContext);
  const availabilities = [
    {
      id: 1,
      day: moment.utc(new Date()).add(1, "days").format("ddd").toString(),
      open_hour: "10:00",
      close_hour: "15:00",
      closed: false,
      // date: "26/5/2022",
      date: moment
        .utc(new Date())
        .add(1, "days")
        .format("MM/DD/YYYY")
        .toString(),
    },
    {
      id: 2,
      day: moment.utc(new Date()).add(2, "days").format("ddd").toString(),
      open_hour: "11:00",
      close_hour: "15:00",
      closed: false,
      date: moment
        .utc(new Date())
        .add(2, "days")
        .format("MM/DD/YYYY")
        .toString(),
    },
    {
      id: 3,
      day: moment.utc(new Date()).add(3, "days").format("ddd").toString(),
      open_hour: "15:00",
      close_hour: "18:00",
      closed: true,
      date: moment
        .utc(new Date())
        .add(3, "days")
        .format("MM/DD/YYYY")
        .toString(),
    },
    {
      id: 4,
      day: moment.utc(new Date()).add(4, "days").format("ddd").toString(),
      open_hour: "12:00",
      close_hour: "17:00",
      closed: false,
      date: moment
        .utc(new Date())
        .add(4, "days")
        .format("MM/DD/YYYY")
        .toString(),
    },
  ];
  const formData = [
    {
      label: "E-Mail",
      placeholder: "E-Mail Address",
      type: "email",
      name: "email",
    },
    {
      label: "Password",
      placeholder: "Password",
      type: "password",
      name: "password",
    },
  ];
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onPictureDrop,
  });
  const {
    getRootProps: getPersonalGalleryRootProps,
    getInputProps: getPersonalGalleryInputProps,
    isDragActive: isPersonalGalleryDragActive,
  } = useDropzone({ accept: "image/*", onDrop: onPersonalGalleryDrop });
  const [loading, setLoading] = useState(false);
  const [viewdAppointments, setViewdAppointments] = useState([]);
  const [viewdDays, setViewdDays] = useState([]);
  const [notLoggedIn, setNotLoggedIn] = useState(false);
  const [notes, setNotes] = useState("");
  useEffect(() => {
    if (loginStatus) setNotLoggedIn(false);
  }, [loginStatus]);
  useEffect(() => {
    setSelectedDay(`
     ${availabilities[0].date}
      `);
    let isToday = isItToday(availabilities[0].date);

    let viewdAppointments = getIntervalsBetweenShifts(
      availabilities[0].open_hour,
      availabilities[0].close_hour,
      "00:30",
      isToday
    );
    setViewdAppointments(viewdAppointments);
    setViewdDays(availabilities);
  }, []);
  const selectAppointment = (appoint) => {
    if (loginStatus) {
      setselectedAppointment(appoint);
      setConfirmed(true);
    } else {
      setNotLoggedIn(true);
    }
  };
  const bookAppointmentHandler = async () => {
    var date = moment(`${selectedDay.trim()} ${selectedAppointment}`);
    // let date = moment(`${selectedDay.trim()} ${selectedAppointment}`)._d
    // let date = moment.utc(`${selectedDay.trim().replaceAll('/', '-')} ${selectedAppointment }`)
    // console.log(moment.tz.guess());
    // console.log(date.tz(moment.tz.guess()).format('')); // 5am PDT

    console.log(date._i);

    try {
      setLoading(true);
      let data = new FormData();
      let tempDate = selectedDay.toString();
      console.log(tempDate.trim());
      let values = {
        provider_id: user.id,
        provider_type: "user",
        // time: moment.utc(`${selectedDay.toString().trim()} ${selectedAppointment}`), // what is this ? 9/5/2022 ?
        time: date.format(""),
        fees: 0,
        patient_notes: { text: notes },
        new_gallery: images.length > 0 ? images : [""],
        timezone: moment.tz.guess(),
      };
      Object.keys(values).forEach((key) => {
        appendItem(data, key, values[key]);
      });
      await axios.post(
        `${process.env.REACT_APP_URL}/book_an_appointment`,

        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      toast.success(`${selectedAppointment} appointment has been confirmed.`);
      cancel();
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  const cancel = () => {
    setNotes("");
    setImages([]);
    setConfirmed(false);
  };
  return (
    <div className="booking-card customerSupport">
      <div className="booking-header">{t("Book Appointment")}</div>
      {!notLoggedIn && confirmed ? (
        <>
          <div className="booking-item mobileCard-hide">
            <img
              src="/assets/booking.svg"
              alt=""
              className="mx-2 icon-img-size"
            />
            <span className="me-1">{t("Selected Appointment :")}</span>
            <span className="bold">{`${selectedDay} ${selectedAppointment}`}</span>
          </div>
          <div className={`mail-phone p-3  flex-column`}>
            <div className="d-flex justify-content-center my-2">
              <Form.Control
                as="textarea"
                rows="5"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder={t("Write Your notes here")}
              />
            </div>
            <div className="d-flex justify-content-center my-2">
              <CCard>
                <CCardHeader>{t("Submit images")}</CCardHeader>
                <CCardBody>
                  <div
                    {...getRootProps()}
                    className="p-4 bg-light border border-dark border-dashed"
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p className="m-0 text-center">{t("Drop files here")}</p>
                    ) : (
                      <p className="m-0 text-center">
                        {t("Drag file or click to upload file")}
                      </p>
                    )}
                  </div>
                  {images?.map((image) => {
                    return (
                      <img
                        className="m-2"
                        height="50"
                        src={
                          image instanceof File
                            ? URL.createObjectURL(image)
                            : typeof image === "string"
                            ? image
                            : image
                        }
                        alt="item"
                      />
                    );
                  })}
                </CCardBody>
              </CCard>
            </div>
            {!loading ? (
              <div style={{ textAlign: "center" }}>
                <button
                  className="mb-3 mx-1 w-60 pop-up"
                  onClick={bookAppointmentHandler}
                >
                  {t("Book Appointment")}
                </button>
                <button
                  className="mb-3 w-30"
                  style={{ minWidth: "120px" }}
                  type="button"
                  onClick={cancel}
                >
                  {t("cancel")}
                </button>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" />
              </div>
            )}
          </div>
        </>
      ) : !notLoggedIn ? (
        <>
          <div className="booking-item">
            <span className="ms-2">
              {t("Choose Appointment and we'll call you")}
            </span>
            </div>
            
          {availabilities.length > 0 && (
            <Row className="my-2 mx-2 gx-3 booking-item">
              <span className="text-center">{t("Available Days")}</span>
              <DaysSlider
                days={viewdDays}
                setViewdAppointmentsHandler={(appoint) => {
                  setSelectedDay(`
                   ${appoint.date}
                  `);
                  let viewdAppointments = getIntervalsBetweenShifts(
                    appoint.open_hour,
                    appoint.close_hour,
                    "00:30"
                  );
                  setViewdAppointments(viewdAppointments);
                }}
              />
            </Row>
          )}
          {viewdAppointments.length > 0 && (
            <Row className="my-2 mx-2 gx-3">
              <span className="text-center">{t("Available Appointments")}</span>
              {viewdAppointments.map((appointment, i) => (
                <AppointmentButton
                  key={i}
                  appointment={appointment}
                  spacing={3}
                  appointmentHandler={selectAppointment}
                />
              ))}
            </Row>
          )}
        </>
      ) : (
        <div className="booking-item no-bottom-border">
          <GoogleFaceBook />
          <div>
            <OrLine />
            <LoginForm
              formData={formData}
              formPadding="bookingFormPadding"
              formWidth="bookingFormWidth"
            />
          </div>
          <div className="d-flex  align-items-center my-1 terms justify-content-between">
            <div>
              <Link to="/forgot-password"> {"forgotPassword"}</Link>
            </div>
            <span>
              <span className="me-1">{t("newUser")}</span>
              <Link to="/sign-up">{t("signup")}</Link>
            </span>
          </div>
        </div>
      )}
      <span></span>
      
    </div>
  );
}
