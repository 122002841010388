import { useEffect, useState } from "react";

import Doctor from "./doctor";
import { Dropdown, DropdownButton, Pagination } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { sortDoctors } from "../../../redux/doctors";
import useWindowWidth from "../../../Hooks/useWindowWidth";
import { useContext } from "react";
import { langContext } from "../../../context/LangProvider";

export default function Doctors({ doctors, numPerPage }) {
  const [screenWidth] = useWindowWidth();

  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const [viewedDoctors, setViewedDoctors] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { filteredDoctors } = useSelector((state) => state.doctors);
  useEffect(() => {
    if (doctors) setViewedDoctors(doctors.slice(0, numPerPage));
  }, [doctors, filteredDoctors]);

  let items = [];
  const changePage = (page) => {
    setActivePage(page);
    let docs = doctors.slice(numPerPage * (page - 1), numPerPage * page);
    setViewedDoctors(docs);
  };
  function sortDoctorsHandler(sortState) {
    console.log(filteredDoctors);
    dispatch(sortDoctors(sortState));
  }
  useEffect(() => {
    if (doctors) {
      for (
        let number = 1;
        number <= Math.ceil(doctors.length / numPerPage);
        number++
      ) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === activePage}
            onClick={() => changePage(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    }
  }, [doctors]);
  const { t } = useContext(langContext);
  return (
    <div className="doctors">
      <div className="d-flex flex-row justify-content-between p-4 pb-0">
        <h1>
          {doctors ? doctors.length : 0} {t("Service Providers")}
        </h1>
        {screenWidth > 1024 && (
          <div className="d-flex flex-row justify-content-center align-items-center">
            <DropdownButton
              className="pop-up p-1 rounded"
              id="dropdown-basic-button"
              title={t("Sort by")}
            >
              <Dropdown.Item onClick={() => sortDoctorsHandler("lth")}>
                {t("Price low to high")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => sortDoctorsHandler("htl")}>
                {t("Price high to low")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => sortDoctorsHandler("wt")}>
                {t("Waiting time")}
              </Dropdown.Item>
            </DropdownButton>
          </div>
        )}
      </div>
      {viewedDoctors.map((doctor) => (
        <Doctor doctor={doctor} key={doctor.id} />
      ))}
      <div className="d-flex justify-content-end align-items-center mb-5">
        <Pagination>{items.length > 1 ? items : null}</Pagination>
      </div>
    </div>
  );
}
