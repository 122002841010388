import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";

import { Col } from "react-bootstrap";
import { addToCart } from "../../../redux/cart";
import { editDoctorServiceCount } from "../../../redux/doctors";
import { editLabServiceCount } from "../../../redux/labs";
import { langContext } from "../../../context/LangProvider";

export default function Service({
  service,
  dataId,
  dataType,
  extraFees,
  handleConfirm,
  myAppointment,
}) {
  const [serviceCount, setServiceCount] = useState(0);
  const { doctors } = useSelector((state) => state.doctors);
  const { labs } = useSelector((state) => state.labs);
  useEffect(() => {
    if (dataType === "doctor") {
      const doctor = doctors.find((doctor) => doctor.id === dataId);
      const doctorService = doctor.services.find(
        (ser) => ser.id === service.id
      );
      const serviceCount = doctorService.count;
      setServiceCount(serviceCount);
    }
    if (dataType === "lab") {
      const lab = labs.find((lab) => lab.id === dataId);
      const labService = lab.services.find((ser) => ser.id === service.id);
      const serviceCount = labService.count;
      setServiceCount(serviceCount);
    }
  }, [dataId, dataType, doctors, labs, service.id]);

  const { cart, fees, feesWithNoDiscount, dataExistId, dataExistType } =
    useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const changeServiceCountInCart = (count) => {
    dispatch(
      addToCart({
        cart,
        newService: { service, count },
        extraFees,
        fees,
        feesWithNoDiscount,
        dataId,
        dataType,
      })
    );
    if (dataType === "doctor") {
      dispatch(
        editDoctorServiceCount({
          dataId,
          serviceCount: count,
          serviceId: service.id,
          doctors,
        })
      );
    }
    if (dataType === "lab") {
      dispatch(
        editLabServiceCount({
          dataId,
          serviceCount: count,
          serviceId: service.id,
          labs,
        })
      );
    }
  };
  const { t } = useContext(langContext);
  return (
    <Col sx={12} md={6} lg={12} xl={6}>
      <div className="service">
        <div
          className={`service-img `}
          style={{ backgroundColor: service.speciality.color }}
        >
          <img
            src={service?.image_url || service.speciality.image_url}
            alt=""
          />
        </div>
        <div className="cardText">
          <h6 className="bold">{service.name}</h6>
          <div>
            {t("Fees :")}
            <span className="bold ms-1">
              {t("EGP")}{" "}
              {parseInt(service.pivot.fees) > 0
                ? parseInt(service.pivot.fees).toFixed(2)
                : parseInt(service.pivot.fees)}
            </span>
          </div>
          <div className="mobile-buttons-cont">
            <div>
              {t("Package:")}
              <span className="bold mainColor ms-1">
                {t("EGP")}{" "}
                {parseInt(service.pivot.package) > 0
                  ? parseInt(service.pivot.package).toFixed(2)
                  : parseInt(service.pivot.package)}
              </span>
            </div>
            <div className="mobile-buttons mt-2">
              <img
                src="/assets/minus.svg"
                alt=""
                className="minusplus me-1"
                onClick={() => {
                  if (!myAppointment) {
                    serviceCount && changeServiceCountInCart(serviceCount - 1);
                  }
                }}
              />
              <span className="number">{serviceCount}</span>
              <img
                src="/assets/plus.svg"
                alt=""
                className="minusplus ms-1"
                onClick={() => {
                  if (!myAppointment) {
                    if (
                      (dataType == dataExistType && dataId == dataExistId) ||
                      dataExistId == null
                    ) {
                      changeServiceCountInCart(serviceCount + 1);
                    } else {
                      handleConfirm(true);
                    }
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}
