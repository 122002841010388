import { useCallback, useEffect, useState } from "react";
import { Editor as TinyMCE } from "@tinymce/tinymce-react";
import Header from "../components/dashboard/Header";
import SideBar from "../components/dashboard/SideBar";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DoctorOffers from "../components/dashboard/DoctorOffers";
import { CButton, CCard, CCardBody, CCardHeader, CCol, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CFormInput, CModal, CModalBody, CModalHeader, CRow } from "@coreui/react";
import { useDropzone } from "react-dropzone";
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
// {
//     title: 'Offer 1',
//     text: `<h6 class='bold mainColor'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
//     image: 'https://www.yourdentistryguide.com/wp-content/uploads/2017/11/tooth-cleaning-925x425.jpg',
//     locations: ['Location1', 'Location2'],
//     status: 'Pending',
//     discount: 100,
//     service: 'service 32'
// }, {
//     title: 'Offer 2222',
//     text: `<h6 class='bold mainColor'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
//     image: 'https://ichef.bbci.co.uk/news/976/cpsprodpb/5435/production/_113475512__110894880_gettyimages-1055182908.jpg',
//     locations: ['Location1', 'Location2'],
//     status: 'Live',
//     discount: 300,
//     service: 'service 46'
// }
export default function DashboardOffers() {
    //
    const [displayModal, setDisplayModal] = useState(false)
    const [image, setImage] = useState()
    const [selectedService, setSelectedService] = useState(null)
    const [newOffer, setNewOffer] = useState({

    })

    const [offers, setOffers] = useState([])
    const onPictureDrop = useCallback(
        (acceptedFiles) => {
            setImage(acceptedFiles[0]);
        },
        [image]
    );

    function preventNegativeNumber(e) {
        if (e.code == 'Minus' || e.target.value < 0)
            e.preventDefault()
    }
    const navigate = useNavigate(),
        [loading, setLoading] = useState(true),
        [locations, setLocations] = useState([]);
    const {
        token,
        doctor,
        doctorStatus,
        status: userStatus,
    } = useSelector((state) => state.user),
        { visible, narrow } = useSelector((state) => state.dashboard);

    async function getOffers() {
        setLoading(true);
        try {
            let res = await axios(
                process.env.REACT_APP_API_URL + "/doctor_dashboard/offers",
                {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                }
            );
            let temp = res?.data?.data
            setOffers(temp);

            console.log(res.data.data);
        } catch (error) {
            toast.error("failed to get locations");
            navigate("/");
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        if (userStatus === "success" && doctorStatus === "success") {
            // if (!doctor) {
            //     navigate("/");
            //     return;
            // }
            getOffers();
        }
    }, [userStatus, doctorStatus, doctor]);



    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onPictureDrop,
    });
    return (
        <div className="" style={{ minHeight: "100vh" }}>

            <SideBar />
            <div
                className="dashboard-container"
                style={
                    window.innerWidth > 768 && visible
                        ? narrow
                            ? { marginLeft: "65px" }
                            : { marginLeft: "256px" }
                        : { marginLeft: "0" }
                }
            >
                <Header />
                <div className="dashboard-wrapper d-flex justify-content-start flex-column align-items-start">
                    <CCard className="w-100">
                        <CCardHeader>Offers</CCardHeader>
                        <CCardBody className="p-3">
                            <CRow>
                                <Link to="create">
                                    <CButton color="info" className="text-white" >Add offer</CButton>
                                </Link>
                            </CRow>
                            <CRow>
                                {offers.length > 0 && <DoctorOffers onDelete={getOffers} offers={offers} />}
                            </CRow>
                        </CCardBody>
                    </CCard>

                    {/* <AddOffer /> */}
                </div>
            </div>
        </div >
    );
}
