import { Navigate, Route, Routes } from "react-router-dom";

import About from "./pages/about";
import ContactUs from "./pages/contact-us";
import CustomePage from "./pages/Custome-Page";
import DashboardBookings from "./pages/DashboardBookings";
import DashboardBookingsEdit from "./pages/DashboardBookingsEdit";
import DashboardLocations from "./pages/DashboardLocations";
import DashboardLocationsEdit from "./pages/DashboardLocationsEdit";
import DashboardProfile from "./pages/DashboardProfile";
import DashboardServices from "./pages/DashboardServices";
import DcotorsList from "./pages/AllDoctors";
import ForgotPassword from "./pages/forgot-password";
import Home from "./pages/home";
import Labs from "./pages/AllLabs";
import Login from "./pages/login";
import Offers from "./pages/AllOffers";
import ProfileAppointments from "./pages/ProfileAppointments";
import ProfileEdit from "./pages/ProfileEdit";
import ProfilePassword from "./pages/ProfilePassword";
import ResetPassword from "./pages/reset-password";
import ServicesPage from "./pages/AllServices";
import SignUp from "./pages/signUp";
import SingleDoctor from "./pages/SingleDoctor";
import SingleLab from "./pages/SingleLab";
import SingleOffer from "./pages/SingleOffer";
import SuccessBooking from "./pages/success-booking";
import UserService from "./services/userService";
import { useSelector } from "react-redux";
import DashboardFAQs from "./pages/DashboardFAQs";
import DashboardNewOffer from "./pages/DashboardNewOffer";
import DashboardOffers from "./pages/DashboardOffers";
import DashboardEditFAQs from "./pages/DashboardEditFAQs";
import DashboardSecurity from "./pages/DashboardSecurity";
import DashboardServiceEdit from "./Hooks/DashboardServiceEdit";

export default function DentistRoutes() {
  const { homePage, sections } = useSelector((state) => state.pages);
  const { loginStatus } = useSelector((state) => state.user);
  const { successfullBook, currentAppointment } = useSelector(
    (state) => state.cart
  );

  const userIsDoctor = UserService.checkUserRole("Doctor");
  const userIsLab = UserService.checkUserRole("Lab");
  const userIsPatient = UserService.checkUserRole("Patient");

  return (
    <Routes>
      {/* <Route
        path="/"
        element={homePage ? <CustomePage sections={sections} /> : <Home />}
      /> */}
      <Route
        path="/"
        element={
          userIsDoctor || userIsLab ? (
            <Navigate to={"/dashboard/profile"} />
          ) : homePage ? (
            <CustomePage sections={sections} />
          ) : (
            <Home />
          )
        }
      />
      <Route path="/doctors" element={<DcotorsList />} />
      <Route
        path="/doctors-filtered"
        element={<DcotorsList servicesFilter />}
      />
      <Route path="/doctorProfile/:id" element={<SingleDoctor />} />
      <Route path="/offers/:id" element={<SingleOffer />} />
      <Route
        path="/doctorProfile/:id/myAppointment"
        element={
          currentAppointment ? (
            <SingleDoctor myAppointment />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/success"
        element={successfullBook ? <SuccessBooking /> : <Home />}
      />
      <Route path="/services" element={<ServicesPage />} />
      <Route path="/offers" element={<Offers />} />
      <Route
        path="/sign-up"
        element={loginStatus ? <Navigate to="/" /> : <SignUp />}
      />
      <Route
        path="/login"
        element={loginStatus ? <Navigate to="/" /> : <Login />}
      />
      <Route
        path="/forgot-password"
        element={loginStatus ? <Navigate to="/" /> : <ForgotPassword />}
      />
      <Route
        path="/reset-password"
        element={loginStatus ? <Navigate to="/" /> : <ResetPassword />}
      />
      <Route
        path="/dashboard/bookings"
        element={
          userIsDoctor || userIsLab ? (
            <DashboardBookings />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/dashboard/security"
        element={
          userIsDoctor || userIsLab ? (
            <DashboardSecurity />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/dashboard/bookings/edit/:id"
        element={
          userIsDoctor || userIsLab ? (
            <DashboardBookingsEdit />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/dashboard/locations"
        element={
          userIsDoctor || userIsLab ? (
            <DashboardLocations />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/dashboard/locations/create"
        element={
          userIsDoctor || userIsLab ? (
            <DashboardLocationsEdit />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/dashboard/services/:id"
        element={
          userIsDoctor || userIsLab ? (
            <DashboardServiceEdit />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/dashboard/offers/create"
        element={userIsDoctor ? <DashboardNewOffer /> : <Navigate to="/" />}
      />{" "}
      <Route
        path="/dashboard/offers/:id"
        element={userIsDoctor ? <DashboardNewOffer /> : <Navigate to="/" />}
      />
      <Route
        path="/dashboard/FAQs"
        element={userIsDoctor ? <DashboardFAQs /> : <Navigate to="/" />}
      />
      <Route
        path="/dashboard/FAQs/edit"
        element={userIsDoctor ? <DashboardEditFAQs /> : <Navigate to="/" />}
      />
      <Route
        path="/dashboard/FAQs/edit/:id"
        element={userIsDoctor ? <DashboardEditFAQs /> : <Navigate to="/" />}
      />
      <Route
        path="/dashboard/offers"
        element={userIsDoctor ? <DashboardOffers /> : <Navigate to="/" />}
      />
      <Route
        path="/dashboard/locations/edit/:id"
        element={
          userIsDoctor ? <DashboardLocationsEdit /> : <Navigate to="/" />
        }
      />
      <Route
        path="/dashboard/services"
        element={
          userIsDoctor || userIsLab ? (
            <DashboardServices />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/dashboard/profile"
        element={
          userIsDoctor || userIsLab ? <DashboardProfile /> : <Navigate to="/" />
        }
      />
      <Route path="/labs" element={<Labs />} />
      <Route path="/singlelab/:id" element={<SingleLab />} />
      <Route
        path="/singlelab/:id/myAppointment"
        element={
          currentAppointment ? <SingleLab myAppointment /> : <Navigate to="/" />
        }
      />
      <Route path="/singleoffer/:id" element={<SingleOffer />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/about" element={<About />} />
      <Route
        path="/profile/update"
        element={loginStatus ? <ProfileEdit /> : <Navigate to="/login" />}
      />
      <Route
        path="/profile/password"
        element={loginStatus ? <ProfilePassword /> : <Navigate to="/login" />}
      />
      <Route
        path="/profile/appointments"
        element={
          loginStatus && userIsPatient ? (
            <ProfileAppointments />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route path="/:slug" element={<CustomePage sections={sections} />} />
    </Routes>
  );
}
