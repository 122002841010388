import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import produce from "immer";

export const getMyAppointments = createAsyncThunk(
  "myAppointments/getMyAppointments",
  async () => {
    try {
      let loginStatus = localStorage.getItem("loggedIn");
      let token = localStorage.getItem("dentistUserToken");
      if (loginStatus) {
        let res = await axios.get(
          `${process.env.REACT_APP_URL}/my_appointments`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        const appointmentsArray = res.data.data.map((appointment) => {
          let newfeesWithNoDiscount = 0;
          appointment.services.map((service) => {
            newfeesWithNoDiscount +=
              parseInt(service.pivot.quantity) * parseInt(service.pivot.fees);
            return service;
          });
          appointment.feesWithNoDiscount =
            newfeesWithNoDiscount + appointment?.provider?.examination_fee;
          return appointment;
        });
        let arr1 = [];
        for (let i = appointmentsArray.length - 1; i >= 0; i--) {
          arr1.push(appointmentsArray[i]);
        }
        return arr1;
      }
    } catch (err) {
      console.log(err);
    }
  }
);
export const cancelMyAppointments = createAsyncThunk(
  "myAppointments/calncelMyAppointment",
  async (id) => {
    console.log("id", id);
    try {
      let loginStatus = localStorage.getItem("loggedIn");
      let token = localStorage.getItem("dentistUserToken");
      if (loginStatus) {
        axios.post(
          `${process.env.REACT_APP_URL}/my_appointments/${id}/cancel`,
          {},
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        return id;
      }
    } catch (error) {}
  }
);

const myAppointmentsSlice = createSlice({
  name: "myAppointments",
  initialState: {
    myAppointments: [],
    status: "loading",
  },
  reducers: {
    cancelMyAppointment: (state, action) => {
      const index = state.myAppointments.findIndex(
        (appointment) => appointment.id === action.payload.id
      );
      let newMyAppointments = produce(state.myAppointments, (draft) => {
        draft[index].status = 2;
      });
      state.myAppointments = newMyAppointments;
    },
  },
  extraReducers: {
    [getMyAppointments.pending]: (state) => {
      state.status = "loading";
    },
    [getMyAppointments.fulfilled]: (state, action) => {
      state.status = "success";
      state.myAppointments = action.payload;
    },
    [getMyAppointments.rejected]: (state) => {
      state.status = "failed";
    },
    [cancelMyAppointments.pending]: (state) => {
      console.log("action.payload");

      state.status = "loading";
    },
    [cancelMyAppointments.fulfilled]: (state, action) => {
      console.log("action.payload", action.payload);
      state.status = "success";
      state.myAppointments = state.myAppointments.map((item) => {
        if (item.id !== action.payload) {
          return item;
        } else return { ...item, status: 2 };
      });
    },
  },
});
export default myAppointmentsSlice.reducer;
export const { cancelMyAppointment } = myAppointmentsSlice.actions;
