import {
  Col,
  Form,
  FormControl,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { langContext } from "../../../context/LangProvider";

export default function PatientInput({
  label,
  type,
  value,
  placeholder,
  onChange,
}) {
  const [visible, setVisible] = useState(false);
  const [checkValue, setCheckValue] = useState(value);
  const [inputField, setInputField] = useState(value || "dsdsd");
  useEffect(() => {
    setInputField(value);
    setCheckValue(value);
  }, [value]);
  const { t } = useContext(langContext);
  useEffect(() => {
    console.log("inputField", inputField);
    if (typeof onChange === "function") onChange(label, inputField);
  }, [inputField]);

  return (
    <Row className="patientForm align-items-center">
      <Col>{t(label)}</Col>
      <Col xs={9}>
        {type === "gender" ? (
          <Form.Group
            className="d-flex checks my-2"
            onChange={(e) => setCheckValue(e.target.value)}
          >
            <Form.Check
              type="radio"
              label={t("genderType1")}
              value="male"
              name="gender"
              checked={checkValue === "male"}
            />
            <Form.Check
              className="ms-5"
              type="radio"
              label={t("genderType2")}
              value="female"
              name="gender"
              checked={checkValue === "female"}
            />
          </Form.Group>
        ) : type === "password" ? (
          <InputGroup className="patient-password my-2">
            <FormControl
              placeholder={t(placeholder)}
              style={{ borderRight: "none" }}
              type={visible ? "text" : "password"}
              onChange={(e) => setInputField(e.target.value)}
            />
            <InputGroup.Text>
              <Image
                src={`${
                  visible ? "/assets/show-password" : "/assets/hide-password"
                }.svg`}
                className="input-image"
                onClick={() => setVisible(!visible)}
              />
            </InputGroup.Text>
          </InputGroup>
        ) : (
          <Form.Group className="my-2" controlId="exampleForm.ControlInput1">
            <Form.Control
              type={type}
              placeholder={t(placeholder)}
              value={inputField}
              disabled={type === "email"}
              onChange={(e) => setInputField(e.target.value)}
            />
          </Form.Group>
        )}
      </Col>
    </Row>
  );
}
