import {
  searchDoctorsByAvailability,
  searchDoctorsByProp,
  searchDoctorsBySpecId,
} from "../../../Helpers/searchDoctorsBy";
import { set2ndPhaseDocs, setFilterParams } from "../../../redux/doctors";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Availabilty from "./filterItems/Availabilty";
import Entity from "./filterItems/Entity";
import ExaminationFees from "./filterItems/ExaminationFees";
import Gender from "./filterItems/Gender";
import PatientCondition from "./filterItems/PatientCondition";
import Specialities from "./filterItems/Specialities";
import Title from "./filterItems/Title";
import getCommon from "../../../Helpers/getCommonInArray";

export default function FilterBody() {
  const dispatch = useDispatch();
  const { filteredDoctors } = useSelector((state) => state.doctors);
  const [selectedSpecialities, setSelectedSpecialities] = useState([]);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState([]);
  const [selectedGender, setSelectedGender] = useState([]);
  const [selectedPatientCondition, setSelectedPatientCondition] = useState([]);
  const [selectedRange, setSelectedRange] = useState([100, 1000]);
  const [selectedAvailabilities, setSelectedAvailabilities] = useState([]);
  const handleFilter = () => {
    let filterArrayBySpecialities = [];
    let filterArrayByEntities = [];
    let filterArrayByTitle = [];
    let filterArrayByGender = [];
    let filterArrayByAvailability = [];
    let filterArrayByPatientCondition = [];
    let filterArrayByFees = [];

    if (selectedSpecialities.length) {
      filterArrayBySpecialities = searchDoctorsBySpecId(
        filteredDoctors,
        selectedSpecialities,
        "speciality_id"
      );
    } else {
      filterArrayBySpecialities = filteredDoctors;
    }
    if (selectedAvailabilities.length) {
      filterArrayByAvailability = searchDoctorsByAvailability(
        filteredDoctors,
        selectedAvailabilities
      );
    } else {
      filterArrayByAvailability = filteredDoctors;
    }

    if (selectedEntities.length) {
      filterArrayByEntities = searchDoctorsByProp(
        filteredDoctors,
        selectedEntities,
        "entity"
      );
    } else {
      filterArrayByEntities = filteredDoctors;
    }
    if (selectedTitle.length) {
      filterArrayByTitle = searchDoctorsByProp(
        filteredDoctors,
        selectedTitle,
        "title"
      );
    } else {
      filterArrayByTitle = filteredDoctors;
    }

    if (selectedGender.length) {
      filterArrayByGender = searchDoctorsByProp(
        filteredDoctors,
        selectedGender,
        "gender"
      );
    } else {
      filterArrayByGender = filteredDoctors;
    }
    if (selectedPatientCondition.length) {
      filteredDoctors.forEach((doc) => {
        if (doc.patient_condition) {
          let conditionInCommon = getCommon(
            selectedPatientCondition,
            doc.patient_condition
          );
          if (conditionInCommon.length) {
            filterArrayByPatientCondition.push(doc);
          }
        }
      });
    } else {
      filterArrayByPatientCondition = filteredDoctors;
    }

    filteredDoctors.forEach((doc) => {
      if (
        doc.examination_fee >= selectedRange[0] &&
        doc.examination_fee <= selectedRange[1]
      ) {
        filterArrayByFees.push(doc);
      }
    });
    let common;
    common = getCommon(filterArrayByFees, filterArrayBySpecialities);
    common = getCommon(common, filterArrayByEntities);
    common = getCommon(common, filterArrayByAvailability);
    common = getCommon(common, filterArrayByTitle);
    common = getCommon(common, filterArrayByGender);
    common = getCommon(common, filterArrayByPatientCondition);

    common = Array.from(new Set(common));
    dispatch(set2ndPhaseDocs(common));
  };

  useEffect(() => {
    handleFilter();
    dispatch(
      setFilterParams({
        specialities: selectedSpecialities,
        availabilities: selectedAvailabilities,
        gender: selectedGender,
        title: selectedTitle,
        entity: selectedEntities,
        patientCondition: selectedPatientCondition,
      })
    );
  }, [
    selectedSpecialities,
    selectedAvailabilities,
    selectedRange,
    selectedGender,
    selectedTitle,
    selectedEntities,
    selectedPatientCondition,
  ]);
  return (
    <>
      <Title
        selectedTitle={selectedTitle}
        setSelectedTitle={setSelectedTitle}
      />
      <Specialities
        selectedSpecialities={selectedSpecialities}
        setSelectedSpecialities={setSelectedSpecialities}
      />
      <Gender
        selectedGender={selectedGender}
        setSelectedGender={setSelectedGender}
      />
      <Availabilty
        selectedAvailabilities={selectedAvailabilities}
        setSelectedAvailabilities={setSelectedAvailabilities}
      />
      <ExaminationFees
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
      />
      <Entity
        selectedEntities={selectedEntities}
        setSelectedEntities={setSelectedEntities}
      />
      <PatientCondition
        selectedPatientCondition={selectedPatientCondition}
        setSelectedPatientCondition={setSelectedPatientCondition}
      />
    </>
  );
}
