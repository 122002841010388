import Spinner, { SpinnerError } from "../Spinner";
import { useEffect, useState } from "react";

import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import SliderCard from "./SliderCard";
import { useSelector } from "react-redux";

export default function Specialists({
  selectedSpecialistsIds,
  title,
  btnText,
  bgColor,
}) {
  const { doctors, status } = useSelector((state) => state.doctors);
  const [viewedDoctors, setViewedDoctors] = useState([]);
  const [spinner, setSpinner] = useState(<Spinner height="100" />);
  console.log("Specialists");
  useEffect(() => {
    let doctors_Arr = [];
    selectedSpecialistsIds.forEach((id) => {
      let doctor = doctors.find((doctor) => doctor.id === id);
      if (doctor) {
        doctors_Arr.push(doctor);
      }
    });
    if (status === "success") {
      setViewedDoctors(doctors_Arr);
    } else if (status === "failed") {
      setSpinner(<SpinnerError />);
    }
  }, [doctors, status]);

  const settings = {
    dots: true,
    arrows: window.innerWidth > 540 ? true : false,
    infinite: true,
    speed: 500,
    slidesToShow: viewedDoctors.length > 4 ? 4 : viewedDoctors.length,
    slidesToScroll: 1,
    nextArrow: <img src="/assets/slider-arrow-right.svg" alt="" />,
    prevArrow: <img src="/assets/slider-arrow-left.svg" alt="" />,
    responsive: [
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: viewedDoctors.length > 3 ? 3 : viewedDoctors.length,
        },
      },
      {
        breakpoint: 912,
        settings: {
          slidesToShow: viewedDoctors.length > 2 ? 2 : viewedDoctors.length,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="specialists" style={{ backgroundColor: bgColor }}>
      <Container>
        <div
          className="mb-4 container"
          dangerouslySetInnerHTML={{ __html: `<h1>${title}</h1>` }}
        ></div>
        {viewedDoctors ? (
          <Slider {...settings} className="mySlider">
            {viewedDoctors.map((doctor, index) => (
              <SliderCard
                numOfCards={viewedDoctors.length}
                data={doctor}
                key={index}
                imgAlternate="/assets/noAvatar.png"
                linkText="View all upcoming availability"
                dataType="doctor"
              />
            ))}
          </Slider>
        ) : (
          <>{spinner}</>
        )}

        <div className="d-flex justify-content-center align-items-center m-top-30">
          <Link to="doctors">
            <button className="scan-more pop-up">
              {btnText}
              <i className="fas fa-chevron-right text-white"></i>
            </button>
          </Link>
        </div>
      </Container>
    </div>
  );
}
