import { Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { getIntervalsBetweenShifts, isItToday } from "doitfast";

import AppointmentButton from "../../AppointmentButton";
import DaysSlider from "../../DaysSlider";
import { IoChevronForwardCircleOutline } from "react-icons/io5";
import LocationInfo from "../../SingleDoctor/doc-info/LocationInfo";
import arrangeDaysFromToday from "../../../Helpers/arrangDaysFromToday";
import useCleanUseEffect from "./../../../Hooks/useCleanUseEffect";
import { useContext, useState } from "react";
import useWindowWidth from "../../../Hooks/useWindowWidth";
import { langContext } from "../../../context/LangProvider";

export default function Doctor({ doctor }) {
  const { t } = useContext(langContext);

  const navigate = useNavigate();
  const [screenWidth] = useWindowWidth();
  const [viewdAppointments, setViewdAppointments] = useState([]);
  const [viewdDays, setViewdDays] = useState([]);
  const [noAvailability, setNoAvailability] = useState("");
  const rating =
    5 *
    (doctor?.reviews?.reduce((acc, curr) => acc + curr.rate, 0) /
      (doctor?.reviews?.length * 5));
  useCleanUseEffect((status) => {
    if (status.aborted || !doctor?.locations[0]?.availabilities) return;
    const sortedAppointments = arrangeDaysFromToday(
      [
        ...doctor.locations[0].availabilities.filter(
          (availability) => availability.closed === false
        ),
      ],
      "accending"
    );
    if (sortedAppointments.length) {
      let isToday = isItToday(sortedAppointments[0].date);
      let viewdAppointments = getIntervalsBetweenShifts(
        sortedAppointments[0].open_hour,
        sortedAppointments[0].close_hour,
        "00:30",
        isToday
      );
      setViewdAppointments(viewdAppointments);

      if (viewdAppointments.length === 0)
        setNoAvailability("No Slots Available For the selected date");
      setViewdDays(sortedAppointments);
    } else {
      setNoAvailability("No Slots Available For the selected date");
    }
  }, []);
  return (
    <Card className="doctor-card card-shadow">
      <Row>
        <Col xs={12} md={6} className="left">
          {screenWidth >= 768 && (
            <Card.Title className="mb-4">
              <Link
                to={`/doctorProfile/${doctor.id}`}
                style={{ textDecoration: "none", color: "black" }}
              >
                {t("Doctor")} <span className="bold"> {doctor.name}</span>
              </Link>
            </Card.Title>
          )}
          <Row className="left-cont">
            <Col xs={3}>
              <div className="img-cont">
                <img
                  src={
                    doctor.profile_picture?.original_url ||
                    "/assets/noAvatar.png"
                  }
                  alt={doctor.name}
                />
              </div>
            </Col>
            <Col xs={5} md={4} className="card-text">
              {screenWidth < 768 && (
                <div className="ms-2">
                  <Link
                    to={`/doctorProfile/${doctor.id}`}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    {t("Doctor")}
                    <span className="bold">{doctor.name}</span>
                  </Link>
                </div>
              )}
              <div className=" ms-1 booking-item">
                {t("Specialists for")}
                {"   "}
                <span className="me-2 bold">{doctor.speciality.name}</span>
              </div>
              {screenWidth >= 768 && (
                <>
                  <div className="">
                    <img
                      src="/assets/location.svg"
                      alt=""
                      className="icon-img-size"
                    />
                    <span className="ms-2">
                      <LocationInfo data={doctor} />
                    </span>
                  </div>
                  <div className="booking-item gap-1">
                    <img
                      src="/assets/fees.svg"
                      alt=""
                      className="icon-img-size"
                    />
                    <span className="ms-2">{t("Fees :")}</span>
                    <span className="bold ms-1">
                      {t("EGP")} {doctor.examination_fee.toFixed(2)}
                    </span>
                  </div>
                  <div className="booking-item gap-1">
                    <img
                      src="/assets/waiting-time.svg"
                      alt=""
                      className="icon-img-size"
                    />
                    <span className="ms-2">{t("Waiting Time:")}</span>
                    <span className="bold ms-1">{doctor.waiting_time}</span>
                  </div>
                  <div className="booking-item gap-1">
                    <img
                      src="/assets/star.svg"
                      alt=""
                      className="icon-img-size"
                    />
                    <span className="ms-2 bold">{rating || 0}</span>
                    <span className="text-muted ms-1">
                      ({doctor?.reviews?.length || 0} {t("reviews")})
                    </span>
                  </div>
                </>
              )}
            </Col>
            <Col xs={12}>
              {screenWidth < 768 && (
                <div className="my-3">
                  <div>
                    <img
                      src="/assets/location.svg"
                      alt=""
                      className="icon-img-size"
                    />
                    <span className="ms-2">
                      <LocationInfo data={doctor} />
                    </span>
                  </div>
                  <div>
                    <img
                      src="/assets/fees.svg"
                      alt=""
                      className="icon-img-size"
                    />
                    <span className="ms-2">({t("Fees :")})</span>
                    <span className="bold ms-1">
                      {t("EGP")} {doctor.examination_fee.toFixed(2)}
                    </span>
                  </div>
                  <div>
                    <img
                      src="/assets/waiting-time.svg"
                      alt=""
                      className="icon-img-size"
                    />
                    <span className="ms-2">{t("Waiting Time:")}</span>
                    <span className="bold sm-1">{doctor.waiting_time}</span>
                  </div>
                  <div>
                    <img
                      src="/assets/star.svg"
                      alt=""
                      className="icon-img-size"
                    />
                    <span className="ms-2 bold">{rating || 0}</span>
                    <span className="text-muted ms-1">
                      ({doctor?.reviews?.length || 0} {t("reviews")})
                    </span>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Col>
        {screenWidth >= 768 ? (
          <Col sm={12} md={6} className="right">
            <Row className="gx-3">
              {noAvailability !== "" && (
                <div className="py-3 text-center">{t(noAvailability)}</div>
              )}
              {viewdAppointments.length > 0 && (
                <DaysSlider
                  days={viewdDays}
                  setViewdAppointmentsHandler={(appoint) => {
                    let isToday = isItToday(appoint.date);
                    let viewdAppointments = getIntervalsBetweenShifts(
                      appoint.open_hour,
                      appoint.close_hour,
                      "00:30",
                      isToday
                    );
                    setViewdAppointments(viewdAppointments);
                  }}
                />
              )}

              <Col sm={12} className="doctor-appoinments">
                {viewdAppointments.length > 0 && (
                  <Row className="my-2 gx-3">
                    {viewdAppointments.map((appointment, i) => (
                      <AppointmentButton
                        key={i}
                        spacing={4}
                        appointment={appointment}
                        appointmentHandler={() =>
                          navigate(`/doctorProfile/${doctor.id}`)
                        }
                      />
                    ))}
                  </Row>
                )}
              </Col>
              <Col sm={12}>
                {noAvailability === "" && (
                  <div className="book-link">
                    <Link to={`/doctorProfile/${doctor.id}`}>
                      <button className="pop-up2">
                        {t("Book An Appointment")}{" "}
                        <IoChevronForwardCircleOutline />
                      </button>
                    </Link>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        ) : (
          <Col sm={12} md={6} className="alte-right">
            <div className="d-flex justify-content-between align-items-center">
              <Link to={`/doctorProfile/${doctor.id}`}>
                <button>Available Today From 9:00 PM</button>
              </Link>
              <Link to={`/doctorProfile/${doctor.id}`}>
                <button className="book">{t("Book Now")}</button>
              </Link>
            </div>
          </Col>
        )}
      </Row>
    </Card>
  );
}
