import { useContext } from "react";
import { useSelector } from "react-redux";
import { langContext } from "../context/LangProvider";

export default function BookingCardAddedServices({
  bookedServices,
  fees,
  feesWithNoDiscount,
  myAppointment,
}) {
  const { currentAppointment } = useSelector((state) => state.cart);
  const { t } = useContext(langContext);
  return (
    <>
      <div className="booking-item">
        <img src="/assets/booking.svg" alt="" className="mx-2 icon-img-size" />
        <span className="me-1">{t("Booking ")}</span>
        {myAppointment ? (
          <span className="bold">
            {currentAppointment?.services.map((service, i) => (
              <span key={i}>
                {i !== 0 ? " , " : ""}
                <span
                  style={{
                    backgroundColor: "#dbc17f57",
                    padding: "0.2rem",
                    borderRadius: "0.3rem",
                  }}
                >
                  {service.pivot.quantity}
                </span>
                <span style={{ color: "#a99156" }} className="mx-1">
                  x
                </span>
                <span className="mx-1">{service?.name}</span>
              </span>
            ))}
            {bookedServices.map((bookedService, i) => (
              <span className={bookedService.count > 0 ? "" : "d-none"} key={i}>
                {i !== 0 ? " , " : ""}
                <span
                  style={{
                    backgroundColor: "#dbc17f57",
                    padding: "0.2rem",
                    borderRadius: "0.3rem",
                  }}
                >
                  {bookedService.count}
                </span>
                <span style={{ color: "#a99156" }} className="mx-1">
                  x
                </span>
                <span className="mx-1">{bookedService?.service?.name}</span>
              </span>
            ))}
          </span>
        ) : (
          <span className="bold">
            {bookedServices?.map((bookedService, i) => (
              <span
                className={bookedService?.count > 0 ? "" : "d-none"}
                key={i}
              >
                {i !== 0 ? " , " : ""}
                <span
                  style={{
                    backgroundColor: "#dbc17f57",
                    padding: "0.2rem",
                    borderRadius: "0.3rem",
                  }}
                >
                  {bookedService.count}
                </span>
                <span style={{ color: "#a99156" }} className="mx-1">
                  x
                </span>
                <span className="mx-1">{bookedService?.service?.name}</span>
              </span>
            ))}
          </span>
        )}
      </div>
      <div className="booking-item ">
        <img src="/assets/fees.svg" alt="" className="mx-2 icon-img-size" />
        <span className="me-1">{t("Fees :")}</span>
        {myAppointment ? (
          <span className="bold me-1">
            {t("EGP")}
            {currentAppointment?.fees > 0
              ? currentAppointment?.fees.toFixed(2)
              : currentAppointment?.fees}
          </span>
        ) : (
          <span className="bold me-1">
            {t("EGP")} {fees > 0 ? fees.toFixed(2) : fees}
          </span>
        )}
        {myAppointment ? (
          <small className="text-muted-dashed">
            {currentAppointment?.feesWithNoDiscount > 0 &&
              `EGP ${currentAppointment?.feesWithNoDiscount?.toFixed(2)}`}
          </small>
        ) : (
          <small className="text-muted-dashed">
            {feesWithNoDiscount < 0 &&
              `${t("EGP")} ${feesWithNoDiscount?.toFixed(2)}`}
          </small>
        )}
      </div>
    </>
  );
}
