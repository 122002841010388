import { useDispatch, useSelector } from "react-redux";

import { Col } from "react-bootstrap";
import { IoChevronForwardCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { setDoctors } from "../../redux/doctors";

export default function Service({ service }) {
  const { doctors } = useSelector((state) => state.doctors);
  const dispatch = useDispatch();

  const handleFilterByService = () => {
    let filterBy = [];
    doctors.map((doc) => {
      doc.services.map((ser) => {
        if (ser.id === service.id) {
          filterBy.push(doc);
        }
        return ser;
      });
      return doc;
    });
    filterBy = Array.from(new Set(filterBy));
    dispatch(setDoctors(filterBy));
  };
  return (
    <Col sm={12} md={6} lg={4}>
      <div className="service">
        <div className="cardText">
          <div>
            <h6 className="bold">{service.name}</h6>
            <p>
              {service.description && service.description.length > 30
                ? service.description.substring(0, 30) + "..."
                : service.description}
            </p>
          </div>
          <Link
            className="bold"
            to="/doctors-filtered"
            onClick={handleFilterByService}
          >
            <button className="pop-up2 text-underline">
              View Doctors <IoChevronForwardCircleOutline />
            </button>
          </Link>
        </div>
        <div
          className="service-img"
          style={{ backgroundColor: service.speciality.color }}
        >
          <div className="circle-cont">
            <div
              className="circle"
              style={{ backgroundColor: service.speciality.color }}
            ></div>
          </div>
          <div className="img-cont">
            <img
              src={service.image_url || service.speciality.image_url}
              alt={service.name}
            />
          </div>
        </div>
      </div>
    </Col>
  );
}
