import Spinner, { SpinnerError } from "../../Spinner";
import { useEffect, useState } from "react";

import { Container } from "react-bootstrap";
import Slider from "react-slick";
import TopReviewCard from "./TopReviewCard";
import { useSelector } from "react-redux";

export default function Testimonials({ items, bgColor, title }) {
  const { reviews, status } = useSelector((state) => state.reviews);
  const [viewdReviews, setViewedLabs] = useState([]);
  const [spinner, setSpinner] = useState(<Spinner height="100" />);
  const filteredReviews = reviews.filter((item) => item.status === 1);

  const getLastReviewsWithSpecificNumber = (arr) => {
    return arr.slice(-1 * parseInt(items), arr.length);
  };
  useEffect(() => {
    if (status === "success") {
      setViewedLabs(getLastReviewsWithSpecificNumber(filteredReviews));
    } else if (status === "failed") {
      setSpinner(<SpinnerError />);
    }
  }, [reviews, status]);
  console.log("reviews", reviews);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: viewdReviews.length >= 3 ? 3 : viewdReviews.length,
    slidesToScroll: 1,
    nextArrow: <img src="assets/slider-arrow-right.svg" alt="" />,
    prevArrow: <img src="assets/slider-arrow-left.svg" alt="" />,
    responsive: [
      {
        breakpoint: 912,
        settings: {
          slidesToShow: viewdReviews.length >= 2 ? 2 : viewdReviews.length,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div style={{ backgroundColor: bgColor }}>
      <Container className="testo specialists">
        <h1 className="mb-4">
          <div dangerouslySetInnerHTML={{ __html: title }}></div>
        </h1>
        {viewdReviews ? (
          <Slider {...settings} className="mySlider">
            {viewdReviews.map((review, index) => (
              <TopReviewCard
                numOfCards={viewdReviews.length}
                review={review}
                key={index}
              />
            ))}
          </Slider>
        ) : (
          <>{spinner}</>
        )}
      </Container>{" "}
    </div>
  );
}
