import DoctorsList from "../components/AllDoctors/DoctorsList";
import Searchbar from "../components/searchbar/Searchbar";

export default function DcotorsList({ servicesFilter }) {
  const numPerPage = 10;
  return (
    <div>
      <Searchbar doctorslistPadding="p-top-100" activeTab="appointment" />
      {servicesFilter ? (
        <DoctorsList numPerPage={numPerPage} servicesFilter />
      ) : (
        <DoctorsList numPerPage={numPerPage} />
      )}
    </div>
  );
}
