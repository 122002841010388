import { t } from "i18next";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { langContext } from "../../context/LangProvider";

export default function Agreement({ label, link }) {
  const { t } = useContext(langContext);
  return (
    <div>
      <div className="terms">
        {t("terms")}
        <Link to="/terms-conditions"> {t("conditions")}</Link>
        <hr />
      </div>
      <div
        className={`d-flex  align-items-center mt-4 pb-4 terms space ${
          link === "Sign up"
            ? "justify-content-between"
            : "justify-content-center"
        }`}
      >
        {link === "Sign up" || link === "إنشاء حساب" ? (
          <div>
            <Link to="/forgot-password">{t("forgotPassword")}</Link>
          </div>
        ) : (
          <></>
        )}
        <span>
          <span className="me-1">{t(label)}</span>
          <Link
            to={
              link === "Sign up" || link === "إنشاء حساب"
                ? "/sign-up"
                : "/login"
            }
          >
            {" "}
            {t(link)}
          </Link>
        </span>
      </div>
    </div>
  );
}
