import AboutPage from "../components/about/AboutPage";
import { Container } from "react-bootstrap";
import FAQ from "../components/faq/FAQ";
import { useSelector } from "react-redux";
import { useContext } from "react";
import { langContext } from "../context/LangProvider";

export default function About() {
  const { faqs } = useSelector((state) => state.faqs);
  const { t } = useContext(langContext);
  return (
    <div className=" p-top-50">
      <Container>
        <AboutPage />
        {faqs.length > 0 && (
          <FAQ fullWidth="w-100" faqData={faqs} title={t("FAQs")} />
        )}
      </Container>
    </div>
  );
}
