import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function SuccessBokking() {
  const { successfullBook } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  return (
    <div className="success-booking-cont">
      <div className="d-flex flex-column justify-content-center align-items-center mb-5">
        <img src="assets/successfully.svg" alt="" />
        <h3>Your booking was successfully submitted</h3>
      </div>
      <div>
        <h5>
          <img src="assets/sms.svg" alt="" className="icon-img-size" />
          <span className="bold ms-2">
            We notified{" "}
            {successfullBook.dataName === "doctor" ? "Doctor" : "Lab"}{" "}
            {successfullBook.data.name} of your booking
          </span>
        </h5>
        <h5>
          <img src="assets/fees.svg" alt="" className="icon-img-size" />
          <span className="mx-2">Fees :</span>
          <span className="bold me-1">
            EGP {successfullBook.fees.toFixed(2)}
          </span>
          <span className="text-muted-dashed">
            EGP{" "}
            {successfullBook.feesWithNoDiscount > 0
              ? successfullBook.feesWithNoDiscount.toFixed(2)
              : successfullBook.feesWithNoDiscount}
          </span>
        </h5>
        <hr />
      </div>
      <div>
        {successfullBook.data.phone && (
          <h5>
            <img src="assets/phone.svg" alt="" className="icon-img-size" />
            <span className="mx-2">Clinic Number:</span>
            <span className="bold">{successfullBook.data.phone}</span>
          </h5>
        )}

        <h5>
          <img src="assets/location.svg" alt="" className="icon-img-size" />
          <span className="mx-2 c-width">Clinic Address :</span>
          {successfullBook.locations.map((location, i) => (
            <span className="bold" key={i}>
              {location.label}
              {i < successfullBook.locations.length - 1 && <span> , </span>}
            </span>
          ))}
          {successfullBook.locations.length === 1 &&
            successfullBook.locations.map((location, i) => (
              <span className="bold" key={i}>
                {location.street} - {location.street_2} - {location.area.name}
              </span>
            ))}
        </h5>
        <hr className="hr-line" />
      </div>
      <div>
        <h5>
          <img src="assets/patient-name.svg" alt="" className="icon-img-size" />
          <span className="mx-2 p-width">Patient Name:</span>
          <span className="bold">{user.name}</span>
        </h5>
        <h5>
          <img src="assets/booking-date.svg" alt="" className="icon-img-size" />
          <span className="mx-2">Booking Date:</span>
          <span className="bold">{successfullBook.date}</span>
        </h5>
        <h5>
          <img src="assets/doctor-name.svg" alt="" className="icon-img-size" />
          <span className="mx-2 d-width">
            {successfullBook.dataName === "doctor" ? "Doctor" : "Lab"} Name:
          </span>
          <span className="bold">{successfullBook.data.name}</span>
        </h5>
        <h5>
          <img src="assets/waiting-time.svg" alt="" className="icon-img-size" />
          {successfullBook.dataName === "doctor" ? (
            <>
              <span className="mx-2">Waiting Time:</span>
              <span className="bold">{successfullBook?.data?.waiting_time}</span>
            </>
          ) : (
            <>
              <span className="mx-2">Working Hours:</span>
              <span className="bold">{successfullBook.workingHours}</span>
            </>
          )}
        </h5>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-4 pb-4">
        <Link to="/profile/appointments">
          <button className="pop-up">My Appointments</button>
        </Link>
      </div>
    </div>
  );
}
