import Rate from "rc-rate";

export default function Review({ review }) {
  return (
    <div className="review-card">
      <div>
        <div className="d-flex align-items-center">
          <div className="review-avatar">
            <img
              src={
                review.user.gender === "male"
                  ? "/assets/men.png"
                  : "/assets/women.png"
              }
              alt=""
            />
          </div>
          <div className="d-flex flex-column w-100">
            <h6 style={{ textTransform: "capitalize" }} className="bold">
              {review?.user?.name}
            </h6>
            <div className="d-flex justify-content-between">
              <div
                className="d-flex justify-content-center align-items-center"
                id="rate"
              >
                <Rate
                  count={5}
                  value={review.rate}
                  character={<i className="fas fa-star"></i>}
                  disabled={true}
                />
              </div>
              <div>{new Date(review.created_at).toLocaleDateString()}</div>
            </div>
          </div>
        </div>
        <small className="text-center">{review.comment}</small>
      </div>
    </div>
  );
}
