import { Container, Row } from "react-bootstrap";

import PatientNavGroup from "../components/patient-profile/profile components/PatientNavGroup";
import ProfileAppointMents from "../components/patient-profile/ProfileAppointMents";
import React from "react";
import useWindowWidth from "../Hooks/useWindowWidth";

export default function ProfileAppointments() {
  const [screenWidth] = useWindowWidth();

  return (
    <div className=" p-top-50">
      <Container>
        <div className="patient-profile-col">
          <Row className="justify-content-center">
            {screenWidth <= 767 ? null : <PatientNavGroup />}

            <ProfileAppointMents />
          </Row>
        </div>
      </Container>
    </div>
  );
}
