import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import produce from "immer";
import { clearParams } from "./search";

export const getServices = createAsyncThunk(
  "services/getServices",
  async () => {
    let res = await axios(`${process.env.REACT_APP_URL}/services`);
    return res.data.data;
  }
);

const servicesSlice = createSlice({
  name: "services",
  initialState: {
    services: [],
    status: null,
  },

  extraReducers: {
    [getServices.pending]: (state) => {
      state.status = "loading";
    },
    [getServices.fulfilled]: (state, action) => {
      state.status = "success";
      state.services = action.payload;
    },
    [getServices.rejected]: (state) => {
      state.status = "failed";
    },
  },
});
export const { selectAllServices } = servicesSlice.actions;
export default servicesSlice.reducer;
