import { Form, Spinner } from "react-bootstrap";
import { getLoginStatus, login } from "../../redux/auth";

import AuthInput from "./AuthInput";
import axios from "axios";
import { getMyAppointments } from "../../redux/myappointments";
import { getPages } from "../../redux/pages";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { langContext } from "../../context/LangProvider";

export default function LoginForm({
  formData,
  formWidth,
  formPadding,
  dataName,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState({ email: "", password: "" });
  const [logingIn, setLogingIn] = useState(false);
  const [loginText, setLogininText] = useState("loginButton");

  const { t } = useContext(langContext);

  const changeValues = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const loginHandler = async (e) => {
    e.preventDefault();
    setLogingIn(true);
    setLogininText(<Spinner animation="border" size="sm" />);
    try {
      let res = await axios.post(`${process.env.REACT_APP_AUTH_URL}/login`, {
        email: values.email,
        password: values.password,
      });
      loginSteps(res);
    } catch (err) {
      console.log(err);
      if (err.response.data) toast.error("Login Failed");
    } finally {
      setLogingIn(false);
      setLogininText("Login");
    }
  };
  async function getHomePage() {
    await dispatch(getPages("/home"));
    navigate("/");
  }
  async function loginSteps(res) {
    if (dataName !== "doctor" && dataName !== "lab") await getHomePage();
    dispatch(login(res.data.data));
    if (
      ["doctor", "lab"].includes(res.data.data.user.roles[0].name.toLowerCase())
    ) {
      navigate("/dashboard/profile");
    }
    dispatch(getLoginStatus());
    dispatch(getMyAppointments());
  }
  return (
    <Form onSubmit={loginHandler} className={formPadding}>
      {formData.map((input, index) => (
        <AuthInput
          key={index}
          formWidth={formWidth}
          label={t(input.label)}
          name={t(input.name)}
          placeholder={t(input.label)}
          type={t(input.type)}
          value={values[input.name]}
          onChange={changeValues}
        />
      ))}
      <div className="d-flex justify-content-center align-items-center mt-4 pb-4">
        <button disabled={logingIn} className="pop-up login">
          {t(loginText)}
        </button>
      </div>
    </Form>
  );
}
