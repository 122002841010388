/* eslint-disable default-case */

import { GoogleMap, Marker } from "@react-google-maps/api";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { useEffect, useState } from "react";

import { Spinner } from "react-bootstrap";

const mapRender = (status) => {
  switch (status) {
    case Status.LOADING:
      return <Spinner animation="border" />;
    case Status.FAILURE:
      return "err";
    case Status.SUCCESS:
      return <MyMapComponent />;
  }
};
const MyMapComponent = ({ location, setLocation }) => {
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (map && !isNaN(location.latitude) && !isNaN(location.longitude)) {
      let targetLocation = {
        lat: Number(location.latitude),
        lng: Number(location.longitude),
      };
      map.setCenter(targetLocation);
      setMarkerPosition(targetLocation);
      map.panTo(targetLocation);
    }
  }, [location, map]);
  const containerStyle = {
    width: "100%",
    height: "200px",
  };
  const [center, setCenter] = useState({
    lat: 31.2,
    lng: 31.3,
  });
  const [markerPosition, setMarkerPosition] = useState(null);

  const chooseLocation = (e) => {
    setMarkerPosition(e.latLng);
    setCenter(e.latLng);
    const tmpLocation = {
      ...location,
      longitude: map.getCenter().toJSON().lng,
      latitude: map.getCenter().toJSON().lat,
    };
    setLocation(tmpLocation);
  };
  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={(map) => setMap(map)}
      onClick={chooseLocation}
    >
      {markerPosition && <Marker position={markerPosition} />}
    </GoogleMap>
  );
};
export default function Mapselector({ location, setLocation }) {
  return (
    <Wrapper
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      render={mapRender}
      libraries={["places"]}
    >
      <MyMapComponent location={location} setLocation={setLocation} />
    </Wrapper>
  );
}
