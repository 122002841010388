import { Form, Row } from "react-bootstrap";

import MyVerticallyCenteredModal from "./servicesModal";
import Service from "./ServiceItem";
import useSearchServices from "./../../../Hooks/useSearchServices";
import { useState } from "react";
import { useContext } from "react";
import { langContext } from "../../../context/LangProvider";

export default function Services({
  services,
  data,
  dataType,
  extraFees,
  handleConfirm,
  myAppointment,
}) {
  const [search, setSearch] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [searched, handleSearch] = useSearchServices(services, search);
  const { t } = useContext(langContext);
  return (
    <div className="doc-services">
      <div className="service-box">
        <h5 className="bold pb-3">{t("Services")}</h5>
        <div className="search-services">
          <img className="icon" src="/assets/search-sml.svg" alt="" />
          <Form.Group className="mb-4" controlId="formBasicEmail">
            <Form.Control
              value={search}
              type="text"
              placeholder={t("Search in services...")}
              className="search"
              onChange={(e) => {
                handleSearch(e.target.value);
                setSearch(e.target.value);
              }}
            />
          </Form.Group>
        </div>
      </div>
      <div>
        <Row>
          {searched.slice(0, 6).map((s, i) => (
            <Service
              service={s}
              key={i}
              dataId={data.id}
              dataType={dataType}
              extraFees={extraFees}
              handleConfirm={handleConfirm}
              myAppointment={myAppointment}
            />
          ))}
        </Row>
        <button
          style={{
            border: "none",
            backgroundColor: "transparent",
            color: "#a99156",
            fontSize: "18px",
            textDecoration: "underline",
          }}
          onClick={() => setModalShow(true)}
        >
          {t("View All Services")}
        </button>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          handleConfirm={handleConfirm}
          extraFees={extraFees}
          data={services}
          dataType={dataType}
          dataName={data.name}
          dataId={data.id}
          myAppointment={myAppointment}
        />
      </div>
    </div>
  );
}
