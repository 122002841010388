import { Modal, Row } from "react-bootstrap";

import Review from "./review";

export default function reviewsModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      className="doc-services"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Doctor<span className="mainColor">{props.doctorName}'s</span> Reviews
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {props?.reviews?.map((review, i) => (
            <Review review={review} key={i} />
          ))}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="pop-up"
          style={{ padding: "0.5rem 1rem", borderRadius: "0.4rem" }}
          onClick={props.onHide}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
