import { useContext } from "react";
import { Accordion } from "react-bootstrap";
import { langContext } from "../../../../context/LangProvider";
import CheckBoxItem from "../CheckBoxItem";

export default function Availabilty({
  selectedAvailabilities,
  setSelectedAvailabilities,
}) {
  const handleChangeAvailabilities = (e) => {
    if (e.target.checked) {
      setSelectedAvailabilities([...selectedAvailabilities, e.target.value]);
    } else {
      setSelectedAvailabilities(
        selectedAvailabilities.filter(
          (ser) =>
            new Date(ser).toDateString() !==
            new Date(e.target.value).toDateString()
        )
      );
    }
  };
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  const { t } = useContext(langContext);
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>{t("Availabilty")}</Accordion.Header>
        <Accordion.Body>
          <CheckBoxItem checkBoxToggle={() => {}} label={t("Any Day")} />
          <CheckBoxItem
            checkBoxToggle={handleChangeAvailabilities}
            label={t("Today")}
            value={today}
          />
          <CheckBoxItem
            checkBoxToggle={handleChangeAvailabilities}
            label={t("Tomorrow")}
            value={tomorrow}
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
