import { useContext } from "react";
import { Form } from "react-bootstrap";
import { langContext } from "../../../context/LangProvider";

export default function CheckBoxItem({ checkBoxToggle, value, label }) {
  const { lang } = useContext(langContext);
  return (
    <Form.Check
      style={{
        display: lang === "ar" ? "flex" : "",

        gap: lang === "ar" ? "30px" : "opx",
        marginRight: lang === "ar" ? "10px" : "0px",
        padding: lang === "ar" ? "0" : "",
      }}
      className="booking-item"
      type="checkbox"
      label={label}
      id={label}
      value={value}
      onChange={(e) => checkBoxToggle(e)}
    />
  );
}
