import AllRoutes from "./routes";
import Footer from "./components/Footer";
import NavBar from "./components/Navbar";
import useCheckPreLoading from "./Hooks/useCheckPreLoading";

export default function Layout() {
  let [fullyLoaded] = useCheckPreLoading();
  return fullyLoaded ? (
    <>
      <NavBar />
      <AllRoutes />
      <Footer />
    </>
  ) : (
    <div>
      <img
        src="/assets/loader.gif"
        alt=""
        style={{ width: "100vw", height: "100vh", objectFit: "cover" }}
      />
    </div>
  );
}
