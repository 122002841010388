import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    visible: true,
    narrow: false,
  },
  reducers: {
    changeDashboardVisibilty: (state, action) => {
      state.visible = action.payload;
    },
    changeDashboardNarrow: (state) => {
      state.narrow = !state.narrow;
    },
  },
});
export default dashboardSlice.reducer;
export const { changeDashboardVisibilty, changeDashboardNarrow } =
  dashboardSlice.actions;
