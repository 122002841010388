import { useState } from "react";

export default function About({ title, borderTop, about }) {
  const [hide, setHide] = useState("d-none");
  const [more, setMore] = useState("Show More");
  const [dots, setDots] = useState("...");
  const handleMore = () => {
    if (hide === "d-none") {
      setHide("");
      setMore("Show Less");
      setDots("");
    } else {
      setHide("d-none");
      setMore("Show More");
      setDots("...");
    }
  };
  return (
    <div className="doc-about my-3">
      {borderTop ? <div className="borderTop"></div> : ""}
      <h5 className="bold pt-2">{title}</h5>
      <span>{about?.substring(0, 250)}</span>
      {about?.length > 250 && <span>{dots}</span>}
      <span className={hide}>{about?.substring(250)}</span>
      {about?.length > 250 && (
        <span className="more" onClick={handleMore}>
          {more}
        </span>
      )}
    </div>
  );
}
