import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { clearParams } from "./search";

export const getCities = createAsyncThunk("cities/getCities", async () => {
  let res = await axios(`${process.env.REACT_APP_URL}/cities`);
  return res.data.data;
});

const citiesSlice = createSlice({
  name: "cities",
  initialState: {
    cities: [],
    citiesSelection: [],
    allCities: true,
    status: null,
  },
  reducers: {
    citiesHighlight: (state, action) => {
      const exist = state.citiesSelection.find(
        (city) => +city.id === +action.payload.city.id
      );
      if (exist) {
        let tmpFilteredCities = state.citiesSelection.filter(
          (city) => city.id !== action.payload.city.id
        );
        state.citiesSelection = tmpFilteredCities;
        if (state.citiesSelection.length === 0) {
          state.allCities = true;
        }
      } else {
        state.citiesSelection = [...state.citiesSelection, action.payload.city];
        state.allCities = false;
      }
    },
    selectAllCities: (state, action) => {
      state.allCities = true;
      state.citiesSelection = [];
    },
  },
  extraReducers: {
    [getCities.pending]: (state) => {
      state.status = "loading";
    },
    [getCities.fulfilled]: (state, action) => {
      state.status = "success";
      state.cities = action.payload;
    },
    [getCities.rejected]: (state) => {
      state.status = "failed";
    },
    [clearParams]: (state) => {
      state.citiesSelection = [];
    },
  },
});
export default citiesSlice.reducer;
export const { citiesHighlight, selectAllCities } = citiesSlice.actions;
