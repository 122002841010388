import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FaTooth } from "react-icons/fa";
import { useSelector } from "react-redux";
import { langContext } from "../../../context/LangProvider";
import BookingCardAddedServices from "../../BookingCardAddedServices";

export default function AppointmentModal(props) {
  const [appointment, setAppointment] = useState(props.appointment);
  useEffect(() => {
    setAppointment(props.appointment);
  }, [props.appointment]);
  console.log(appointment);
  const { t } = useContext(langContext);
  return (
    <>
      {appointment && props.appointment && (
        <Modal
          Modal
          {...props}
          size="md"
          className="doc-services"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <div className="container">{t("Appointment details")}</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {appointment && (
              <>
                <BookingCardAddedServices
                  bookedServices={appointment?.services}
                  fees={appointment?.fees}
                  feesWithNoDiscount={appointment?.feesWithNoDiscount}
                  myAppointment={appointment}
                />
                {appointment?.provider_notes && (
                  <>
                    <div style={{ display: "flex" }}>
                      <img
                        src="/assets/edit-inactive.svg"
                        alt=""
                        className="mx-2 icon-img-size"
                      />
                      <span className="me-1">{t("Doctor Note :")}</span>
                      <span className="bold">
                        {appointment?.provider_notes?.text}
                      </span>
                    </div>
                  </>
                )}
                {appointment?.provider_notes?.teeth && (
                  <div className="m-2">
                    <FaTooth size={20} />
                    <span className="mx-1">{t("Teeth notes :")}</span>
                    {appointment?.provider_notes?.teeth.map((tooth, index) => {
                      return (
                        <div className="mx-5 d-flex flex-column">
                          <span className="me-1 booking-item">
                            {index + 1} <span>{t("- Tooth :")}</span>
                            <span className="bold">{tooth.value}</span>
                          </span>
                          <span className="me-1 mx-4 booking-item">
                            {t(" Note :")}{" "}
                            <span className="bold">{tooth.note || ""}</span>
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div>
                  {appointment?.patient_notes && (
                    <div className="booking-item">
                      <img
                        src="/assets/edit-inactive.svg"
                        alt=""
                        className="mx-2 icon-img-size"
                      />
                      <span className="me-1">{t("Patient notes :")} </span>
                      <span className="bold">
                        {appointment?.patient_notes ?? ""}
                      </span>
                    </div>
                  )}
                  {appointment?.gallery?.length > 0 && (
                    <div>
                      <span className="bold">{t("Image(s) :")}</span>
                      <div
                        className="d-flex flex-row w-100"
                        style={{ overflowX: "scroll" }}
                      >
                        {appointment?.gallery.map((image) => {
                          return (
                            <img
                              onClick={() => {
                                window.open(
                                  image.original_url,
                                  "_blank",
                                  "noopener,noreferrer"
                                );
                              }}
                              src={image.original_url}
                              style={{
                                cursor: "pointer",
                                width: "200px",
                                height: "200px",
                                display: "flex",
                              }}
                              className="m-2 icon-img-size border rounded"
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="pop-up"
              style={{ padding: "0.5rem 1rem", borderRadius: "0.4rem" }}
              onClick={props.onHide}
            >
              {t("Close")}
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
