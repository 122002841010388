import { useEffect, useState } from "react";

import { Form } from "react-bootstrap";

export default function ContactFormInput({ value, onChange, input }) {
  const [extraItem, setExtraItem] = useState("");
  const [extraOptions, setExtraOptions] = useState([]);
  const extraItemPreparer = (e) => {
    setExtraItem(e.target.value);
  };
  useEffect(() => {
    onChange({ type: "array", items: extraOptions }, input);
  }, [extraOptions]);
  const addExtraOption = () => {
    setExtraOptions((prev) => [...prev, extraItem]);
    setExtraItem("");
  };
  const removeItem = (item) => {
    let newItems = extraOptions.filter((option) => option !== item);
    setExtraOptions(newItems);
  };
  return (
    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label className="bold">{input.label}</Form.Label>
      {input.type === "textarea" ? (
        <Form.Control
          as="textarea"
          rows="5"
          required={input?.validation?.required}
          type={input.type}
          placeholder={input?.props?.placeholder}
          value={value}
          name={input.name}
          onChange={onChange}
        />
      ) : input.type === "checkbox" ? (
        <Form.Group className="d-flex checks" onChange={onChange}>
          {input.options.map((option) => (
            <Form.Check
              required={input?.validation?.required}
              label={option.label}
              value={option.value}
              name={input.name}
              id={option.label}
              className="mx-2"
              type={input?.props?.multiple ? "checkbox" : "radio"}
            />
          ))}
        </Form.Group>
      ) : input.type === "select" ? (
        <>
          <Form.Select
            multiple={input?.props?.multiple}
            aria-label="Default select example"
            onChange={(e) => onChange(e, input)}
            name={input.name}
            className="mb-3"
            required={input?.validation?.required}
          >
            {console.log(input)}
            {input.options.map((option, i) => (
              <option
                disabled={i === 0}
                selected={i === 0}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </Form.Select>
          {input.showText && (
            <Form.Control
              as="textarea"
              className="mb-3"
              rows="5"
              required={input?.extra[value].validation?.required}
              type={input.extra[value].type}
              placeholder={input?.extra[value].props?.placeholder}
              name={input.extra[value].name}
            />
          )}
        </>
      ) : input.type === "file" ? (
        <Form.Control
          required={input?.validation?.required}
          type={input.type}
          value={value}
          multiple={input?.props.multiple}
          name={input.name}
          onChange={(e) => onChange(e, input)}
        />
      ) : input?.props?.array ? (
        <>
          <div className="d-flex">
            <Form.Control
              required={input?.validation?.required}
              type={input.type}
              value={extraItem}
              name={input.name}
              onChange={extraItemPreparer}
              placeholder={input?.props?.placeholder}
              minLength={input?.props?.min}
              maxLength={input?.props?.max}
            />
            <button onClick={addExtraOption} className="mx-2 pop-up">
              Add
            </button>
          </div>
          <div className="extra-items-box">
            {extraOptions.map((extra, i) => (
              <span className="extra-item" key={i}>
                {extra}
                <span onClick={() => removeItem(extra)} className="remove-item">
                  X
                </span>
              </span>
            ))}
          </div>
        </>
      ) : (
        <Form.Control
          required={input?.validation?.required}
          type={input.type}
          value={value}
          name={input.name}
          onChange={onChange}
          placeholder={input?.props?.placeholder}
          minLength={input?.props?.min}
          maxLength={input?.props?.max}
        />
      )}
    </Form.Group>
  );
}
