import { Accordion } from "react-bootstrap";
import CheckBoxItem from "../CheckBoxItem";
import useCleanUseEffect from "../../../../Hooks/useCleanUseEffect";
import { useSelector } from "react-redux";
import { useContext, useState } from "react";
import { langContext } from "../../../../context/LangProvider";

export default function Specialities({
  selectedSpecialities,
  setSelectedSpecialities,
}) {
  const { specialities: allspecialities } = useSelector(
    (state) => state.specialities
  );
  const [specialities, setSpecialities] = useState([]);
  useCleanUseEffect(
    (status) => {
      if (!status.aborted) setSpecialities(allspecialities);
    },
    [allspecialities]
  );
  const handleChangeSpeciality = (e) => {
    if (e.target.checked) {
      setSelectedSpecialities([
        ...selectedSpecialities,
        parseInt(e.target.value),
      ]);
    } else {
      setSelectedSpecialities(
        selectedSpecialities.filter((ser) => ser !== parseInt(e.target.value))
      );
    }
  };
  const { t } = useContext(langContext);
  return (
    <Accordion className="booking-item" defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>{t("Specialities")}</Accordion.Header>
        <Accordion.Body>
          {specialities.length > 0 &&
            specialities.map((speciality, i) => (
              <CheckBoxItem
                key={i}
                checkBoxToggle={handleChangeSpeciality}
                label={speciality.name}
                value={speciality.id}
              />
            ))}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
