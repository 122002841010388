import { CHeader, CHeaderBrand } from "@coreui/react";
import { cilArrowThickFromLeft, cilArrowThickFromRight } from "@coreui/icons";
import { useDispatch, useSelector } from "react-redux";

import CIcon from "@coreui/icons-react";
import React from "react";
import { changeDashboardVisibilty } from "../../redux/dashboard";

export default function Header() {
  const dispatch = useDispatch();
  const { visible } = useSelector((state) => state.dashboard);
  const toggleSidebar = () => {
    const val = [true, "responsive"].includes(visible) ? false : "responsive";
    dispatch(changeDashboardVisibilty(val));
  };
  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(visible) ? true : "responsive";
    dispatch(changeDashboardVisibilty(val));
  };
  return (
    <CHeader>
      <CHeaderBrand style={{ cursor: "pointer" }}>
        {visible ? (
          <>
            <CIcon
              className="ml-md-3 d-md-none"
              onClick={toggleSidebarMobile}
              icon={cilArrowThickFromRight}
              size="xl"
            />
            <CIcon
              className="ml-3 d-none d-md-block"
              onClick={toggleSidebar}
              icon={cilArrowThickFromRight}
              size="lg"
            />
          </>
        ) : (
          <>
            <CIcon
              className="ml-md-3 d-md-none"
              onClick={toggleSidebarMobile}
              icon={cilArrowThickFromLeft}
              size="xl"
            />
            <CIcon
              className="ml-3 d-none d-md-block"
              onClick={toggleSidebar}
              icon={cilArrowThickFromLeft}
              size="lg"
            />
          </>
        )}
      </CHeaderBrand>
    </CHeader>
  );
}
