import { Card } from "react-bootstrap";
import LocationInfo from "./LocationInfo";
import useWindowWidth from "../../../Hooks/useWindowWidth";
import { useContext } from "react";
import { langContext } from "../../../context/LangProvider";

export default function DocInfo({ data }) {
  const [screenWidth] = useWindowWidth();
  const rating =
    5 *
    (data?.reviews?.reduce((acc, curr) => acc + curr.rate, 0) /
      (data?.reviews?.length * 5));
  const fiveStarRating =
    (100 * data?.reviews?.filter((rating) => rating.rate === 5).length) /
    (data.reviews.length || 1);
  const { t } = useContext(langContext);
  return (
    <div className="doc-info">
      <div className="doc-info-cont">
        <div>
          <div className="img-cont">
            <img
              src={data.profile_picture?.original_url || "/assets/noAvatar.png"}
              alt={data.name}
            />
          </div>
        </div>
        <div className="card-text ms-2">
          <Card.Title>
            {t("Doctor")} <span className="bold"> {data.name}</span>
          </Card.Title>

          <div className="mt-2">
            {t("Specialists for")}{" "}
            <span className="bold">{data?.speciality?.name}</span>
          </div>

          {screenWidth >= 540 && (
            <>
              <div>
                <img
                  src="/assets/location.svg"
                  alt=""
                  className="icon-img-size me-2"
                />
                <LocationInfo data={data} />
              </div>
              <div>
                <img
                  src="/assets/star.svg"
                  alt=""
                  className="icon-img-size me-2"
                />
                <span className="bold">{rating || 0}</span>
                <span className="text-muted ms-1">
                  ({data?.reviews?.length || 0} {t("reviews")})
                </span>
              </div>
              {data.online_payment === "1" && (
                <div>
                  <img
                    src="/assets/credit-card.svg"
                    alt=""
                    className="icon-img-size me-2"
                  />
                  <span>{t("Online Payment Available")}</span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {screenWidth < 540 && (
        <>
          <div className="mt-2">
            <img
              src="/assets/location.svg"
              alt=""
              className="icon-img-size me-2"
            />
            <LocationInfo data={data} />
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <img
                src="/assets/star.svg"
                alt=""
                className="icon-img-size me-2"
              />
              <span className="bold">{rating}</span>
              <span className="text-muted ms-1">
                ({data?.reviews?.length || 0} {t("reviews")})
              </span>
            </div>
            <div className="pe-5">
              <img
                src="/assets/waiting-time.svg"
                alt=""
                className="icon-img-size me-2"
              />
              <span className="bold">{data.waiting_time}</span>
            </div>
          </div>

          {data.online_payment === "1" && (
            <div>
              <img
                src="/assets/credit-card.svg"
                alt=""
                className="icon-img-size me-2"
              />
              <span>{t("Online Payment Available")}</span>
            </div>
          )}
        </>
      )}
      {screenWidth >= 540 && (
        <>
          <div className="recommend mt-2">
            <div className="d-flex justify-content-between align-items-center my-2">
              <img
                src="/assets/like.svg"
                alt=""
                className="me-2 icon-img-size"
              />
              <div>
                <div className="bold">{t("Highly Recommended")}</div>
                <small>
                  {fiveStarRating}{" "}
                  {t("% of the patients gave this doctor 5 stars")}
                </small>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center my-2">
              <img
                src="/assets/timer.svg"
                alt=""
                className="me-2 icon-img-size"
              />
              <div>
                <div className="bold">{t("Excellent wait time")}</div>
                <small>{t("97% of patients waited less than 30 min.")}</small>
              </div>
            </div>
            <div></div>
          </div>
        </>
      )}
    </div>
  );
}
