import { Card } from "react-bootstrap";
import Rate from "rc-rate";

export default function Testo({ review, numOfCards }) {
  return (
    <Card className={`testo-card card-shadow ${numOfCards === 1 && "oneCard"}`}>
      <Card.Body>
        <div className="testo-avatar">
          <img src={"/assets/men.png"} alt="" />
        </div>
        <Card.Title
          className="text-center mb-2"
          style={{ textTransform: "capitalize" }}
        >
          {review?.user?.name}
        </Card.Title>
        <Card.Text className="text-center">{review.comment}</Card.Text>
        <div className="d-flex justify-content-center align-items-center">
          <Rate
            count={5}
            value={review.rate || 0}
            character={<i className="fas fa-star"></i>}
            disabled={true}
          />
        </div>
      </Card.Body>
    </Card>
  );
}
