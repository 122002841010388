import React from "react";

export default function BookAgainStatus({ currentAppointment }) {
  return (
    <div className="booking-item d-flex align-items-center">
      <>
        <img src="/assets/status.svg" alt="" className="mx-2 icon-img-size" />
        <span className="me-1">Status :</span>
        <span className="bold">
          {currentAppointment.status === 1 ? (
            <span style={{ color: "teal" }}>Confirmed</span>
          ) : currentAppointment.status === 2 ? (
            <span style={{ color: "rgb(211 61 47)" }}>Canceled</span>
          ) : currentAppointment.status === 3 ? (
            <span style={{ color: "rgba(50,220,100,1)" }}>Completed</span>
          ) : (
            <span style={{ color: "#7e5e05" }}>Pending</span>
          )}
        </span>
      </>
    </div>
  );
}
