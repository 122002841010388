import { Col, Container, Row } from "react-bootstrap";

import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";

export default function Offers({ btnUrl, btnText, title, slides }) {
  const settings = {
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "ease-in-out",
  };
  const navigate = useNavigate();
  console.log("offers");
  return (
    <div className="offers-cont">
      <Container>
        <div
          className="mb-4"
          dangerouslySetInnerHTML={{ __html: `<h1>${title}</h1>` }}
        ></div>
        <div className="offers card-shadow">
          <Slider {...settings} className="w-100">
            {slides.map((slide, index) => (
              <div key={index}>
                <Row
                  className="offer-slide gx-0 container"
                  style={{ backgroundImage: `url(${slide.image_url})` }}
                >
                  <Col xs={6} className="offer-slide-col">
                    <button className={slide.visibility ? "" : "d-none"}>
                      Offers
                    </button>
                    <div
                      className="container"
                      dangerouslySetInnerHTML={{
                        __html: `<h1>${slide.description}</h1>`,
                      }}
                    ></div>
                    {/* <Link to={""}> */}
                    <button
                      onClick={() => {
                        navigate(`/offers/${slide.provider_id}`, {
                          state: slide,
                        });
                      }}
                      className="pop-up"
                    >
                      {slide.btnText}
                      {"Explore offer"}
                      <img src="/assets/arrow-right.svg" alt="" />
                    </button>
                    {/* </Link> */}
                  </Col>
                </Row>
              </div>
            ))}
          </Slider>
        </div>
        {/* <div className="d-flex justify-content-center align-items-center m-top-30">
          <Link to="offers">
            <button className="scan-more pop-up p-2 px-5 rounded">
              SEE ALL <i className="fas fa-chevron-right text-white"></i>
            </button>
          </Link>
        </div> */}
      </Container>
    </div>
  );
}
