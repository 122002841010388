import { Accordion } from "react-bootstrap";
import Slider from "rc-slider";
import { useContext } from "react";
import { langContext } from "../../../../context/LangProvider";

export default function ExaminationFees({ selectedRange, setSelectedRange }) {
  const { createSliderWithTooltip } = Slider;
  const Range = createSliderWithTooltip(Slider.Range);
  const handleChangeRange = (value) => {
    setSelectedRange(value);
  };
  const { t } = useContext(langContext);
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>{t("Examination Fee")}</Accordion.Header>
        <Accordion.Body>
          <Range
            min={100}
            max={1000}
            className="p-1"
            defaultValue={selectedRange}
            allowCross={false}
            onAfterChange={(value) => handleChangeRange(value)}
            tipFormatter={(value) => `${value} ${t("EGP")}`}
          />
          <div className="d-flex justify-content-between align-items-center">
            <small className="ms-3">100 {t("EGP")}</small>
            <small className="me-3">1000 {t("EGP")}</small>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
