import { Col, Container, Form, Row } from "react-bootstrap";

import { Link } from "react-router-dom";
import { getPages } from "../redux/pages";
import { useDispatch } from "react-redux";
import { useContext, useState } from "react";
import { langContext } from "../context/LangProvider";

export default function Footer() {
  const [feedback, setFeedback] = useState("");
  const dispatch = useDispatch();
  const handleSubmitFeedback = (e) => {
    e.preventDefault();
  };
  const { t, lang } = useContext(langContext);
  return window.location.pathname.includes("dashboard") ? null : (
    <div className="footer-bottom">
      <Container>
        <Row className="justify-content-between">
          <Col xs={6} sm={6} lg="auto">
            <h3>{t("usefulLinks")}</h3>
            <div className="links">
              <Link to="/profile/update">{t("account")}</Link>
              <Link to="/doctors">{t("doctorsSpecialists")}</Link>
              <Link to="/offers">{t("offers")}</Link>
              <Link to="/labs">{t("scanLabs")}</Link>
            </div>
          </Col>
          <Col xs={6} sm={6} lg="auto">
            <h3>{t("more")}</h3>
            <div className="links">
              <Link to="/profile/update">{t("account")}</Link>
              <Link to="/contact-us">{t("contactus")}</Link>
              <Link to="/about">{t("Aboutus")}</Link>
            </div>
          </Col>
          <Col xs={6} sm={6} lg="auto">
            <h3>{t("needHelp")}</h3>
            <div className="links">
              <Link
                to="/terms-conditions"
                onClick={() => dispatch(getPages("/terms-conditions"))}
              >
                {t("conditions")}
              </Link>
              <Link
                to="/privacy-policy"
                onClick={() => dispatch(getPages("/privacy-policy"))}
              >
                {t("privacyPolicy")}
              </Link>
              <Link
                to="/booking-refund"
                onClick={() => dispatch(getPages("/booking-refund"))}
              >
                {t("bookingRefundPolicy")}
              </Link>

              <Link to="/FAQs" onClick={() => dispatch(getPages("/FAQs"))}>
                {t("FAQ")}
              </Link>
            </div>
          </Col>
          <Col xs={6} sm={6} lg="auto">
            <h3>{t("shareYourFeedback")}</h3>
            <div className="feedback">
              <Form onSubmit={(e) => handleSubmitFeedback(e)}>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    placeholder={t("writeyourfeedback")}
                  />
                </Form.Group>
                <button
                  onClick={() => {
                    console.log("nowaday");
                  }}
                  className="bold"
                >
                  {t("send")}{" "}
                  <i
                    className={
                      lang === "en"
                        ? "fas fa-chevron-right"
                        : "fas fa-chevron-left"
                    }
                  ></i>
                </button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
