import { useEffect, useState } from "react";

export default function useSearchServices(services, search) {
  const [searched, setSearched] = useState(services);
  const handleSearch = (value) => {
    search = value;
  };
  useEffect(() => {
    if (search !== "") {
      const filteredSearch = services.filter((s) =>
        s.name.toLowerCase().startsWith(search.toLowerCase())
      );
      setSearched(filteredSearch);
    } else {
      setSearched(services);
    }
  }, [search]);
  return [searched, handleSearch];
}
