import { CCard, CCardBody, CCardHeader, CCol, CRow } from "@coreui/react";
import { useEffect, useState } from "react";

import Pill from "../patient-profile/profile components/Pill";
import SpicyDatatable from "spicy-datatable";
import { Spinner } from "react-bootstrap";
import getTotalFees from "../../Helpers/getTotalFees";
import { useNavigate } from "react-router-dom";

export default function Bookings({ bookings, loading }) {
  const navigateFunction = (item) => {
    navigate(`edit/${item}`);
  };
  const config = {
    itemsPerPageLabel: "Bookings Per Page",
    noEntriesLabel: "No Bookings To Show",
    entryCountLabels: ["Showing", "to", "of", "Bookings."],
  };
  const columns = [
    { key: "patient", label: "Patient", sort: true },
    { key: "services", label: "Services", sort: false },
    { key: "fees", label: "Fees", sort: true },
    { key: "time", label: "Time", sort: true },
    { key: "status", label: "Status", sort: false },
  ];
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const getRows = () => {
    const rows = bookings.map((booking, i) => {
      let date = new Date(booking.time);
      let d = date.getDay() + 1;
      let m = date.getMonth() + 1;
      let y = date.getFullYear();
      return {
        patient: booking.user.name,
        services: booking.services.map((service) => service.name).join(" , "),
        fees: getTotalFees(booking.services, booking.fees) + " EGP",
        time: `${d}/${m}/${y} ${date.getHours() < 10 ? "0" : ""}${date
          .toLocaleTimeString()
          .replace(":00", "")}`,
        status:
          booking.status === 1 ? (
            <Pill bgColor="teal" color="#fff" status="Confirmed" />
          ) : booking.status === 2 ? (
            <Pill bgColor="#fbd9d6" color="#ee5f54" status="Canceled" />
          ) : booking.status === 3 ? (
            <Pill bgColor="#d6eddc" color="#34a853" status="Completed" />
          ) : (
            <Pill bgColor="#f7b204" color="rgb(58 52 34)" status="Pending" />
          ),
        onClickHandler: () => navigateFunction(booking.id),
      };
    });
    setRows(rows);
  };
  useEffect(() => {
    !loading && getRows();
  }, [loading]);
  return (
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>Bookings</CCardHeader>
          <CCardBody>
            {loading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" />
              </div>
            ) : (
              <div className="booking-table">
                <SpicyDatatable
                  tableKey="booking table"
                  columns={columns}
                  rows={rows}
                  config={config}
                />
              </div>
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
}
