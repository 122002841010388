import { useEffect, useState } from "react";

import Header from "../components/dashboard/Header";
import Locations from "../components/dashboard/Locations";
import SideBar from "../components/dashboard/SideBar";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function DashboardLocations() {
  const navigate = useNavigate(),
    [loading, setLoading] = useState(true),
    [locations, setLocations] = useState([]);
  const {
      token,
      doctor,
      doctorStatus,
      status: userStatus,
    } = useSelector((state) => state.user),
    { visible, narrow } = useSelector((state) => state.dashboard);
  useEffect(() => {
    if (userStatus === "success" && doctorStatus === "success") {
      if (!doctor) {
        navigate("/");
        return;
      }
      getLocations();
    }
  }, [userStatus, doctorStatus, doctor]);
  async function getLocations() {
    setLoading(true);
    try {
      let res = await axios(
        process.env.REACT_APP_API_URL + "/doctor_dashboard/locations",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setLocations(res.data.data);
    } catch (error) {
      toast.error("failed to get locations");
      navigate("/");
    } finally {
      setLoading(false);
    }
  }
  return (
    <div className="" style={{ minHeight: "100vh" }}>
      <SideBar />
      <div
        className="dashboard-container"
        style={
          window.innerWidth > 768 && visible
            ? narrow
              ? { marginLeft: "65px" }
              : { marginLeft: "256px" }
            : { marginLeft: "0" }
        }
      >
        <Header />
        <div className="dashboard-wrapper">
          <Locations locations={locations} loading={loading} />
        </div>
      </div>
    </div>
  );
}
