import AboutSection from "./AboutSection";

export default function AboutPage() {
  const image = "assets/Who We Are.png";
  const text =
    "<h1>Who We Are</h1><h6 class='bold mainColor'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nequeneque, vehicula vitae tellus a, tempor suscipit nisi. Praesent velmagna arcu. Pellentesque condimentum hendrerit dolor quis porttitor.Nullam finibus tincidunt enim vel accumsan. Nam nec mauris auctor,convallis ligula eget, dictum sapien. Nullam tincidunt malesuada lacusnon dignissim</p>";
  return (
    <div className="AboutPage">
      <AboutSection text={text} image={image} />
      <AboutSection reversed text={text} image={image} />
      <AboutSection text={text} image={image} />
    </div>
  );
}
