import React, { useContext } from "react";
import { langContext } from "../../context/LangProvider";

export default function CardTitle({ dataName, isShowingModal }) {
  const { t } = useContext(langContext);
  return (
    <>
      {isShowingModal ? (
        <div className="text-center bold py-1">
          {dataName === "doctor" ? t("Booking ") : t("Scan Lab ")}
          {t("Information")}
        </div>
      ) : (
        <div className="booking-header">
          {dataName === "doctor" ? t("Booking ") : t("Scan Lab ")}
          {t("Information")}
        </div>
      )}
    </>
  );
}
