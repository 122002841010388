import { cilPlus } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow } from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SpicyDatatable from "spicy-datatable";

export default function Services({ doctor, setLoading, loading }) {
  const navigateFunction = (item) => {
    navigate(`${item}`);
  };
  const config = {
    itemsPerPageLabel: "Services Per Page",
    noEntriesLabel: loading ? "Loading" : "No Services To Show",
    entryCountLabels: ["Showing", "to", "of", "Services."],
  };
  const columns = [
    { key: "name", label: "Name", sort: true },
    { key: "fees", label: "Fees", sort: true },
    { key: "package", label: "Package", sort: false },
  ];
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const getRows = () => {
    try {
      const rows = doctor.services.map((service, i) => {
        return {
          name: service?.name,
          fees: service?.pivot.fees,
          package: service?.pivot.package,
          onClickHandler: () => navigateFunction(service.id),
        };
      });
      setRows(rows);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getRows();
  }, [loading, doctor]);
  return (
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>Services</CCardHeader>
          <div className="d-flex justify-content-end my-2">
            <CButton
              variant="ghost"
              size="sm"
              color="dark"
              onClick={() => navigate("create")}
            >
              <CIcon icon={cilPlus} />
              Add New Service
            </CButton>
          </div>
          <CCardBody>
            {loading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" />
              </div>
            ) : (
              <div className="booking-table">
                <SpicyDatatable
                  tableKey="booking table"
                  columns={columns}
                  rows={rows}
                  config={config}
                />
              </div>
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
}
