import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

import axios from "axios"
import produce from "immer"

export const  getLabs=createAsyncThunk(
    'labs/getLabs',
    async ()=> {
        let res=await axios(`${process.env.REACT_APP_URL}/labs`)
        const labs=res.data.data.map(lab=>{lab.services.map(service=>
            service.count=0
        )
        return lab
    })
        return labs
    }
)

const labsSlice=createSlice({
    name:'labs',
    initialState:{
        labs:[],
        status:null
    },reducers:{
        editLabServiceCount:(state, action)=>{
            const index1=action.payload.labs.findIndex(doctor=>doctor.id===action.payload.dataId)
            const index2=action.payload.labs[index1].services.findIndex(service=>service.id===action.payload.serviceId)
            let newLabs=produce(action.payload.labs,draft=>{
                draft[index1].services[index2].count=action.payload.serviceCount
            })
        state.labs=newLabs
        }
    },
    extraReducers:{
        [getLabs.pending]:(state)=> {
            state.status='loading'
        },
        [getLabs.fulfilled]:(state,action)=> {
            state.status='success'
            state.labs=action.payload
        },
        [getLabs.rejected]:(state)=> {
            state.status='failed'
        }
    }
})
export default labsSlice.reducer
export const {editLabServiceCount} =labsSlice.actions
