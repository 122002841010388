import axios from "axios";

class UserService {
  static async getDoctorProfile() {
    let loginStatus = localStorage.getItem("loggedIn");
    let token = localStorage.getItem("dentistUserToken");
    if (true) {
      try {
        let res = await fetch(
          `${process.env.REACT_APP_API_URL}/doctor_dashboard/doctor`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        const json = await res.json();
        console.log("user Service", json.data);
        // localStorage.setItem("dentistUserRole", json.data.roles[0].name);

        return {
          loginStatus,
          user: json.data,
          profile: json.data.profile || null,
          token,
        };
      } catch (error) {
        console.log("user Service", error.message);
        if (error.response?.data === "Unauthorized") {
          console.log("Unauthorized");
          // localStorage.removeItem("loggedIn");
          // localStorage.removeItem("dentistUserToken");
          // localStorage.removeItem("dentistUserRole");
        }
      }
    }
    // return {
    //   loginStatus: false,
    //   user: null,
    //   profile: null,
    //   token: null,
    // };
  }
  static async getMyProfile() {
    console.log("");
    let loginStatus = localStorage.getItem("loggedIn");
    let token = localStorage.getItem("dentistUserToken");
    if (loginStatus) {
      try {
        let res = await axios.post(
          `${process.env.REACT_APP_AUTH_URL}/me`,
          {},
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        console.log("Res", res);
        localStorage.setItem("dentistUserRole", res.data.data.roles[0].name);
        return {
          loginStatus,
          user: res.data.data || null,
          profile: res.data.data.profile || null,
          token,
        };
      } catch (error) {
        if (error.response.data === "Unauthorized.")
          localStorage.removeItem("loggedIn");
        localStorage.removeItem("dentistUserToken");
        localStorage.removeItem("dentistUserRole");
      }
    }
    return {
      loginStatus: false,
      user: null,
      profile: null,
      token: null,
    };
  }
  static login(token) {
    localStorage.setItem("loggedIn", true);
    localStorage.setItem("dentistUserToken", token);
  }
  static logout() {
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("dentistUserToken");
    localStorage.removeItem("dentistUserRole");
  }
  static checkUserRole(userRole) {
    let role = localStorage.getItem("dentistUserRole");
    if (role === userRole) return true;
    return false;
  }
}
export default UserService;
