import { createSlice } from "@reduxjs/toolkit";
import { clearParams } from "./search";

const areasSlice = createSlice({
  name: "areas",
  initialState: {
    areas: [],
    areasSelection: [],
    allAreas: true,
  },
  reducers: {
    selectAreas: (state, action) => {
      state.areas = action.payload.areas;
    },
    areasHighlight: (state, action) => {
      const exist = state.areasSelection.find(
        (ser) => ser.id === action.payload.area.id
      );
      if (exist) {
        let tmpFilteredAreas = state.areasSelection.filter(
          (ser) => ser.id !== action.payload.area.id
        );
        state.areasSelection = tmpFilteredAreas;
        if (state.areasSelection.length === 0) {
          state.allAreas = true;
        }
      } else {
        state.areasSelection = [...state.areasSelection, action.payload.area];
        state.allAreas = false;
      }
    },
    selectAllAreas: (state) => {
      state.allAreas = true;
      state.areasSelection = [];
    },
  },
  extraReducers: {
    [clearParams]: (state) => {
      state.areasSelection = [];
    },
  },
});
export default areasSlice.reducer;
export const { areasHighlight, selectAreas, selectAllAreas } =
  areasSlice.actions;
