import { Form, Overlay, Tooltip } from "react-bootstrap";
import { useContext, useRef, useState } from "react";
import { langContext } from "../../context/LangProvider";

export default function AuthInput({
  label,
  name,
  placeholder,
  formWidth,
  type,
  value,
  onChange,
  error,
  submitted,
}) {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const { t } = useContext(langContext);
  return (
    <>
      <Form.Group
        className={`mt-2 mb-1 d-flex  justify-content-between align-items-center`}
        controlId={name}
      >
        <Overlay target={target.current} show={show} placement="top">
          {(props) => (
            <Tooltip id="overlay-example" {...props}>
              Password requirements:
              <ul>
                <li>6 or more Characters</li>
                <li>Symbol - Number - Capital Letter</li>
              </ul>
            </Tooltip>
          )}
        </Overlay>
        <Form.Label>{label}</Form.Label>
        {type === "gender" ? (
          <Form.Group className="d-flex checks" onChange={onChange}>
            <Form.Check
              type="radio"
              label={t("genderType1")}
              value="male"
              name="gender"
              id="male"
            />
            <Form.Check
              id="female"
              className="ms-5"
              type="radio"
              label={t("genderType2")}
              value="female"
              name="gender"
            />
          </Form.Group>
        ) : type === "password" ? (
          <Form.Control
            ref={target}
            onClick={() => setShow(!show)}
            onBlur={() => setShow(false)}
            className={formWidth}
            required
            name={name}
            isInvalid={error?.length > 0}
            value={value}
            onChange={onChange}
            type={type}
            placeholder={placeholder}
          />
        ) : (
          <Form.Control
            className={formWidth}
            required
            isInvalid={error?.length > 0}
            isValid={error?.length === 0 && submitted}
            name={name}
            value={value}
            onChange={onChange}
            type={type}
            placeholder={placeholder}
          />
        )}
      </Form.Group>
      <div className="err-box">
        <div className="empty"></div>
        <div className="err">
          {error?.length > 0 &&
            error.map((err) => <div className="text-danger mt-2">{err}</div>)}
        </div>
      </div>
    </>
  );
}
