import Spinner, { SpinnerError } from "../components/Spinner";
import { useContext, useEffect, useState } from "react";

import About from "../components/SingleDoctor/about";
import BookingCard from "../components/BookingCard/BookingCard";
import ClinicPhotos from "../components/SingleDoctor/ClinicPhotos";
import { Container } from "react-bootstrap";
import Direction from "../components/SingleDoctor/direction";
import DocInfo from "../components/SingleDoctor/doc-info/DocInfo";
import FAQ from "../components/faq/FAQ";
import Reviews from "../components/SingleDoctor/reviews/reviews";
import Services from "../components/SingleDoctor/services/services";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { faqsService } from "../services/faqsService";
import { langContext } from "../context/LangProvider";

export default function DoctorProfile({ myAppointment }) {
  let { id } = useParams();
  const { doctors, status } = useSelector((state) => state.doctors);
  const { faqs } = useSelector((state) => state.faqs);
  const [FAQs, setFAQs] = useState([]);
  const [doctor, setDoctor] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const [spinner, setSpinner] = useState(<Spinner height="100" />);
  useEffect(() => {
    async function fetchSingleDoctor() {
      const doctor = doctors.find((doctor) => doctor.id === parseInt(id));
      if (status === "success") {
        setDoctor(doctor);
      } else if (status === "failed") {
        setSpinner(<SpinnerError />);
      }
    }
    fetchSingleDoctor();
  }, [doctors, id, status]);
  useEffect(() => {
    console.log(myAppointment);
  }, [myAppointment]);
  useEffect(() => {
    setFAQs([]);
    if (doctor) {
      console.log(doctor);
      setFAQs(doctor.faqs);
    }
  }, [doctor]);
  const [over, setOver] = useState("d-none");
  const overLay = (s) => {
    setOver(s);
  };
  const entity_gallery = [
    { original_url: "/assets/clinic.png" },
    { original_url: "/assets/clinic.png" },
    { original_url: "/assets/clinic.png" },
    { original_url: "/assets/clinic.png" },
    { original_url: "/assets/clinic.png" },
    { original_url: "/assets/clinic.png" },
    { original_url: "/assets/clinic.png" },
    { original_url: "/assets/clinic.png" },
  ];
  const personal_gallery = [
    { original_url: "/assets/doctor-work.png" },
    { original_url: "/assets/doctor-work.png" },
    { original_url: "/assets/doctor-work.png" },
    { original_url: "/assets/doctor-work.png" },
    { original_url: "/assets/doctor-work.png" },
    { original_url: "/assets/doctor-work.png" },
    { original_url: "/assets/doctor-work.png" },
    { original_url: "/assets/doctor-work.png" },
    { original_url: "/assets/doctor-work.png" },
    { original_url: "/assets/doctor-work.png" },
    { original_url: "/assets/doctor-work.png" },
  ];
  const { t } = useContext(langContext);
  return (
    <div className=" p-top-100">
      <div className={`black-overlay ${over}`}></div>
      {doctor ? (
        <Container>
          <div className="doc-profile-info-cont">
            <DocInfo data={doctor} />
            <BookingCard
              overLay={overLay}
              data={doctor}
              dataName="doctor"
              confirmation={confirmation}
              handleConfirm={(value) => {
                setConfirmation(value);
                setOver(value ? "" : "d-none");
              }}
              myAppointment={myAppointment}
            />
          </div>
          <Services
            myAppointment={myAppointment}
            services={doctor.services}
            data={doctor}
            extraFees={doctor.examination_fee}
            dataType="doctor"
            handleConfirm={(value) => {
              setConfirmation(value);
              setOver(value ? "" : "d-none");
            }}
          />
          <About
            title={`${t("About Dr")}. ${doctor.name}`}
            about={doctor.about}
          />
          <ClinicPhotos
            title={t("Clinic Gallery")}
            gallery={
              doctor.entity_gallery?.length
                ? doctor.entity_gallery
                : entity_gallery
            }
          />
          <ClinicPhotos
            title={t("Doctor's Gallery")}
            gallery={
              doctor.personal_gallery?.length
                ? doctor.personal_gallery
                : personal_gallery
            }
          />
          <Direction data={doctor} />
          <Reviews reviews={doctor?.reviews} doctorName={doctor.name} />
          {FAQs.length > 0 && <FAQ faqData={FAQs} title={t("FAQs")} />}
        </Container>
      ) : (
        <>{spinner}</>
      )}
    </div>
  );
}
