export default function getCommon(arr1, arr2) {
    let common = []; // Array to contain common elements
    for (let i = 0; i < arr1.length; ++i) {
      for (let j = 0; j < arr2.length; ++j) {
        if (arr1[i] == arr2[j]) {
          // If element is in both the arrays
          common.push(arr1[i]); // Push to common array
        }
      }
    }
    return common; // Return the common elements
  }