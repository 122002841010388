import {
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSwitch,
  CFormTextarea,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import {
  conditions,
  entities,
  genders,
  titles,
} from "../../Helpers/staticData";

import Select from "react-select";
import useCleanUseEffect from "./../../Hooks/useCleanUseEffect";
import { useSelector } from "react-redux";
import { useState } from "react";
import UserService from "../../services/userService";

export default function ProfileInfo({ doctor, setDoctor, errors }) {
  const [specialities, setSpecialities] = useState([]);
  const { specialities: allspecialities } = useSelector(
    (state) => state.specialities
  );
  console.log("ProfileInfo", doctor);
  useCleanUseEffect(
    (status) => {
      if (!status.aborted) setSpecialities(allspecialities);
    },
    [allspecialities]
  );

  return (
    <div>
      <CCard>
        <CCardBody>
          <CRow>
            <CCol md="6">
              <CForm>
                <CFormLabel>Name</CFormLabel>
                <CFormInput
                  name="name"
                  placeholder="Enter doctor's name"
                  value={doctor?.name}
                  onChange={(e) =>
                    setDoctor({ ...doctor, name: e.target.value })
                  }
                />
                <div className="text-danger">{errors?.name}</div>
              </CForm>
            </CCol>
            {UserService.checkUserRole("Doctor") && (
              <>
                <CCol md="6">
                  <CFormLabel>Title</CFormLabel>
                  <Select
                    options={titles}
                    onChange={(item) =>
                      setDoctor({
                        ...doctor,
                        title: item?.value,
                      })
                    }
                    value={titles.filter(
                      (title) => title.value === doctor?.title
                    )}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.label}
                    isClearable
                    isSearchable
                    placeholder="Select title"
                  />
                  <div className="text-danger">{errors?.title}</div>
                </CCol>
                <CCol md="6">
                  <CFormLabel>Speciality</CFormLabel>
                  <Select
                    options={specialities}
                    onChange={(item) =>
                      setDoctor({
                        ...doctor,
                        speciality: item,
                        speciality_id: item?.id,
                      })
                    }
                    value={doctor?.speciality}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    isClearable
                    isSearchable
                    placeholder="Select speciality"
                  />
                  <div className="text-danger">{errors?.speciality_id}</div>
                </CCol>
                <CCol md="6">
                  <CFormLabel>Gender</CFormLabel>
                  <Select
                    options={genders}
                    onChange={(item) =>
                      setDoctor({
                        ...doctor,
                        gender: item?.value,
                      })
                    }
                    value={genders.filter(
                      (gender) => gender.value === doctor?.gender
                    )}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.label}
                    isClearable
                    isSearchable
                    placeholder="Select gender"
                  />
                  <div className="text-danger">{errors?.gender}</div>
                </CCol>
                <CCol md="6">
                  <CFormLabel>Entity</CFormLabel>
                  <Select
                    options={entities}
                    onChange={(item) =>
                      setDoctor({
                        ...doctor,
                        entity: item?.value,
                      })
                    }
                    value={entities.filter(
                      (entity) => entity.value === doctor?.entity
                    )}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.label}
                    isClearable
                    isSearchable
                    placeholder="Select entity"
                  />
                  <div className="text-danger">{errors?.entity}</div>
                </CCol>
                <CCol md="6">
                  <CFormLabel>Patient Condition</CFormLabel>
                  <Select
                    options={conditions}
                    onChange={(item) => {
                      let tmpConditions = [...item];
                      let tmpValues = tmpConditions.map(
                        (condition) => condition.value
                      );
                      setDoctor({
                        ...doctor,
                        patient_condition: tmpValues,
                      });
                    }}
                    value={conditions.filter((condition) =>
                      doctor?.patient_condition?.includes(condition.value)
                    )}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.label}
                    isClearable
                    isSearchable
                    isMulti
                    placeholder="Select patient condition"
                  />
                  <div className="text-danger">{errors?.patient_condition}</div>
                  <div className="text-danger">
                    {errors?.no_patient_condition}
                  </div>
                </CCol>
              </>
            )}
          </CRow>
          {UserService.checkUserRole("Doctor") && (
            <CRow>
              <CCol md="4">
                <CFormLabel>Examination Fee</CFormLabel>
                <CInputGroup>
                  <CInputGroupText>EGP</CInputGroupText>
                  <CFormInput
                    type="number"
                    min={0}
                    name="examination_fee"
                    placeholder="Enter doctor's examination fee"
                    value={doctor?.examination_fee}
                    disabled
                  />
                </CInputGroup>
                <div className="text-danger">{errors?.examination_fee}</div>
              </CCol>
              <CCol md="4">
                <CFormLabel>Waiting Time</CFormLabel>
                <CFormInput
                  name="waiting_time"
                  placeholder="Enter doctor's waiting time"
                  value={doctor?.waiting_time}
                  onChange={(e) =>
                    setDoctor({ ...doctor, waiting_time: e.target.value })
                  }
                />
                <div className="text-danger">{errors?.waiting_time}</div>
              </CCol>
              <CCol md="4">
                <CFormLabel>Online Payment</CFormLabel>
                <div className="d-flex align-items-center">
                  <CFormSwitch
                    variant="3d"
                    color="primary"
                    checked={doctor?.online_payment}
                    onChange={(e) =>
                      setDoctor({
                        ...doctor,
                        online_payment: e.target.checked,
                      })
                    }
                  />
                </div>
              </CCol>
            </CRow>
          )}
          <CRow>
            <CCol md="6">
              <CFormLabel>About</CFormLabel>
              <CFormTextarea
                rows={10}
                name="about"
                placeholder="Enter provider's about"
                value={doctor?.about}
                onChange={(e) =>
                  setDoctor({ ...doctor, about: e.target.value })
                }
              />
              <div className="text-danger">{errors?.about}</div>
            </CCol>
            <CCol md="6">
              <CFormLabel>Note</CFormLabel>
              <CFormTextarea
                rows={10}
                name="note"
                placeholder="Enter provider's note"
                value={doctor?.note}
                onChange={(e) => setDoctor({ ...doctor, note: e.target.value })}
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
  );
}
