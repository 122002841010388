import { useContext } from "react";
import { langContext } from "../../context/LangProvider";
import Agreement from "./Agreement";
import GoogleFaceBook from "./GoogleFaceBook";
import LoginForm from "./LoginForm";
import OrLine from "./OrLine";

export default function Login() {
  const { t } = useContext(langContext);
  const formData = [
    {
      label: "E-Mail",
      placeholder: "E-Mail Address",
      type: "email",
      name: "email",
    },
    {
      label: "Password",
      placeholder: "Password",
      type: "password",
      name: "password",
    },
  ];
  return (
    <div className="auth-cont loginPage">
      <GoogleFaceBook />
      <div>
        <OrLine />
        <LoginForm formData={formData} />
      </div>
      <Agreement label={t("newUser")} link={t("signup")} />
    </div>
  );
}
