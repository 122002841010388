import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { clearParams } from "./search";

export const getSpecialities = createAsyncThunk(
  "specialities/getSpecialities",
  async () => {
    const res = await axios(`${process.env.REACT_APP_URL}/specialities`);
    return res.data.data;
  }
);
const specialitiesSlice = createSlice({
  name: "specialities",
  initialState: {
    specialities: [],
    servicesSelection: [],
    allServices: true,
    state: "idle",
  },
  reducers: {
    serviceHighlight: (state, action) => {
      const exist = state.servicesSelection.find(
        (ser) => +ser.id === +action.payload.service.id
      );
      if (exist) {
        let tmpFilteredServices = state.servicesSelection.filter(
          (ser) => +ser.id !== +action.payload.service.id
        );
        state.servicesSelection = [...tmpFilteredServices];

        if (state.servicesSelection.length === 0) {
          state.allServices = true;
        }
      } else {
        state.servicesSelection = [
          ...state.servicesSelection,
          action.payload.service,
        ];
        state.allServices = false;
      }
    },
    selectAllServices: (state, action) => {
      state.allServices = true;
      state.servicesSelection = [];
    },
  },
  extraReducers: {
    [getSpecialities.pending]: (state, action) => {
      state.state = "pending";
    },
    [getSpecialities.fulfilled]: (state, action) => {
      state.specialities = action.payload;
      state.state = "fulfilled";
    },
    [getSpecialities.rejected]: (state, action) => {
      state.state = "failed";
    },

    [clearParams]: (state) => {
      state.servicesSelection = [];
    },
  },
});
export default specialitiesSlice.reducer;
export const { serviceHighlight, selectAllServices } =
  specialitiesSlice.actions;
