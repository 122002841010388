import { useEffect, useState } from "react";

import Bookings from "../components/dashboard/Bookings";
import Header from "../components/dashboard/Header";
import SideBar from "../components/dashboard/SideBar";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function DashboardBookings() {
  const { visible, narrow } = useSelector((state) => state.dashboard),
    { token, doctor } = useSelector((state) => state.user),
    navigate = useNavigate(),
    [loading, setLoading] = useState(true),
    [bookings, setBookings] = useState([]);

  useEffect(() => {
    // if (!doctor) {
    //   navigate("/");
    //   return;
    // }
    getBookings();
  }, [doctor]);
  async function getBookings() {
    try {
      let res = await axios(
        process.env.REACT_APP_API_URL + "/doctor_dashboard/appointments",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setBookings(res.data.data);
    } catch (error) {
      toast.error("failed to get bookings");
    } finally {
      setLoading(false);
    }
  }
  return (
    <div className="" style={{ minHeight: "100vh" }}>
      <SideBar />
      <div
        className="dashboard-container"
        style={
          window.innerWidth > 768 && visible
            ? narrow
              ? { marginLeft: "65px" }
              : { marginLeft: "256px" }
            : { marginLeft: "0" }
        }
      >
        <Header />
        <div className="dashboard-wrapper">
          <Bookings bookings={bookings} loading={loading} />
        </div>
      </div>
    </div>
  );
}
