import { Accordion } from "react-bootstrap";

export default function Singlefaq({ faq }) {
  return (
    <Accordion className="accordion-box">
      <Accordion.Item eventKey="1">
        <Accordion.Header>{faq.question}</Accordion.Header>
        <Accordion.Body>{faq.answer}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
