import axios from "axios";
import { toast } from "react-toastify";

function handleResponse(res) {

}
let token = localStorage.getItem("dentistUserToken");
const auth = {
    "content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer " + token
}
const getDoctorsFAQs = async (id) => {
    token = localStorage.getItem("dentistUserToken");
    const requestOptions = { method: "GET", headers: auth };
    let json = await axios.get(`${process.env.REACT_APP_API_URL}/doctor_dashboard/faqs?id=${2}`, { headers: auth })
    return json.data
}
const updateFAQ = async (faq, id, restoreState) => {
    console.log('updaing');
    token = localStorage.getItem("dentistUserToken");
    const requestOptions = {
        method: "POST",
        headers: auth,
        body: faq,
    };
    let _url = `${process.env.REACT_APP_API_URL}/doctor_dashboard/faqs`;
    if (id) {
        _url += `/${id}?restore=${restoreState}`;
    }

    fetch(_url, requestOptions).then((json) => {
        toast.success("Updated successfully", {
            style: { color: 'success', accentColor: 'success' },
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
        console.log('success updating');
    }).catch((json) => {
        console.log('error updating');
        toast.error(json?.message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
    });
};
export async function insertFAQ(faq) {

    token = localStorage.getItem("dentistUserToken");
    await axios.post(
        `${process.env.REACT_APP_API_URL}/doctor_dashboard/faqs`,
        faq,
        {
            headers: {
                "content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
            },
        }
    ).then((e) => {
        toast.success(e?.message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            style: { color: 'success', accentColor: 'success' },
        });
        toast("FAQ inserted sucessfully", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        })
        console.log(e);
    }).catch((e) => {
        toast.error(e?.message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
        console.log(e);
    });
}
export async function deleteFAQ(faqId, forceState) {
    const requestOptions = {
        method: "DELETE",
        headers: auth,
    };
    fetch(
        `${process.env.REACT_APP_API_URL}/doctor_dashboard/faqs/${faqId}?force=${forceState}`,
        requestOptions
    ).then(() => {
        toast.success("Deleted successfully", {
            style: { color: 'success', accentColor: 'success' },
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
    }).catch((res) => {
        toast.error(res.message, {
            style: { color: 'success', accentColor: 'success' },
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
    });
}
export async function getAll(trashedState) {
    token = localStorage.getItem("dentistUserToken");
    const requestOptions = { method: "GET", headers: auth };
    return fetch(
        `${process.env.REACT_APP_API_URL}/doctor_dashboard/faqs?trashed=${trashedState}`,
        requestOptions
    ).then(handleResponse);
}
const getFAQ = async (id) => {
    token = localStorage.getItem("dentistUserToken");
    const requestOptions = { method: "GET", headers: auth };
    return fetch(`${process.env.REACT_APP_API_URL}/doctor_dashboard/faqs/${id}`, requestOptions).then(
        handleResponse
    );
};
export const faqsService = {
    getAll,
    getFAQ,
    insertFAQ,
    updateFAQ,
    deleteFAQ,
    getDoctorsFAQs
};
