export const searchDoctorsByProp = (allDoctors, selectedSearchType, prop) => {
  let filteredDoctorsByProp = [];
  allDoctors.forEach((doc) => {
    if (
      selectedSearchType.includes(
        doc[prop] ? doc[prop].toLowerCase() : doc[prop]
      )
    ) {
      filteredDoctorsByProp.push(doc);
    }
  });
  return filteredDoctorsByProp;
};
export const searchDoctorsBySpecId = (allDoctors, selectedSearchType, prop) => {
  let filteredDoctorsByProp = [];
  allDoctors.forEach((doc) => {
    if (selectedSearchType.includes(doc[prop])) {
      filteredDoctorsByProp.push(doc);
    }
  });
  return filteredDoctorsByProp;
};
export const searchDoctorsByAvailability = (allDoctors, selectedSearchType) => {
  console.log(selectedSearchType);
  let filteredDoctorsByProp = [];
  allDoctors.forEach((doc) => {
    doc.locations.forEach((loc) => {
      loc.availabilities.forEach((availability) => {
        selectedSearchType.forEach((searchType) => {
          if (
            new Date(availability.date).toDateString() ===
            new Date(searchType).toDateString()
          ) {
            filteredDoctorsByProp.push(doc);
          }
        });
      });
    });
  });

  return filteredDoctorsByProp;
};
