import { Col, Container, Form, Row } from "react-bootstrap";

import PatientBtn from "../components/patient-profile/profile components/PatientBtn";
import PatientInput from "../components/patient-profile/profile components/PatientInput";
import PatientNavGroup from "../components/patient-profile/profile components/PatientNavGroup";
import React, { useContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import useWindowWidth from "../Hooks/useWindowWidth";
import validate from "../Helpers/validate";
import { IoTelescope } from "react-icons/io5";
import { langContext } from "../context/LangProvider";

export default function ProfilePassword() {
  const [screenWidth] = useWindowWidth();
  const { token } = useSelector((state) => state.user);
  const [errors, seterrors] = useState(null);
  console.log(token);
  const { t } = useContext(langContext);
  const formData = [
    {
      label: t("Current Password"),
      placeholder: t("Current Password"),
      type: "password",
    },
    {
      label: t("New Password"),
      placeholder: t("New Password"),
      type: "password",
    },
    {
      label: t("confirmPassword"),
      placeholder: t("confirmPassword"),
      type: "password",
    },
  ];
  const updatePassword = async (e) => {
    console.log(formData);
    e.preventDefault();
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_URL}/update_password`,
        {
          current_password: formData[0].value || "",
          new_password: formData[1].value || "",
          new_password_confirmation: formData[2].value || "",
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log(res.data);
      if (res.data.message) toast.success("Password Updated Successfully");
    } catch (err) {
      console.log(err.response.data.errors);
      seterrors(err.response.data.errors);
      if (err.response.data.errors) {
        let apiErrors = { ...err.response.data.errors };
        validate(apiErrors["current_password"]);
        validate(apiErrors["new_password"]);
      }
      if (err.response.data.message === "Invalid password")
        toast.error("Incorrect Password");
    }
  };
  const handleChange = (label, inputField) => {
    formData.forEach((item) => {
      if (item.label === label) item.value = inputField;
    });
    console.log(formData);
  };
  return (
    <div className=" p-top-50">
      <Container>
        <div className="patient-profile-col">
          <Row className="justify-content-center">
            {screenWidth <= 767 ? null : <PatientNavGroup />}
            <Col
              md={8}
              lg={9}
              className={`${screenWidth >= 768 ? "ps-5" : ""} `}
              style={{ padding: "0 12px" }}
            >
              <h2 style={{ marginBottom: "2rem" }}>
                {t("Password & Security")}
              </h2>
              <Form onSubmit={updatePassword}>
                {formData.map((input, i) => (
                  <>
                    <PatientInput
                      key={i}
                      label={input.label}
                      type={input.type}
                      value={input.value}
                      placeholder={input.placeholder}
                      onChange={handleChange}
                    />
                    <div className="err-box">
                      <div style={{ width: "26%" }}></div>
                      <div className="err">
                        {errors &&
                          errors[
                            input.label.toLowerCase().replace(" ", "_")
                          ] && (
                            <div
                              className="text-danger mt-2"
                              style={{ lineHeight: "25px", width: "400px" }}
                            >
                              {
                                errors[
                                  input.label.toLowerCase().replace(" ", "_")
                                ]
                              }
                            </div>
                          )}
                      </div>
                    </div>
                  </>
                ))}
                <PatientBtn />
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
