import { Col } from "react-bootstrap";

export default function AppointmentButton({
  appointment,
  appointmentHandler,
  spacing,
}) {
  return (
    <Col xs={spacing} className="my-2">
      <button onClick={() => appointmentHandler(appointment)}>
        {appointment}
      </button>
    </Col>
  );
}
