import { useDispatch, useSelector } from "react-redux";

import { changeDashboardVisibilty } from "./dashboard";
import { getCities } from "./cities";
import { getDoctors } from "./doctors";
import { getFaqs } from "./faq";
import { getHomePage } from "./pages";
import { getLabs } from "./labs";
import { getLoginStatus } from "./auth";
import { getMyAppointments } from "./myappointments";
import { getOffers } from "./offers";
import { getReviews } from "./reviews";
import { getServices } from "./services";
import { getSpecialities } from "./specialities";
import { useEffect } from "react";

export default function ReduxStore() {
  // Getting all Data from Redux Store Coming from Api
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDoctors());
    dispatch(getLabs());
    dispatch(getCities());
    dispatch(getServices());
    dispatch(getFaqs());
    dispatch(getLoginStatus());
    dispatch(getOffers());
    dispatch(getMyAppointments());
    dispatch(getSpecialities());
    dispatch(getHomePage());
    if (window.innerWidth < 768) {
      dispatch(changeDashboardVisibilty(false));
    }
  }, [dispatch]);
  const { doctors, status } = useSelector((state) => state.doctors);
  useEffect(() => {
    if (status === "success") {
      dispatch(getReviews(doctors));
    }
  }, [dispatch, doctors, status]);
  return null;
}
