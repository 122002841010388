import { toast } from "react-toastify";

const handleResponse = (response) => {
  console.log(response);
  if (response?.data?.success) {
    toast.success(response?.data?.message);
  } else {
    toast.warning(response?.data?.message);
  }
  return response.text().then((text) => {
    let data = text;
    try {
      data = JSON.parse(text);
    } catch (error) {}

    return data;
  });
};

export default handleResponse;
