import { useContext } from "react";
import { Card } from "react-bootstrap";
import { langContext } from "../../context/LangProvider";
import LocationInfo from "./../SingleDoctor/doc-info/LocationInfo";

export default function LabInfo({ lab }) {
  // const rating =5*(
  //   data?.rating?.reduce((acc, curr) => acc + curr.count, 0) /
  //   data?.rating?.length*5);
  const rating = 0;
  console.log("lab", lab);
  const { t } = useContext(langContext);
  return (
    <div className="doc-info lab">
      <Card.Title className="bold pb-2">{lab.name}</Card.Title>
      <div className="doc-info-cont">
        <div>
          <div className="img-cont lab">
            <img src={lab?.logo?.original_url || "/assets/ahram.jfif"} alt="" />
          </div>
        </div>
        <div className="card-text ms-2">
          <div className="d-flex align-items-start">
            <img
              src="/assets/location.svg"
              alt=""
              className="icon-img-size me-2 pt-1"
            />
            <LocationInfo data={lab} />
          </div>
          <div className="d-flex align-items-start">
            <img
              src="/assets/phone.svg"
              alt=""
              className="icon-img-size me-2 pt-1"
            />
            <span className="me-1">{t("Phone Number:")}</span>
            <span className="bold"> {lab.phone}</span>
          </div>
          <div className="d-flex align-items-start">
            <img
              src="/assets/star.svg"
              alt=""
              className="icon-img-size me-2 pt-1"
            />
            <span className="bold me-1">{rating}</span>
            <span className="text-muted">
              ({lab?.reviews?.length || 0} {t("reviews")})
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
