import CIcon from "@coreui/icons-react";
import { CButton, CCol, CContainer, CRow } from "@coreui/react";
import { useState } from "react";
import {
  cilCloudUpload,
  cilCreditCard,
  cilDelete,
  cilTrash,
} from "@coreui/icons";

import { useEffect } from "react";
import Image, { propTypes } from "react-bootstrap/Image";
import { offersService } from "../../services/offersService";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
const DoctorOffers = ({ offers, onDelete }) => {
  console.log(offers);
  const [offersArray, setOffersArray] = useState(offers);
  useEffect(() => {
    if (offers) {
      setOffersArray(offers);
    }
    console.log(offersArray);
  }, [offers?.length]);
  async function deleteOffer(id) {
    try {
      let res = await offersService.deleteOffer(id, 1);
      console.log("res", res);
      if (res.success)
        toast("Deleted offer successsfully.", {
          style: { floodColor: "green" },
        });
    } catch (error) {
      toast("Something is wrong ,couldn't delete offer.");
    }

    onDelete();
  }
  return (
    <CContainer className="d-flex justify-content-start align-items-start flex-column w-100">
      {offersArray &&
        offersArray?.map(
          ({
            id,
            title,
            description,
            image_url,
            locations,
            services,
            start_time,
            end_time,
            discount,
          }) => {
            return (
              <CRow className="align-items-center">
                <CCol className="my-2 border-bottom py-2 w-100">
                  <Link
                    onClick={(e) => e.stopPropagation()}
                    to={`${id}`}
                    className="offerRow"
                    style={{
                      cursor: "pointer",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    <CRow
                      onClick={() => console.log("how do you do")}
                      className="p-1 pointer-event"
                    >
                      <CCol lg="1">
                        <Image
                          fluid
                          rounded
                          width={"100%"}
                          thumbnail
                          src={image_url}
                        />
                      </CCol>

                      <CCol
                        lg="2"
                        className="d-flex justify-content-between px-2"
                      >
                        <div className="m-2">
                          <div>
                            <b>{title}</b>
                          </div>
                          <div
                            dangerouslySetInnerHTML={{ __html: description }}
                          ></div>
                        </div>
                      </CCol>

                      <CCol lg="2">
                        <b>Services</b>
                        {services.map((service) => {
                          return <div>{service.name}</div>;
                        })}
                        {/* <p>{service}</p> */}
                      </CCol>
                      <CCol>
                        <b>Start date</b>
                        <p>{new Date(start_time).toDateString()}</p>
                      </CCol>

                      <CCol lg="2">
                        <b>End date</b>
                        <p>{new Date(end_time).toDateString()}</p>
                      </CCol>

                      <CCol lg="2">
                        <CRow>
                          <b>Discount</b>
                        </CRow>
                        {discount} EGP
                      </CCol>

                      <CCol lg="2">
                        <b>Locations</b>
                        <div>
                          {/* {JSON.stringify(locations)} */}
                          {locations.map((location) => (
                            <div className=" d-flex justify-content-start">
                              <p className="border-bottom">
                                {location?.street}
                              </p>
                              <p className="border-bottom">
                                {location?.street2}
                              </p>
                            </div>
                          ))}
                        </div>
                      </CCol>
                    </CRow>
                  </Link>
                </CCol>
                <CCol lg="1">
                  <div className="d-flex align-items-center">
                    <CButton
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteOffer(id);
                      }}
                      className="d-flex align-items-center"
                      color="danger"
                    >
                      <CIcon icon={cilTrash} />
                    </CButton>
                  </div>
                </CCol>
              </CRow>
            );
          }
        )}
    </CContainer>
  );
};
export default DoctorOffers;
