import { Container } from "react-bootstrap";
import FAQ from "../components/faq/FAQ";
import Searchbar from "../components/searchbar/Searchbar";
import Services from "../components/AllServices/services";
import { useSelector } from "react-redux";

export default function ServicesPage() {
  // const { faqs } = useSelector((state) => state.faqs);
  const topServicesTitle = "<h1>Book From Top <span>Services</span></h1>";

  return (
    <div className=" p-top-100">
      <Searchbar dlPage={"p-top-100"} activeTab="appointment" />
      <Container>
        <Container>
          <Services title={topServicesTitle} />
          {/* {faqs.length > 0 && (
            <FAQ fullWidth="w-100" faqData={faqs} title="FAQs" />
          )} */}
        </Container>
      </Container>
    </div>
  );
}
