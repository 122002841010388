import Booking from "../components/success-booking/SuccessBooking";
import { Container } from "react-bootstrap";

export default function SuccessBooking() {
  return (
    <div className="">
      <div className="back-booking p-top-100">
        <Container>
          <Booking />
        </Container>
      </div>
    </div>
  );
}
