import { Form, Spinner } from "react-bootstrap";
import React, { useContext, useState } from "react";

import { AiFillLock } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";
import { langContext } from "../../context/LangProvider";

export default function ForgotPassword() {
  const [reseting, setResting] = useState(false);
  const [resetingText, setResetingText] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailSuccess, setEmailSuccess] = useState(false);
  // const { lang } = useContext(langContext);
  const { t } = useContext(langContext);
  const resetHandler = async (e) => {
    e.preventDefault();
    setResting(true);
    setResetingText(<Spinner animation="border" size="sm" />);
    setEmailError("");
    if (emailSuccess) {
      setEmailError(
        "You have already reset your password, Please Check your E-mail"
      );
      setResetingText("Reset");
    } else {
      try {
        let res = await axios.post(
          `${process.env.REACT_APP_AUTH_URL}/password/reset-request`,
          {
            email,
          }
        );
        console.log(res);
        if (res?.data?.success) toast.success("Password Sent to your email");
        else toast.error("reset password Failed");
        setEmailSuccess(true);
      } catch (err) {
        console.log(err);
        if (err.response.data) toast.error("reset password Failed");
      } finally {
        setResting(false);
        setResetingText("");
      }
    }
  };
  return (
    <div className="auth-cont forgot">
      <div className="text-center">
        <AiFillLock size={150} color="#a99156" />
      </div>
      <div style={{ fontSize: "1.2rem" }}>{t("forgot")}</div>
      <div>
        <Form onSubmit={resetHandler}>
          <Form.Group
            className={`  "mt-2 mb-1 d-flex justify-content-between align-items-center"`}
            controlId="email"
          >
            <Form.Label>{t("E-Mail")}</Form.Label>
            <Form.Control
              required
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t("E-Mail")}
            />
          </Form.Group>
          <small className="text-danger">{emailError}</small>
          <div className="d-flex justify-content-center align-items-center mt-4 pb-4">
            <button
              disabled={reseting}
              className="pop-up login"
              style={reseting ? { opacity: 0.5 } : { opacity: 1 }}
            >
              {resetingText || t("resetingText")}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
}
