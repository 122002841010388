import {
    CButton,
    CButtonGroup,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CFormInput,
    CRow,
} from "@coreui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { faqsService } from "../../services/faqsService";
import { useEffect, useState } from "react";
import { FormLabel } from "react-bootstrap";
import CIcon from "@coreui/icons-react";
import { cilCloudUpload, cilTrash } from "@coreui/icons";
const initValues = {
    question: "",
    answer: "",
    order: 0,
};

const FAQEdit = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [faq, setFAQ] = useState(initValues);
    const [errors, setErrors] = useState([]);
    const location = useLocation();
    const id = location?.state?.id
    useEffect(() => {
        if (location?.state)
            setFAQ({ question: location.state.question, answer: location.state.answer, order: location.state.order, id: location.state.id })
    }, [location.state])
    useEffect(() => {
        const fetchFAQ = async () => {
            if (!id) {
                setLoading(false);
                return;
            }
            setLoading(true);
            try {
                setLoading(true);
                let json = await faqsService.getFAQ(id)
                console.log('daaaata : ', json);

                if (json?.success) {
                    setFAQ(json?.data);
                }
                else {
                    console.log(json);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false)
                console.log("error", error);
            }
        };
        const getPermissions = async () => {
            setLoading(true);
            // const { data: user } = await usersService.getCurrentUser();
            fetchFAQ();
        };
        getPermissions();
    }, [id]);

    const deleteFAQ = async () => {
        setLoading(true);
        let json = await faqsService.deleteFAQ(faq.id, 0).then(()=>{
            navigate("/dashboard/faqs")
        });
        setLoading(false);
        if (json.success) {
            // navigate()// go back
        }
    };

    const saveFAQ = async (published = false) => {
        let data = new FormData();
        Object.keys(faq).forEach((key) => {
            data.append(key, faq[key]);
            data.append("published", published);
        });
        setLoading(true);
        try {
            let json = location.state ? await faqsService.updateFAQ(data, faq.id, 0) : await faqsService.insertFAQ(data).then(()=>{
                navigate("/dashboard/faqs")
            }).catch(()=>{
                
            });
            setLoading(false);
           
            
        } catch (error) {
            setErrors(error.errors);
            toast.error(error, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
            setLoading(false);
        }
    };

    return (
        <>
            <CRow>
                <CCol xs="12">
                    <CCard>
                        <CCardHeader
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <FormLabel className="my-0">FAQ</FormLabel>
                            <CButtonGroup className="float-right">
                                {(loading && (
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                )) || (
                                        <>
                                            <CButton
                                                onClick={() => saveFAQ(true)}
                                                type="submit"
                                                size="lg"
                                                color="success"
                                            >
                                                <CIcon icon={cilCloudUpload} /> {id ? "Update" : "Save"}
                                            </CButton>
                                            {id && (
                                                <CButton
                                                    onClick={() => deleteFAQ()}
                                                    type="submit"
                                                    size="lg"
                                                    color="danger"
                                                >
                                                    <CIcon icon={cilTrash} /> Delete
                                                </CButton>
                                            )}
                                        </>
                                    )}
                            </CButtonGroup>
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <FormLabel>Question</FormLabel>
                                <CFormInput
                                    placeholder="Enter FAQ's Question"
                                    value={faq.question}
                                    onChange={(e) => setFAQ({ ...faq, question: e.target.value })}
                                />
                                <span className="error">{errors?.question}</span>
                            </CRow>
                            <CRow>
                                <FormLabel>Answer</FormLabel>
                                <CFormInput
                                    placeholder="Enter FAQ's Answer"
                                    value={faq.answer}
                                    onChange={(e) => setFAQ({ ...faq, answer: e.target.value })}
                                />
                                <span className="error">{errors?.answer}</span>
                            </CRow>
                            {/* <CRow>
                                <FormLabel>Order</FormLabel>
                                <CFormInput
                                    type="number"
                                    placeholder="Enter FAQ's Order"
                                    value={faq.order}
                                    onChange={(e) => setFAQ({ ...faq, order: e.target.value })}
                                />
                                <span className="error">{errors?.order}</span>
                            </CRow> */}
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </>
    );
}
export default FAQEdit;