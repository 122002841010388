import { Container } from "react-bootstrap";
import ResetPasswordForm from "../components/auth/ResetPassword";

export default function ResetPassword() {
  return (
    <div className="">
      <div className="back-auth p-top-100 ">
        <Container>
          <ResetPasswordForm />
        </Container>
      </div>
    </div>
  );
}
