import { Container } from "react-bootstrap";
import Doctors from "./doctors/doctors";
import SideFilter from "./side-filter/SideFilter";
import useSearchHelper from "../../Hooks/useSearchHelper";
import { useSelector } from "react-redux";

export default function DoctorsListComp({ numPerPage, servicesFilter }) {
  useSearchHelper(servicesFilter);
  const { filteredDoctors, secondPhaseFilter, filterParams } = useSelector(
    (state) => state.doctors
  );
  return (
    <Container className="p-top-50 doc-list-cont">
      <SideFilter />
      <Doctors
        doctors={
          filterParams.specialities.length ||
          filterParams.gender.length ||
          filterParams.title.length ||
          filterParams.entity.length ||
          filterParams.availabilities.length ||
          filterParams.patientCondition.length
            ? secondPhaseFilter
            : filteredDoctors
        }
        numPerPage={numPerPage}
      />
    </Container>
  );
}
