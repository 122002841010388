import { Accordion } from "react-bootstrap";
import Singlefaq from "./single-faq";

export default function FAQ({ fullWidth, faqData, title }) {
  return (
    <div className={`${fullWidth} doc-faq`}>
      <h1 className="py-md-3">{title}</h1>
      <div>
        <Accordion defaultActiveKey="0" className="accordion-box">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {faqData.slice(0, 1)[0].question}
            </Accordion.Header>
            <Accordion.Body>{faqData.slice(0, 1)[0].answer}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {faqData.slice(1).map((faq, i) => (
          <Singlefaq faq={faq} key={i} />
        ))}
      </div>
    </div>
  );
}
