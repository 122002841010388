import { useEffect, useState } from "react";

import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function SearchDoctor() {
  const handleSearchDoctor = (sel) => {
    navigate(`/doctorProfile/${sel.value}`);
  };
  const [options, setOptions] = useState([]);
  const { doctors } = useSelector((state) => state.doctors);
  useEffect(() => {
    if (doctors) {
      let docs = doctors.map((d) => {
        let temp = { value: d.id, label: d.name };
        return temp;
      });
      setOptions(docs);
    }
  }, [doctors]);
  const navigate = useNavigate();

  return (
    <Select
      className="basic-single"
      onChange={handleSearchDoctor}
      placeholder="Select Doctor"
      isSearchable={true}
      name="color"
      options={options}
    />
  );
}
