import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CButton, CCardBody, CCol, CRow } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import Header from "../components/dashboard/Header";
import LocationEdit from "../components/dashboard/LocationEdit";
import SideBar from "../components/dashboard/SideBar";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { cilCloudUpload, cilTrash } from "@coreui/icons";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function DashboardLocationsEdit() {

  const { id } = useParams(),
    { visible, narrow } = useSelector((state) => state.dashboard),
    [errors, setErrors] = useState([]),
    [loading, setLoading] = useState(true),
    [updating, setUpdating] = useState(false),
    navigate = useNavigate(),
    [location, setLocation] = useState({
      street: "",
      street_2: "",
      latitude: "",
      longitude: "",
      note: "",
      availabilities: [],
      area: { city: { name: "", id: "" }, name: "", id: "" },
    }),
    deleteLocation = async () => {
      axios.delete(`${process.env.REACT_APP_API_URL}/doctor_dashboard/locations/${location.id}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      }).then(function (res) {
        console.log(res);
        toast.success(res.data.message)
        navigate(-1)
      }).catch((function (err) {
        console.log(err);

        toast.error(err.data.message)
      }))
    },
    updateLocation = async () => {
      setErrors({
        ...errors,
        availability_range: "",
        latLng: "",
      });
      if (location.longitude === "" || location.latitude === "") {
        setErrors({
          ...errors,
          latLng:
            "You have to add longitude and latitude (can't update with one coordinate)",
        });
        toast.error("The given data was invalid");
        throw new Error("The given data was invalid");
      }
      location?.availabilities &&
        location.availabilities.forEach((availability) => {
          if (
            !availability.closed &&
            !availability.close_hour &&
            !availability.open_hour
          ) {
            setErrors({
              ...errors,
              availability_range:
                "You have to add open and close hours if not closed",
            });
            toast.error("The given data was invalid");
            throw new Error("The given data was invalid");
          }
        });
      setUpdating(true);
      try {
        await axios.post(
          process.env.REACT_APP_API_URL +
          `/doctor_dashboard/locations${id ? `/${id}` : ""}`,
          location,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        toast.success(
          id ? "Location Updated Successfully" : "Location Added Successfully"
        );
      } catch (error) {
        setErrors(error.response.data.errors);
        toast.error("The given data was invalid");
      } finally {
        setUpdating(false);
      }
    },
    {
      token,
      doctor,
      doctorStatus,
      status: userStatus,
    } = useSelector((state) => state.user);
  useEffect(() => {
    if (userStatus === "success" && doctorStatus === "success") {
      if (!doctor) {
        navigate("/");
        return;
      }
      getLocations();
    }
  }, [userStatus, doctorStatus, doctor]);
  async function getLocations() {
    if (!id) {
      setLoading(false);
      return;
    }
    try {
      let res = await axios(
        process.env.REACT_APP_API_URL + "/doctor_dashboard/locations",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setLocation(res.data.data.find((location) => +location.id === +id));
    } catch (error) {
      setErrors(error.response.data.errors);
      toast.error("failed to get locations");
      navigate("/");
    } finally {
      setLoading(false);
    }
  }
  return (
    <div className="" style={{ minHeight: "100vh" }}>
      <SideBar />
      <div
        className="dashboard-container"
        style={
          window.innerWidth > 768 && visible
            ? narrow
              ? { marginLeft: "65px" }
              : { marginLeft: "256px" }
            : { marginLeft: "0" }
        }
      >
        <Header />

        {!loading ? (
          <div className="dashboard-wrapper">
            <CCardBody>
              <CRow className="m-1 d-flex justify-content-start align-self-end">
                <CCol >
                  <CButton
                    onClick={updateLocation}
                    type="submit"
                    size="lg"
                    color="success"
                    disabled={updating}
                    style={{ width: "fit-content", alignSelf: "center", color: "white" }}
                  >
                    {id ? "Update" : "Save"}
                    <CIcon icon={cilCloudUpload} />
                  </CButton>
                </CCol>
                <CCol >
                  {id && <CButton
                    onClick={deleteLocation}
                    type="submit"
                    size="lg"
                    color="danger"

                    disabled={updating}
                    style={{ width: "fit-content", alignSelf: "center", color: "white" }}
                  >
                    Delete
                    <CIcon icon={cilTrash} />
                  </CButton>}
                </CCol>
              </CRow>

              <LocationEdit
                location={location}
                setLocation={setLocation}
                errors={errors}
              />
            </CCardBody>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" />
          </div>
        )}


      </div>
    </div>
  );
}
