import LightGallery from "lightgallery/react";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import useWindowWidth from "../../Hooks/useWindowWidth";

export default function ClinicPhotos({ title, gallery }) {
  const [screenWidth] = useWindowWidth();
  return (
    <div className="doc-clinicPhotos">
      <h5 className="bold py-3">{title}</h5>
      <div className="images-cont">
        <LightGallery speed={500} plugins={[lgThumbnail]} addClass="gallery">
          {screenWidth > 768 &&
            gallery.slice(0, 4).map((image, i) => (
              <a href={image.original_url} className="mobile-hide" key={i}>
                <img alt="" src={image.original_url} className="gallery-img" />
              </a>
            ))}
          {screenWidth <= 768 &&
            gallery.slice(0, 3).map((image, i) => (
              <a href={image.original_url} className="mobile-show" key={i}>
                <img alt="" src={image.original_url} />
              </a>
            ))}
          {gallery.length > 4 && (
            <a
              href={
                screenWidth >= 768
                  ? gallery[4].original_url
                  : gallery[3].original_url
              }
              className="more-image-link"
            >
              {screenWidth >= 768 && gallery.length - 5 > 0 && (
                <div className="black-overlay">
                  <span className="bold">
                    {gallery.length - 5}
                    More
                  </span>
                </div>
              )}
              {screenWidth < 480 && gallery.length - 4 > 0 && (
                <div className="black-overlay">
                  <span className="bold">
                    {gallery.length - 4}
                    More
                  </span>
                </div>
              )}

              <img
                alt=""
                src={
                  screenWidth >= 480
                    ? gallery[4].original_url
                    : gallery[3].original_url
                }
              />
            </a>
          )}
          {gallery.slice(5).map((image, i) => (
            <a href={image.original_url} className="more-images" key={i}>
              <img alt="" src={image.original_url} />
            </a>
          ))}
        </LightGallery>
      </div>
    </div>
  );
}
