import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import LocationInfo from "../SingleDoctor/doc-info/LocationInfo";
import { useContext, useState } from "react";
import { t } from "i18next";
import { langContext } from "../../context/LangProvider";

export default function SliderCard({
  data,
  imgAlternate,
  linkText,
  dataType,
  numOfCards,
}) {
  const [image, setImage] = useState("assets/arrow-circle-right-sml.svg");
  const rating =
    5 *
    (data?.reviews?.reduce((acc, curr) => acc + curr.rate, 0) /
      (data?.reviews?.length * 5));
  const { t } = useContext(langContext);
  return (
    <Card className={`scan-card ${numOfCards === 1 && "oneCard"}`}>
      <div className="img-cont">
        {dataType === "lab" ? (
          <img
            className="top-img"
            src={data.logo?.original_url || imgAlternate}
            alt=""
          />
        ) : (
          <img
            className="top-img"
            src={data.profile_picture?.original_url || imgAlternate}
            alt={data.name}
          />
        )}
      </div>

      <Card.Body
        className={`scan-card-body ${dataType === "doctor" && "specialist"}`}
      >
        <Card.Title className="mb-4 bold">{data.name}</Card.Title>
        <div className={` ${dataType === "doctor" && "specialist"}`}>
          <div>
            {dataType === "doctor" && (
              <div className="my-1">
                {t("Specialists for")}{" "}
                <span className="bold">{data?.speciality?.name}</span>
              </div>
            )}
            <div className="my-1">
              <img
                src="assets/location.svg"
                alt=""
                className="icon-img-size pb-1"
              />
              <span className="ms-2 ">
                <LocationInfo data={data} />
              </span>
            </div>
            <div className="my-1">
              <img
                src="assets/star.svg"
                alt=""
                className="icon-img-size pb-1"
              />
              <span className="ms-2 bold">{rating || 0}</span>
              <span className="text-muted ms-1">
                ({data?.reviews?.length || 0} {t("reviews")})
              </span>
            </div>
          </div>
          <div className="mt-4">
            <Link
              to={
                dataType === "lab"
                  ? `/singlelab/${data.id}`
                  : `/doctorProfile/${data.id}`
              }
            >
              <button
                className="pop-up2"
                onMouseLeave={() =>
                  setImage("assets/arrow-circle-right-sml.svg")
                }
                onMouseOver={() =>
                  setImage("assets/arrow-circle-right-sml2.svg")
                }
              >
                <span>{t(linkText)}</span>{" "}
                <img src={image} alt="" className="icon-img-size" />
              </button>
            </Link>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
