import Spinner, { SpinnerError } from "../Spinner";
import { useContext, useEffect, useState } from "react";

import AppointmentCard from "./profile components/AppointmentCard";
import { Col } from "react-bootstrap";
import SwitchButton from "./profile components/SwitchButton";
import { useSelector } from "react-redux";
import useWindowWidth from "../../Hooks/useWindowWidth";
import { getMyAppointments } from "../../redux/myappointments";
import { useDispatch } from "react-redux";
import { langContext } from "../../context/LangProvider";

export default function ProfileAppointMents() {
  const [screenWidth] = useWindowWidth();
  const [appointments, setAppointments] = useState([]);
  const [viewToggler, setViewToggler] = useState("Consultation");
  const { myAppointments, status } = useSelector(
    (state) => state.myAppointments
  );
  const { t } = useContext(langContext);
  console.log(myAppointments);
  const dispatch = useDispatch();
  const [spinner, setSpinner] = useState(<Spinner height="100" />);
  useEffect(() => {
    dispatch(getMyAppointments());
  }, []);
  useEffect(() => {
    if (status === "success") {
      setAppointments(myAppointments);
    } else if (status === "failed") {
      setSpinner(<SpinnerError />);
    }
  }, [myAppointments, status]);

  return (
    <Col
      md={8}
      lg={9}
      className={`${screenWidth >= 768 ? "ps-5" : ""} `}
      style={{ padding: "0 12px" }}
    >
      <div className="d-flex justify-content-between mb-3">
        <h2 className="appointment-title">
          {viewToggler === "Consultation"
            ? t("My Appointments")
            : t("My Consultation Sessions")}
        </h2>
        <SwitchButton
          viewToggler={viewToggler}
          setViewToggler={setViewToggler}
        />
      </div>
      {status !== "loading" ? (
        <>
          {appointments?.map((appointment) => (
            <AppointmentCard
              appointment={appointment}
              key={appointment.id}
              viewToggler={viewToggler}
            />
          ))}
          <>
            {viewToggler === "Appointments" &&
              appointments.filter((appointment) =>
                appointment.provider_type.includes("User")
              ).length === 0 &&
              t("You Have No Consultation Sessions")}
            {viewToggler === "Consultation" &&
              appointments.filter(
                (appointment) => !appointment.provider_type.includes("User")
              ).length === 0 &&
              t("You Have No Appointments")}
          </>
        </>
      ) : (
        <>{spinner}</>
      )}
    </Col>
  );
}
