import MainSlider from "../components/home/MainSlider";
import Subscribe from "../components/home/Subscribe";
import TopDoctors from "../components/home/TopDoctors";
import TopOffers from "../components/home/TopOffers";
import TopReviews from "../components/home/TopReviews/TopReviews";
import TopScanLabs from "../components/home/TopScanLabs";
import TopServices from "../components/home/TopServices";
import { useSelector } from "react-redux";

export default function Home() {
  const { services } = useSelector((state) => state.services);
  const { doctors } = useSelector((state) => state.doctors);
  const { labs } = useSelector((state) => state.labs);
  console.log("labs", labs);
  const getIdsOfLastFive = (arr) => {
    return arr.slice(-5).map((ele) => ele.id);
  };
  const selectedServices = getIdsOfLastFive(services);
  const selectedSpecialists = getIdsOfLastFive(doctors);
  const selectedLabs = getIdsOfLastFive(labs);
  console.log("selectedLabs", selectedLabs);
  console.log("home>>>>");
  const homeSlides = [
    {
      text: "Take Care of your<span class='bold'><span class='mainColor'> Tooth </span>Health Here</span></><p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.Assumenda dolores aperiam asperiores, ullam repellendusAssumenda dolores aperiam asperiores, ullam repellendus</p>",
      image: "/assets/pattern.svg",
    },
    {
      text: "Take Care of your<span class='bold'><span class='mainColor'> Tooth </span>Health Here</span></><p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.Assumenda dolores aperiam asperiores, ullam repellendusAssumenda dolores aperiam asperiores, ullam repellendus</p>",
      image: "/assets/pattern.svg",
    },
  ];
  const topOffersSlides = [
    {
      text: "Sale Up to <span>50%</span> on<span>Teeth Whitening</span> and more...",
      image: "/assets/offers-page.png",
      btnText: "Explore  Offers",
      visibility: true,
    },
    {
      text: "Sale Up to <span>50%</span> on<span>Teeth Whitening</span> and more",
      image: "/assets/offers-page.png",
      btnText: "Explore Offers",
      visibility: true,
    },
  ];
  const topSpecialistsTitle = "Our <span> Dental Care </span> Specialists";
  const subscribeTxt =
    "Subscribe for any <span class='mainColor bold'>news</span> update from <span class='mainColor bold'>Dentist</span>";
  return (
    <div>
      <MainSlider
        slides={homeSlides}
        bgColor="#fff"
        searchBar_visibility={true}
      />
      <div style={{ backgroundColor: "transparent" }}>
        <TopServices
          selectedServicesIds={selectedServices}
          title="Book From Top <span>Services</span>"
          btnText="View More Services"
          btnUrl="services"
        />
      </div>
      <TopDoctors
        selectedSpecialistsIds={selectedSpecialists}
        title={topSpecialistsTitle}
        btnText="SEE ALL"
        btnUrl="doctors"
        bgColor="#fafafa"
      />
      <TopOffers
        btnUrl="offers"
        // btnText="See all"
        slides={topOffersSlides}
      />
      <TopScanLabs
        selectedLabsIds={selectedLabs}
        title="Recommended <span> Labs </span>"
        btnText="See All"
        btnUrl="labs"
        bgColor="#fafafa"
      />
      <TopReviews
        bgColor="white"
        title="This Is Why <span> We Do </span> What <span> We Do </span>"
        items={4}
      />
      <Subscribe text={subscribeTxt} />
    </div>
  );
}
