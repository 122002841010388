import CartService from "../services/cartService";
import { createSlice, current } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cart: [],
    myCart: [],
    tempCart: [],
    fees: 0,
    feesWithNoDiscount: 0,
    dataExistId: null,
    dataExistType: null,
    successfullBook: null,
    currentAppointment: null,
  },
  reducers: {
    addToCart: (state, action) => {
      if (!state.dataExistId) {
        state.dataExistId = action.payload.dataId;
        state.dataExistType = action.payload.dataType;
      }
      const InCartProduct = CartService.isInCart(
        state.cart,
        action.payload.newService
      );
      if (InCartProduct) {
        // editing existing services
        let cartAfterRemovingExistingService = CartService.removeFromCart(
          state.cart,
          action.payload.newService
        );
        state.cart = [
          ...cartAfterRemovingExistingService,
          action.payload.newService,
        ];
        if (
          CartService.IsDeccreasingCartCount(
            InCartProduct,
            action.payload.newService
          )
        ) {
          state.cart = state.cart.filter((cartItem) => cartItem.count !== 0); //to fully remove the service from cart when count is 0
        }
      } else {
        // adding new services
        state.cart = [...action.payload.cart, action.payload.newService];
      }

      if (state.cart.length === 0) {
        state.fees = 0;
        state.feesWithNoDiscount = 0;
        return;
      }
      state.fees = CartService.getFees(state.cart, action.payload);

      console.log(current(state));
    },
    successfullBooking: (state, action) => {
      state.successfullBook = {
        data: action.payload.data,
        dataName: action.payload.dataName,
        workingHours: action.payload.workingHours,
        fees: action.payload.fees,
        feesWithNoDiscount: action.payload.feesWithNoDiscount,
        date: action.payload.date,
        locations: action.payload.locations,
        waiting_time: action.payload.waiting_time,
      };
    },
    setCurrentAppointment: (state, action) => {
      state.currentAppointment = action.payload;
      let newTempCart = [];
      action.payload.services.map((service) => {
        newTempCart.push({
          service: service,
          count: +service.pivot.quantity,
        });
      });
      state.tempCart = newTempCart;
    },
    resetCurrentAppointment: (state) => {
      state.currentAppointment = null;
      state.tempCart = [];
    },
    bookAgain: (state, action) => {
      state.fees = state.currentAppointment.fees;
      state.feesWithNoDiscount = state.currentAppointment.feesWithNoDiscount;
      state.cart = state.tempCart;
    },
    clearCart: (state) => {
      state.cart = [];
      state.fees = 0;
      state.dataExistId = null;
      state.dataExistType = null;
    },
  },
});
export default cartSlice.reducer;
export const {
  addToCart,
  successfullBooking,
  clearCart,
  setCurrentAppointment,
  resetCurrentAppointment,
  bookAgain,
} = cartSlice.actions;
