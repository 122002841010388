import React, { useEffect, useState } from "react";

import Header from "../components/dashboard/Header";
import Services from "../components/dashboard/Services";
import Sidebar from "../components/dashboard/SideBar";
import { appendItem } from "doitfast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { doctorDashBoardService } from "../services/doctorDashboardService";

export default function DashboardLocations() {
  const initValues = {
    services: [],
  };
  const navigate = useNavigate();

  const { visible, narrow } = useSelector((state) => state.dashboard);
  const {
    status: userStatus,
  } = useSelector((state) => state.user);
  const { status: doctorsStatus } = useSelector(
    (state) => state.doctors
  );
  const [doctor, setDoctor] = useState(initValues);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loggedInDoctor, setLoggedInDoctor] = useState(null);
  useEffect(() => {
    setLoading(true)
    async function getLoggedInDoctor() {
      setLoading(true)
      let res = await doctorDashBoardService.getDoctor().then(async (r) => {
        const data = await r.json()
        setDoctor(data.data)
        setLoading(false)
      })
      console.log(res);
    }
    getLoggedInDoctor()

  }, [])

  useEffect(() => {
    if (userStatus === "success" && doctorsStatus === "success") {
      // setDoctor(doctors.find((doctor) => +doctor.user_id === +user.id));
    }
    // setLoading(false)
  }, [userStatus, doctorsStatus]);
  useEffect(() => {
    // setLoading(false)
    // if (!doctor) navigate("/");
  }, [doctor]);
  const updateDoctor = async () => {
    setLoading(true);
    let data = new FormData();
    Object.keys(doctor).forEach((key) => {
      appendItem(data, key, doctor[key]);
    });
    console.log(doctor);
  };
  const dispatch = useDispatch()
  return (
    <div className="" style={{ minHeight: "100vh" }}>
      <Sidebar />
      <div
        className="dashboard-container"
        style={
          window.innerWidth > 768 && visible
            ? narrow
              ? { marginLeft: "65px" }
              : { marginLeft: "256px" }
            : { marginLeft: "0" }
        }
      >
        <Header />
        <div className="dashboard-wrapper">
          <Services loading={loading} doctor={doctor} />
        </div>
      </div>
    </div>
  );
}
