import {
  Col,
  Container,
  Dropdown,
  Image,
  Nav,
  NavDropdown,
  Navbar,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";

import BookingCard from "./BookingCard/BookingCard";
import ConsultationButton from "./ConsultationButton";
import PatientNavGroup from "./patient-profile/profile components/PatientNavGroup";
import { RiCustomerService2Line } from "react-icons/ri";
import UserService from "../services/userService";
import axios from "axios";
import { getPages } from "../redux/pages";
import { logout } from "../redux/auth";
import useWindowWidth from "../Hooks/useWindowWidth";
import { getMyAppointments } from "../redux/myappointments";
import { langContext } from "../context/LangProvider";

export default function NavBar() {
  const { user, loginStatus, token } = useSelector((state) => state.user);
  const { cart, dataExistType, dataExistId } = useSelector(
    (state) => state.cart
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { doctors } = useSelector((state) => state.doctors);
  const { labs } = useSelector((state) => state.labs);
  const [showCart, setShowCart] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showConsultationButton, setShowConsultationButton] = useState(false);
  const [dataTypeInCart, setDataTypeInCart] = useState(null);
  const [dataSentToCart, setDataSentToCart] = useState(null);
  const { setlang, lang } = useContext(langContext);
  useEffect(() => {
    setDataTypeInCart(dataExistType);
  }, [dataExistType]);

  useEffect(() => {
    if (dataExistType === "doctor") {
      const dataToCart = doctors.find((doctor) => doctor.id == dataExistId);
      setDataSentToCart(dataToCart);
    }
    if (dataExistType === "lab") {
      const dataToCart = labs.find((lab) => lab.id == dataExistId);
      setDataSentToCart(dataToCart);
    }
  }, [dataExistId, dataExistType, doctors, labs]);

  const handleCloseCart = () => setShowCart(false);
  const handleShowCart = () => setShowCart(true);
  const handleCloseConsultationButton = () => setShowConsultationButton(false);
  const handleShowConsultationButton = () => setShowConsultationButton(true);
  const handleCloseProfile = () => setShowProfile(false);
  const handleShowProfile = () => setShowProfile(true);
  const userIsDoctor = UserService.checkUserRole("Doctor");
  const { t } = useContext(langContext);
  const [screenWidth] = useWindowWidth();
  const logoutHandler = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_AUTH_URL}/logout`,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      dispatch(logout());
      navigate("/login");
    } catch (err) {
      console.log(err);
    }
  };
  const overLay = (s) => {
    console.log(s);
  };
  const navigateToHome = async () => {
    await dispatch(getPages("/home"));
    navigate("/");
  };
  return window.location.pathname.includes("dashboard") ? null : (
    <Navbar fixed="top" collapseOnSelect expand="md" className="top-navbar">
      <Container className="container">
        <Navbar.Brand className="logo" onClick={navigateToHome}>
          <Image src="/assets/logo.svg" />
        </Navbar.Brand>

        <Nav>
          <Row className="align-items-center">
            {loginStatus ? (
              <Col className="ms-auto d-none d-md-flex">
                <NavDropdown
                  title={user.name}
                  className="collasible-nav-dropdown menu"
                >
                  <Link
                    to="/profile/update"
                    className="ms-2 my-1  p-1 d-block hoverGold"
                  >
                    {t("Edit Profile")}
                  </Link>
                  <Link
                    to="/profile/password"
                    className="ms-2 my-1  p-1 d-block hoverGold"
                  >
                    {t("Password")} &amp; {t("Security")}
                  </Link>
                  {userIsDoctor ? (
                    <Link
                      to="dashboard/profile"
                      className="ms-2 my-1  p-1 d-block hoverGold"
                    >
                      {t("My Dashboard")}
                    </Link>
                  ) : (
                    <Link
                      onClick={() => {
                        dispatch(getMyAppointments());
                      }}
                      to="/profile/appointments"
                      className="ms-2 my-1 d-block p-1 hoverGold"
                    >
                      {t("My Appointments")}
                    </Link>
                  )}

                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    onClick={() => logoutHandler()}
                    className="dropdown-item hoverGold"
                  >
                    {t("Log Out")}
                  </NavDropdown.Item>
                </NavDropdown>
              </Col>
            ) : (
              <>
                <Col xs="auto" className="d-none d-md-block">
                  <Link to="/sign-up">
                    <button className="sign-up pop-up mx-1">
                      {t("signupanv")}
                    </button>
                  </Link>
                </Col>
                <Col xs="auto" className="d-none d-md-block">
                  <Link to="/login">
                    <button className="log-in mx-1">{t("loginnav")}</button>
                  </Link>
                </Col>
              </>
            )}

            <Col>
              {screenWidth >= 1024 ? (
                <Dropdown className="cart mx-2">
                  <Dropdown.Toggle
                    style={{ backgroundColor: "transparent" }}
                    id="dropdown-basic"
                  >
                    <Image src="/assets/cart.svg" style={{ width: "20px" }} />
                  </Dropdown.Toggle>
                  {cart.length > 0 ? (
                    <Dropdown.Menu className="booking">
                      <BookingCard
                        overLay={overLay}
                        data={dataSentToCart}
                        dataName={dataTypeInCart}
                      />
                    </Dropdown.Menu>
                  ) : (
                    <Dropdown.Menu>
                      <div className="ms-1">{t("Your Cart is Empty")}</div>
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              ) : (
                <Nav.Link>
                  <Image
                    src="/assets/cart.svg"
                    style={{ width: "20px" }}
                    onClick={handleShowCart}
                  />
                  <Offcanvas
                    show={showCart}
                    onHide={handleCloseCart}
                    placement="top"
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>{t("Your Cart")}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      {cart.length > 0 ? (
                        <BookingCard
                          overLay={overLay}
                          data={dataSentToCart}
                          dataName={dataTypeInCart}
                        />
                      ) : (
                        <div className="text-center">
                          {t("Your Cart is Empty")}
                        </div>
                      )}
                    </Offcanvas.Body>
                  </Offcanvas>
                </Nav.Link>
              )}
            </Col>
            <Col>
              <NavDropdown
                title={lang}
                className="collasible-nav-dropdown menu"
              >
                <span
                  onClick={(e) => setlang(e.target.innerText)}
                  className="ms-2 my-1  p-1 d-block hoverGold"
                >
                  en
                </span>

                <span
                  onClick={(e) => setlang(e.target.innerText)}
                  className="ms-2 my-1  p-1 d-block hoverGold"
                >
                  ar
                </span>
              </NavDropdown>
            </Col>

            <Col>
              {screenWidth >= 1024 ? (
                <Dropdown className="cart mx-2">
                  <Dropdown.Toggle
                    style={{ backgroundColor: "transparent" }}
                    id="dropdown-basic"
                  >
                    <Nav.Link>
                      <div className="blob">
                        <RiCustomerService2Line size={20} color="#fff8f8" />
                      </div>
                    </Nav.Link>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="bookingAppointment">
                    <div className="ms-1">
                      <ConsultationButton />
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <>
                  <button style={{ backgroundColor: "transparent" }}>
                    <div
                      className="blob"
                      onClick={handleShowConsultationButton}
                    >
                      <RiCustomerService2Line size={20} color="#fff8f8" />
                    </div>
                  </button>
                  <Offcanvas
                    show={showConsultationButton}
                    onHide={handleCloseConsultationButton}
                    placement="top"
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>{t("Book Appointment")}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <ConsultationButton />
                    </Offcanvas.Body>
                  </Offcanvas>
                </>
              )}
            </Col>
            <Col>
              <Navbar.Toggle onClick={handleShowProfile} />
            </Col>
          </Row>

          <Offcanvas
            show={showProfile}
            onHide={handleCloseProfile}
            aria-labelledby="offcanvasNavbarLabel"
            placement="top"
            style={{ textAlign: "center", backgroundColor: "#fef8e9" }}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">
                {loginStatus && <span>{user.name}</span>}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              {(loginStatus && (
                <PatientNavGroup onHide={handleCloseProfile} />
              )) || (
                <div className="d-flex flex-column my-1">
                  <Link
                    style={{ textTransform: "uppercase" }}
                    to="/sign-up"
                    className="pop-up text-white p-2 my-2"
                    onClick={handleCloseProfile}
                  >
                    {t("loginnav")}
                  </Link>
                  <Link
                    style={{ textTransform: "uppercase" }}
                    to="/login"
                    className="my-2"
                    onClick={handleCloseProfile}
                  >
                    {t("signupanv")}
                  </Link>
                </div>
              )}
            </Nav>
          </Offcanvas>
        </Nav>
      </Container>
    </Navbar>
  );
}
