import {
  Accordion,
  Dropdown,
  DropdownButton,
  Offcanvas,
} from "react-bootstrap";

import FilterBody from "./FilterBody";
import useCleanUseEffect from "../../../Hooks/useCleanUseEffect";
import { useContext, useState } from "react";
import useWindowWidth from "../../../Hooks/useWindowWidth";
import { useDispatch } from "react-redux";
import { sortDoctors } from "../../../redux/doctors";
import { langContext } from "../../../context/LangProvider";

export default function SideFilter() {
  const dispatch = useDispatch();
  const [stickyStyles, setStickyStyles] = useState({});
  const [screenWidth] = useWindowWidth();
  useCleanUseEffect((status) => {
    window.addEventListener("scroll", () => {
      if (!status.aborted) {
        if (window.scrollY > 400) {
          setStickyStyles({
            backgroundColor: "#fef8e9",
            top: "85px",
            position: "fixed",
            width: "100%",
            padding: "10px",
          });
        } else {
          setStickyStyles({ position: "relative", top: 0 });
        }
      }
    });
  }, []);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  function sortDoctorsHandler(sortState) {
    dispatch(sortDoctors(sortState));
  }
  const { t } = useContext(langContext);
  return (
    <div className="side-filter">
      {screenWidth >= 1024 ? (
        <div className="side-filter-flex">
          <Accordion defaultActiveKey="1" className="filter-top">
            <Accordion.Item eventKey="1">
              <div className="main-head">
                <Accordion.Header>
                  <i className="fas fa-sliders-h mx-2"></i> {t("Filter By")}
                </Accordion.Header>
              </div>
              <Accordion.Body className="main-head-cont">
                <FilterBody />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      ) : (
        <>
          <div className="side-filter-flex" style={stickyStyles}>
            <button className="mobileScreen" onClick={handleShow}>
              <i className="fas fa-sliders-h me-2"></i> {t("Filter By")}
            </button>
            {/* <button className="mobileScreen">
              <i className="fas fa-sort-numeric-up-alt me-2"></i> Sort By
            </button> */}
            <DropdownButton
              className=" align-items-center me-2 d-flex flex-row justify-content-center align-item-center pop-up"
              id="dropdown-basic-button"
              title={t("Sort by")}
            >
              <Dropdown.Item
                className="m-2"
                onClick={() => sortDoctorsHandler("lth")}
              >
                {t("Price low to high")}
              </Dropdown.Item>
              <Dropdown.Item
                className="m-2"
                onClick={() => sortDoctorsHandler("htl")}
              >
                {t("Price high to low")}
              </Dropdown.Item>
              <Dropdown.Item
                className="m-2"
                onClick={() => sortDoctorsHandler("wt")}
              >
                {t("Waiting time")}
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <i className="fas fa-sliders-h me-2"></i> {t("Filter By")}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="mobile-filter-body">
                <FilterBody />
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )}
    </div>
  );
}
