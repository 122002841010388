import AutoScrollToTop from "./components/autoScrollToTop";
import { BrowserRouter } from "react-router-dom";
import CssImports from "./styles/CssImports";
import Layout from "./Layout";
import ReduxStore from "./redux/ReduxStore";
import { ToastContainer } from "react-toastify";
import { LangProvider } from "./context/LangProvider";
import "./styles/app.css";

function App() {
  return (
    <BrowserRouter>
      <LangProvider>
        <CssImports />
        <ReduxStore />
        <AutoScrollToTop />
        <Layout />
        <ToastContainer />
      </LangProvider>
    </BrowserRouter>
  );
}

export default App;
