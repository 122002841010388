import React from "react";

export default function OrLine() {
  return (
    <div className="or-box">
      <hr />
      <span>or</span>
    </div>
  );
}
