import Spinner, { SpinnerError } from "../Spinner";
import { useContext, useEffect, useState } from "react";

import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import SliderCard from "./SliderCard";
import { useSelector } from "react-redux";
import { langContext } from "../../context/LangProvider";

export default function ScanLabs({ selectedLabsIds, title, btnText, btnUrl }) {
  const { labs, status } = useSelector((state) => state.labs);
  const [viewedLabs, setViewedLabs] = useState([]);
  const [spinner, setSpinner] = useState(<Spinner height="100" />);
  console.log("labs", labs);
  console.log("selectedLabsIds", selectedLabsIds);
  const { t } = useContext(langContext);
  useEffect(() => {
    let labs_Arr = [];
    if (status === "success") {
      selectedLabsIds.forEach((id) => {
        let lab = labs.find((lab) => lab.id === id);
        if (lab) {
          labs_Arr.push(lab);
        }
      });
      if (!labs_Arr.length) {
        setSpinner(<SpinnerError />);
        return;
      }
      setViewedLabs(labs_Arr);
    } else if (status === "failed") {
      setSpinner(<SpinnerError />);
    }
  }, [labs, status]);
  const settings = {
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: viewedLabs.length >= 3 ? 3 : viewedLabs.length,
    slidesToScroll: 1,
    nextArrow: <img src="/assets/slider-arrow-right.svg" alt="" />,
    prevArrow: <img src="/assets/slider-arrow-left.svg" alt="" />,
    responsive: [
      {
        breakpoint: 912,
        settings: {
          slidesToShow: viewedLabs.length >= 2 ? 2 : viewedLabs.length,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  console.log("viewedLabs", viewedLabs);

  return (
    <div className="specialists">
      <Container>
        <div
          className="mb-4"
          dangerouslySetInnerHTML={{ __html: `<h1>${title}</h1>` }}
        ></div>
        {viewedLabs ? (
          <Slider {...settings} className="mySlider">
            {viewedLabs.map((lab, index) => (
              <SliderCard
                numOfCards={viewedLabs.length}
                data={lab}
                key={index}
                imgAlternate="/assets/pattern.svg"
                linkText={t("View all Working Hours")}
                dataType="lab"
              />
            ))}
          </Slider>
        ) : (
          <>{spinner}</>
        )}

        <div className="d-flex justify-content-center align-items-center m-top-30">
          <Link to={btnUrl}>
            <button className="scan-more pop-up">
              {btnText} <i className="fas fa-chevron-right text-white"></i>
            </button>
          </Link>
        </div>
      </Container>
    </div>
  );
}
