import Select from "react-select";

export default function BookAgainLocation({ options, currentAppointment }) {
  return (
    <>
      <img src="/assets/location.svg" alt="" className="mx-2 icon-img-size" />
      <span className="me-1">
        {options.length === 1 ? "Location :" : "Locations :"}
      </span>
      {options.length > 1 ? (
        <div className="locationSelectionBox">
          <Select
            className="basic-single"
            placeholder="Select Location"
            name="locations"
            value={options.find(
              (option) => option.id == currentAppointment.location_id
            )}
            isDisabled
          />
        </div>
      ) : (
        options.length === 1 && <span className="bold">{options[0].label}</span>
      )}
    </>
  );
}
