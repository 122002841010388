import { Form, Spinner } from "react-bootstrap";

import AuthInput from "./AuthInput";
import axios from "axios";
import { login } from "../../redux/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import validate from "../../Helpers/validate";
import { langContext } from "../../context/LangProvider";

export default function SignupForm({ formData }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useContext(langContext);
  const [values, setValues] = useState({
    name: "",
    mobile: "",
    birthDate: "",
    email: "",
    gender: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    name: [],
    mobile: [],
    birthDate: [],
    email: [],
    gender: [],
    password: [],
    confirmPassword: [],
  });
  const [submitted, setSubmitted] = useState(false);
  const [signingUp, setSigningUp] = useState(false);
  const [signUpText, setSignUpText] = useState(t(""));
  const changeValues = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const signupHandler = async (e) => {
    e.preventDefault();
    setSigningUp(true);
    setSignUpText(<Spinner animation="border" size="sm" />);
    try {
      let res = await axios.post(`${process.env.REACT_APP_AUTH_URL}/signup`, {
        user: {
          name: values.name,
          email: values.email,
          password: values.password,
          password_confirmation: values.confirmPassword,
        },
        profile: {
          mobile: values.mobile,
          gender: values.gender,
          birth_date: values.birthDate,
        },
      });
      console.log(res);
      dispatch(login(res.data.data));
      navigate("/profile/update");
    } catch (err) {
      // console.log(err);
      // console.log(err.response.data.errors);
      if (err.response.data.errors) {
        let apiErrors = { ...err.response.data.errors };
        const mobileErrors = validate(apiErrors["profile.mobile"]);
        const allErrors = errors;
        allErrors.mobile = mobileErrors;
        const genderErrors = validate(apiErrors["profile.gender"]);
        allErrors.gender = genderErrors;
        const birthDateErrors = validate(apiErrors["profile.birth_date"]);
        allErrors.birthDate = birthDateErrors;
        const nameErrors = validate(apiErrors["user.name"]);
        allErrors.name = nameErrors;
        const emailErrors = validate(apiErrors["user.email"]);
        allErrors.email = emailErrors;
        const passwordErrors = validate(apiErrors["user.password"]);
        allErrors.password = passwordErrors;
        if (
          passwordErrors.includes(
            "The user.password confirmation does not match."
          )
        )
          allErrors.confirmPassword = [""];
        setErrors(allErrors);
      }
    } finally {
      setSigningUp(false);
      setSignUpText(t("signupLabel"));
      setSubmitted(true);
    }
  };
  console.log(errors);
  return (
    <Form onSubmit={(e) => signupHandler(e)}>
      {formData.map((input, index) => (
        <AuthInput
          key={index}
          label={t(input.label)}
          placeholder={t(input.label)}
          type={input.type}
          name={input.name}
          value={values[input.name]}
          onChange={changeValues}
          error={errors[input.name]}
          submitted={submitted}
        />
      ))}
      <div className="d-flex justify-content-center align-items-center mt-4 pb-4">
        <button disabled={signingUp} className="pop-up signup">
          {signUpText || t("signupLabel")}
        </button>
      </div>
    </Form>
  );
}
