import MyVerticallyCenteredModal from "./reviewsModal";
import Review from "./review";
import { useContext, useState } from "react";
import { langContext } from "../../../context/LangProvider";

export default function Reviews({ reviews, doctorName }) {
  const [modalShow, setModalShow] = useState(false);
  const { t } = useContext(langContext);
  return (
    <div className="doc-reviews">
      <h5 className="bold py-md-3">{t("Reviews")}</h5>
      <div className="reviews-box">
        {reviews?.length ? (
          reviews.slice(0, 6).map((review) => <Review review={review} />)
        ) : (
          <p>{t("No reviews available yet")}</p>
        )}
      </div>
      <button
        style={{
          border: "none",
          backgroundColor: "transparent",
          color: "#a99156",
          fontSize: "18px",
          textDecoration: "underline",
        }}
        onClick={() => setModalShow(true)}
      >
        {reviews?.length == 0 && t("View All Reviews")}
      </button>{" "}
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        reviews={reviews}
        doctorName={doctorName}
      />
    </div>
  );
}
