import { Container } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";

export default function Spinner({ height, color }) {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <TailSpin
        height={height}
        width="200"
        color={color ? color : "#a99156"}
        ariaLabel="loading"
      />
    </div>
  );
}
export const SpinnerError = () => {
  return (
    <Container>
      <div style={{ fontSize: "1.2rem" }}>
        <h4>No Data Found , The problem might be one of the following:</h4>
        <ul className="m-auto">
          <li>Server Error , Please try again later</li>
          <li>Slow Connection</li>
          <li>No Data Matching</li>
        </ul>
      </div>
    </Container>
  );
};
