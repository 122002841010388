import { Col, Form, Row } from "react-bootstrap";

import ContactFormInput from "./ContactFormInput";
import { useContext, useState } from "react";
import { langContext } from "../../context/LangProvider";

export default function ContactForm({ formData, initialValues }) {
  const [values, setValues] = useState(initialValues);
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const { t } = useContext(langContext);
  const changeValues = (e, input) => {
    if (e.type === "array") {
      setValues({
        ...values,
        [input.name]: e.items,
      });
    } else {
      if (e.target.type === "checkbox") {
        let checkedItems = values[e.target.name];
        if (e.target.checked) {
          checkedItems = [...checkedItems, e.target.value];
        } else {
          checkedItems = values[e.target.name].filter(
            (value) => value !== e.target.value
          );
        }
        setValues({
          ...values,
          [e.target.name]: checkedItems,
        });
      }
      if (e.target.type === "select-one") {
        if (Object.hasOwn(input.extra, e.target.value)) {
          input.showText = true;
        } else {
          input.showText = false;
        }
        setValues({ ...values, [e.target.name]: e.target.value });
      }
      if (e.target.type === "file") {
        if (e.target.files.length > input.validation.max) {
          alert(`${t("You can only choose")} ${input.validation.max} files`);
        } else {
          const fileNames = [];
          for (let i = 0; i < input.validation.max; i++) {
            fileNames.push(e.target.files[i].name);
          }
          setValues({ ...values, [e.target.name]: fileNames });
        }
      } else {
        setValues({ ...values, [e.target.name]: e.target.value });
      }
    }
  };
  return (
    <Col xs={12} lg={6} className="mb-4">
      <div className="contact-form">
        <Form onSubmit={handleSubmit}>
          <Row>
            {formData &&
              formData.slice(0, 2).map((input) => (
                <Col xs={12} md={6}>
                  <ContactFormInput
                    input={input}
                    value={values[input.name]}
                    onChange={changeValues}
                  />
                </Col>
              ))}
          </Row>
          {formData &&
            formData
              .slice(2, formData.length)
              .map((input) => (
                <ContactFormInput
                  input={input}
                  value={values[input.name]}
                  onChange={changeValues}
                />
              ))}
          <div className="d-flex justify-content-center align-items-center">
            <button className="pop-up">{t("send")}</button>
          </div>
        </Form>
      </div>
    </Col>
  );
}
