import axios from "axios";
import handleResponse from "../Helpers/handleResponse";
let token = localStorage.getItem("dentistUserToken");
const auth = {
    "content-type": "application/json",
    "Accept": "application/json",
    "Authorization": "Bearer " + token
}
const getDoctorsServices = async (id) => {
    token = localStorage.getItem("dentistUserToken");
    const requestOptions = { method: "GET", headers: auth };
    let json = await axios.get(`${process.env.REACT_APP_API_URL}/doctor_dashboard/services?id=${2}`, { headers: auth })
    return json.data
}
const updateService = async (service, id, restoreState) => {
    console.log('updating');
    token = localStorage.getItem("dentistUserToken");
    const requestOptions = {
        method: "POST",
        headers: auth,
        body: service,
    };
    let _url = `${process.env.REACT_APP_API_URL}/doctor_dashboard/services`;
    if (id) {
        _url += `/${id}?restore=${restoreState}`;
    }
    return fetch(_url, requestOptions).then(handleResponse);
};
export async function insertService(service) {
    token = localStorage.getItem("dentistUserToken");
    await axios.post(
        `${process.env.REACT_APP_API_URL}/doctor_dashboard/services`,
        service,
        {
            headers: {
                "content-type": "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        }
    ).then(handleResponse)
}

export async function updateServices(temp) {
    let services = { services: temp }
    token = localStorage.getItem("dentistUserToken");
    await axios.post(
        `${process.env.REACT_APP_API_URL}/doctor_dashboard/services`,
        services,
        {
            headers: {
                "content-type": "application/json",
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
        }
    ).then(handleResponse)
}
export async function deleteService(serviceId, forceState) {
    const requestOptions = {
        method: "DELETE",
        headers: auth,
    };
    return fetch(
        `${process.env.REACT_APP_API_URL}/doctor_dashboard/services/${serviceId}`,
        requestOptions
    );
}
export async function getAll() {
    token = localStorage.getItem("dentistUserToken");
    const requestOptions = { method: "GET", headers: auth };
    return fetch(
        `${process.env.REACT_APP_API_URL}/doctor_dashboard/services`,
        requestOptions
    ).then(handleResponse);
}


export const servicesService = {
    getAll,
    insertService,
    updateService,
    deleteService,
    getDoctorsServices,
    updateServices
};
