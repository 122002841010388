import { Pagination, Row } from "react-bootstrap";
import Spinner, { SpinnerError } from "../Spinner";
import { useEffect, useState } from "react";

import Lab from "./lab";
import { useSelector } from "react-redux";

export default function ScanLabs({ numPerPage, title }) {
  const { labs, status } = useSelector((state) => state.labs);

  const [viewedLabs, setViewedLabs] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [spinner, setSpinner] = useState(<Spinner height="100" />);

  let items = [];
  const changePage = (page) => {
    setActivePage(page);
    let labz = labs.slice(numPerPage * (page - 1), numPerPage * page);
    setViewedLabs(labz);
  };
  for (
    let number = 1;
    number <= Math.ceil(labs.length / numPerPage);
    number++
  ) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === activePage}
        onClick={() => changePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }
  useEffect(() => {
    if (status === "success") {
      setViewedLabs(labs.slice(0, numPerPage));
    } else if (status === "failed") {
      setSpinner(<SpinnerError />);
    }
  }, [labs, numPerPage, status]);
  console.log(viewedLabs);
  return (
    <div className="labs">
      {viewedLabs.length > 0 ? (
        <>
          <h3 className="py-4 bold">{title}</h3>
          <Row>
            {viewedLabs.map((lab, index) => (
              <Lab lab={lab} key={index} />
            ))}
          </Row>
          <div className="d-flex justify-content-end align-items-center pb-5">
            <Pagination>{items.length > 1 ? items : null}</Pagination>
          </div>
        </>
      ) : (
        <>{spinner}</>
      )}
    </div>
  );
}
