import { Col, Row } from "react-bootstrap";
import { getIntervalsBetweenShifts, isItToday } from "doitfast";

import AppointmentButton from "../AppointmentButton";
import BookingModal from "./BookingModal";
import DaysSlider from "../DaysSlider";
import { useContext, useState } from "react";
import { langContext } from "../../context/LangProvider";

export default function CardBody({
  viewdAppointments,
  viewdDays,
  setSelectedDay,
  setViewdAppointments,
  setSelectedDate,
  appointmentHandler,
  isShowingModal,
  dataName,
  data,
  confirmation,
  handleConfirm,
}) {
  const [modalShow, setModalShow] = useState(false);
  const { t } = useContext(langContext);
  return (
    <Row className="gx-3">
      <DaysSlider
        days={viewdDays}
        setViewdAppointmentsHandler={(appoint) => {
          setSelectedDay(appoint.day);
          setSelectedDate(appoint.date);
          let isToday = isItToday(appoint.date);
          if (dataName === "lab") {
            isToday = false;
          }
          let viewdAppointments = getIntervalsBetweenShifts(
            appoint.open_hour,
            appoint.close_hour,
            "00:30",
            isToday
          );
          setViewdAppointments(viewdAppointments);
        }}
      />
      <div className="appointmentsSlidingBox">
        {dataName === "doctor" &&
          viewdAppointments?.filter((appointment) => appointment.includes("AM"))
            .length > 0 && <div className="bold mt-3">{t("Morning")}</div>}

        <Col sm={12}>
          {dataName === "doctor" ? (
            <>
              {viewdAppointments?.length > 0 && (
                <Row className="my-2 gx-3 mobileCard-hide">
                  {viewdAppointments
                    .filter((appointment) => appointment.includes("AM"))
                    .map((appointment, i) => (
                      <AppointmentButton
                        key={i}
                        appointment={appointment}
                        spacing={3}
                        appointmentHandler={appointmentHandler}
                      />
                    ))}
                </Row>
              )}
              {viewdAppointments?.length > 0 && !isShowingModal && (
                <Row className="my-2 gx-3 mobileCard-show">
                  {viewdAppointments
                    .filter((appointment) => appointment?.includes("AM"))
                    .slice(0, 4)
                    .map((appointment, i) => (
                      <AppointmentButton
                        key={i}
                        appointment={appointment}
                        spacing={3}
                        appointmentHandler={appointmentHandler}
                      />
                    ))}
                </Row>
              )}
              {viewdAppointments?.length > 0 && isShowingModal && (
                <Row className="my-2 gx-2 mobileCard-show">
                  {viewdAppointments
                    .filter((appointment) => appointment.includes("AM"))
                    .map((appointment, i) => (
                      <AppointmentButton
                        key={i}
                        appointment={appointment}
                        spacing={3}
                        appointmentHandler={appointmentHandler}
                      />
                    ))}
                </Row>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              {viewdAppointments?.length > 0 && (
                <span className="mx-2">From</span>
              )}
              {viewdAppointments?.length > 0 && (
                <Row className="my-2 gx-3">
                  {viewdAppointments
                    .filter(
                      (appointment) =>
                        appointment.includes("AM") &&
                        appointment === viewdAppointments[0]
                    )
                    .map((appointment, i) => (
                      <AppointmentButton
                        key={i}
                        appointment={appointment}
                        spacing={12}
                        appointmentHandler={appointmentHandler}
                      />
                    ))}
                </Row>
              )}
              {viewdAppointments?.length > 0 && (
                <span className="mx-2">To</span>
              )}
              {viewdAppointments?.length > 0 && (
                <Row className="my-2 gx-3">
                  {viewdAppointments
                    .filter(
                      (appointment) =>
                        appointment.includes("PM") &&
                        appointment ===
                          viewdAppointments[viewdAppointments?.length - 1]
                    )
                    .map((appointment, i) => (
                      <AppointmentButton
                        key={i}
                        appointment={appointment}
                        spacing={12}
                        appointmentHandler={appointmentHandler}
                      />
                    ))}
                </Row>
              )}
            </div>
          )}
        </Col>
        {dataName === "doctor" && (
          <>
            {dataName === "doctor" &&
              viewdAppointments?.filter((appointment) =>
                appointment.includes("PM")
              ).length > 0 && <div className="bold mt-3">{t("Afternoon")}</div>}
            <Col sm={12}>
              {viewdAppointments?.length > 0 && (
                <Row className="my-2 gx-3 mobileCard-hide">
                  {viewdAppointments
                    .filter((appointment) => appointment.includes("PM"))
                    .map((appointment, i) => (
                      <AppointmentButton
                        key={i}
                        appointment={appointment}
                        spacing={3}
                        appointmentHandler={appointmentHandler}
                      />
                    ))}
                </Row>
              )}
              {viewdAppointments?.length > 0 && !isShowingModal && (
                <Row className="my-2 gx-3 mobileCard-show">
                  {viewdAppointments
                    .filter((appointment) => appointment.includes("PM"))
                    .slice(0, 4)
                    .map((appointment, i) => (
                      <AppointmentButton
                        key={i}
                        appointment={appointment}
                        spacing={3}
                        appointmentHandler={appointmentHandler}
                      />
                    ))}
                </Row>
              )}
              {viewdAppointments?.length > 0 && isShowingModal && (
                <Row className="my-2 gx-2 mobileCard-show">
                  {viewdAppointments
                    .filter((appointment) => appointment.includes("PM"))
                    .map((appointment, i) => (
                      <AppointmentButton
                        key={i}
                        appointment={appointment}
                        spacing={3}
                        appointmentHandler={appointmentHandler}
                      />
                    ))}
                </Row>
              )}
            </Col>
          </>
        )}
      </div>
      {!isShowingModal && (
        <div className="justify-content-center mobileCard-show">
          <span onClick={() => setModalShow(true)}>View all slots</span>
          <BookingModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            data={data}
            dataName={dataName}
            confirmation={confirmation}
            handleConfirm={handleConfirm}
            overlay={() => {
              console.log("object");
            }}
            isShowingModal
          />
        </div>
      )}
    </Row>
  );
}
