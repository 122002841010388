import { useContext } from "react";
import Select from "react-select";
import { langContext } from "../../context/LangProvider";

export default function SelectLocation({
  options,
  disabledSelection,
  handleChooseLocation,
}) {
  const { t } = useContext(langContext);
  console.log("options", options);
  return (
    <>
      <img src="/assets/location.svg" alt="" className="mx-2 icon-img-size" />
      <span className="me-1">
        {options.length === 1 ? t("Location :") : t("Locations :")}
      </span>
      {options.length > 1 ? (
        <div className="locationSelectionBox">
          <Select
            className="basic-single"
            onChange={handleChooseLocation}
            placeholder={t("Select Location")}
            isSearchable={true}
            name={t("locations")}
            options={options}
            isDisabled={disabledSelection}
          />
        </div>
      ) : (
        options.length === 1 && <span className="bold">{options[0].label}</span>
      )}
    </>
  );
}
