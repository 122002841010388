import { t } from "i18next";
import Agreement from "./Agreement";
import GoogleFaceBook from "./GoogleFaceBook";
import OrLine from "./OrLine";
import SignupForm from "./SignupForm";

export default function SignUp() {
  const formData = [
    {
      label: "yourName",
      placeholder: "fullName",
      type: "text",
      name: "name",
    },
    {
      label: "mobileNumber",
      placeholder: "Phone Number",
      type: "text",
      name: "mobile",
    },
    {
      label: "E-Mail",
      placeholder: "Email Address",
      type: "email",
      name: "email",
    },
    { label: "gender", type: "gender", name: "gender" },
    {
      label: "birthdate",
      placeholder: "Birthdate",
      type: "date",
      name: "birthDate",
    },
    {
      label: "Password",
      placeholder: "Password",
      type: "password",
      name: "password",
    },
    {
      label: "confirmPassword",
      placeholder: "confirmPassword",
      type: "password",
      name: "confirmPassword",
    },
  ];

  return (
    <div className="auth-cont">
      <GoogleFaceBook />
      <div>
        <OrLine />
        <div>
          <SignupForm formData={formData} />
        </div>
      </div>
      <Agreement label={"existUser"} link={"loginButton"} />
    </div>
  );
}
