export default function AppointDay({
  appoint,
  activeDay,
  selectAppointments,
  index,
}) {
  const date = appoint?.date
    ?.replace(`${new Date().getFullYear()}-`, "")
    .replace("-", "/");
  return (
    <span
      xs={3}
      className={`my-2 swiper-slide ${index % 2 === 0 ? "sep-back" : "sep2-back"
        } ${activeDay ? "active-day" : ""}`}
      style={{ width: "100%", display: "inline-block" }}
      onClick={selectAppointments}
    >
      {`${appoint.day.toUpperCase()} ${date !== "" ? `(${date})` : ""}`}
    </span>
  );
}
