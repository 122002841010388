import { Accordion } from "react-bootstrap";
import CheckBoxItem from "../CheckBoxItem";
import React, { useContext } from "react";
import { langContext } from "../../../../context/LangProvider";

export default function Title({ selectedTitle, setSelectedTitle }) {
  const handleChangeTitle = (e) => {
    if (e.target.checked) {
      setSelectedTitle([...selectedTitle, e.target.value]);
    } else {
      setSelectedTitle(selectedTitle.filter((ser) => ser !== e.target.value));
    }
  };
  const { t } = useContext(langContext);
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>{t("Title")}</Accordion.Header>
        <Accordion.Body>
          <CheckBoxItem
            checkBoxToggle={handleChangeTitle}
            label={t("Professor")}
            value="professor"
          />
          <CheckBoxItem
            checkBoxToggle={handleChangeTitle}
            label={t("Lecturer")}
            value="lecturer"
          />
          <CheckBoxItem
            checkBoxToggle={handleChangeTitle}
            label={t("Consultant")}
            value="consultant"
          />
          <CheckBoxItem
            checkBoxToggle={handleChangeTitle}
            label={t("Specialist")}
            value="specialist"
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
