import { Col } from "react-bootstrap";
import PatientNav from "./PatientNav";
import UserService from "../../../services/userService";
import { logout } from "../../../redux/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { langContext } from "../../../context/LangProvider";

export default function PatientNavGroup({ onHide }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutHandler = () => {
    dispatch(logout());
    navigate("/login");
  };
  const userIsDoctor = UserService.checkUserRole("Doctor");
  const { t } = useContext(langContext);

  const profileNavs = [
    {
      title: t("Edit Profile"),
      imgs: ["/assets/edit-active", "/assets/edit-inactive"],
      tabTitle: "update",
    },
    {
      title: t("Password & Security"),
      imgs: ["/assets/password-active", "/assets/password-inactive"],
      tabTitle: "password",
    },
    userIsDoctor
      ? {
          title: t("My Dashboard"),
          imgs: ["/assets/appointment-active", "/assets/appointment-inactive"],
          tabTitle: "dashboard",
        }
      : {
          title: t("My Appointments"),
          imgs: ["/assets/appointment-active", "/assets/appointment-inactive"],
          tabTitle: "appointments",
        },
  ];
  return (
    <Col className="patient-nav-col" md={4} lg={3}>
      <main className="patientNavGroup" style={{ minHeight: "500px" }}>
        {profileNavs.map((nav, i) => (
          <PatientNav
            key={i}
            tabTitle={nav.tabTitle}
            title={nav.title}
            imgs={nav.imgs}
            onHide={onHide}
          />
        ))}

        <PatientNav title="Logout" select={logoutHandler} />
      </main>
    </Col>
  );
}
