import React, { useContext } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { langContext } from "../../../context/LangProvider";
import { getMyAppointments } from "../../../redux/myappointments";

export default function SwitchButton({ viewToggler, setViewToggler }) {
  const dispatch = useDispatch();

  const changeView = () => {
    dispatch(getMyAppointments());
    setViewToggler(
      viewToggler === "Consultation" ? "Appointments" : "Consultation"
    );
  };
  const { t } = useContext(langContext);
  return (
    <button className="learn-more" onClick={changeView}>
      <span className="circle" aria-hidden="true">
        <span className="icon arrow"></span>
      </span>
      <span className="button-text">{t(viewToggler)}</span>
    </button>
  );
}
