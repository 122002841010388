import { Col, Container, Row } from "react-bootstrap";
import Spinner, { SpinnerError } from "../Spinner";
import { useEffect, useState } from "react";

import { IoChevronForwardCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Service from "../AllServices/ServiceCard";
import { useSelector } from "react-redux";
import useWindowWidth from "../../Hooks/useWindowWidth";

export default function TopServices({
  selectedServicesIds,
  title,
  btnText,
  btnUrl,
}) {
  const { services, status } = useSelector((state) => state.services);
  const [viewedServices, setViewedServices] = useState([]);
  const [screenWidth] = useWindowWidth();
  const [spinner, setSpinner] = useState(<Spinner height="100" />);

  useEffect(() => {
    let services_Arr = [];
    selectedServicesIds.forEach((id) => {
      let service = services.find((service) => service.id === id);
      if (service) {
        services_Arr.push(service);
      }
    });
    if (status === "success") {
      setViewedServices(services_Arr);
    } else if (status === "failed") {
      setSpinner(<SpinnerError />);
    }
  }, [services, status]);

  return (
    <Container className="topServices">
      <div
        className="mb-4"
        dangerouslySetInnerHTML={{ __html: `<h1>${title}</h1>` }}
      ></div>
      {viewedServices.length > 0 ? (
        <Row>
          {viewedServices.map((s) => (
            <Service service={s} key={s.id} />
          ))}
          <Col sm={12} md={6} lg={4}>
            {screenWidth > 768 ? (
              <div className="more-service">
                <Link to={btnUrl} className="more-link">
                  <button style={{color:'white'}}>
                    {btnText} <IoChevronForwardCircleOutline />
                  </button>
                </Link>
              </div>
            ) : (
              <div className="more-service">
                <Link to={btnUrl} className="more-link">
                  {btnText} <IoChevronForwardCircleOutline />
                </Link>
              </div>
            )}
          </Col>
        </Row>
      ) : (
        <>{spinner}</>
      )}
    </Container>
  );
}
