import { useEffect, useState } from "react";

// import Header from "../../components/dashboard/Header";
// import SideBar from "../../components/dashboard/SideBar ";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CCard, CCardBody, CCardHeader } from "@coreui/react";
import SideBar from "../components/dashboard/SideBar";
import Header from "../components/dashboard/Header";
import FAQEdit from "../components/dashboard/FAQEdit";

export default function DashboardEditFAQs() {
    const navigate = useNavigate(),
        [loading, setLoading] = useState(true)
    const {
        token,
        doctor,
        doctorStatus,
        status: userStatus,
    } = useSelector((state) => state.user),
        { visible, narrow } = useSelector((state) => state.dashboard);
    useEffect(() => {
        if (userStatus === "success" && doctorStatus === "success") {
            if (!doctor) {
                navigate("/");
                return;
            }
            // getLocations();
        }
    }, [userStatus, doctorStatus, doctor]);

    return (
        <div className="" style={{ minHeight: "100vh" }}>
            <SideBar />
            <div
                className="dashboard-container"
                style={
                    window.innerWidth > 768 && visible
                        ? narrow
                            ? { marginLeft: "65px" }
                            : { marginLeft: "256px" }
                        : { marginLeft: "0" }
                }
            >
                <Header />
                <div className="dashboard-wrapper">
                    <CCard>
                        <CCardHeader>Edit FAQ</CCardHeader>
                        <CCardBody>
                            <FAQEdit />
                            {/* <DoctorFAQs /> */}
                        </CCardBody>
                    </CCard>
                </div>
            </div>
        </div>
    );
}
