import ContactForm from "./ContactForm";
import ContactInfo from "./ContactInfo";
import { Row } from "react-bootstrap";

export default function ContactUsPage({ data, formData, initialValues }) {
  return (
    <div className="contactPage">
      <Row>
        <ContactInfo data={data} />
        <ContactForm formData={formData} initialValues={initialValues} />
      </Row>
    </div>
  );
}
