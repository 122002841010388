import { useEffect, useState } from "react";

import { Card } from "react-bootstrap";
import LocationInfo from "./LocationInfo";
import { useSelector } from "react-redux";
import useWindowWidth from "../../../Hooks/useWindowWidth";

export default function DocInfo({ data }) {
  const [screenWidth] = useWindowWidth();
  const { doctors } = useSelector((state) => state.doctors);
  const [doctor, setDoctor] = useState(null);
  useEffect(() => {
    if (doctors) {
      const doctor = doctors?.find((doctor) => doctor.id === data?.doctorId);
      setDoctor(doctor?.name);
    } else {
      setDoctor("");
    }
  }, [doctors]);
  // const rating =
  //   5 *
  //   (data?.reviews?.reduce((acc, curr) => acc + curr.rate, 0) /
  //     (data?.reviews?.length * 5));
  const rating = 0;
  return (
    <div className="doc-info">
      <h4
        dangerouslySetInnerHTML={{
          __html: data?.description
        }
        } className="bold pb-2" />
      <div className="doc-info-cont d-flex flex-column">
        <div>
          <div className="img-cont" style={{ height: "9.5rem", width: "9.5rem" }}>
            <img src={data?.provider?.profile_picture?.original_url || "/assets/noAvatar.png"} alt="" />
          </div>
        </div>
        <div className="card-text ms-2">
          <Card.Title>
            Doctor <span className="bold"> {data?.provider?.name}</span>
          </Card.Title>

          {screenWidth >= 540 && (
            <>
              <div>
                <img
                  src="/assets/location.svg"
                  alt=""
                  className="icon-img-size me-2"
                />
                <LocationInfo data={data} />
              </div>
              <div>
                <img
                  src="/assets/star.svg"
                  alt=""
                  className="icon-img-size me-2"
                />
                <span className="bold">{rating || 0}</span>
                <span className="text-muted ms-1">
                  ({data?.reviews?.length || 0} reviews)
                </span>
              </div>
              {data?.online_payment === "1" && (
                <div>
                  <img
                    src="/assets/credit-card.svg"
                    alt=""
                    className="icon-img-size me-2"
                  />
                  <span>Online Payment Available</span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {
        screenWidth < 540 && (
          <>
            <div className="mt-2">
              <img
                src="/assets/location.svg"
                alt=""
                className="icon-img-size me-2"
              />
              <LocationInfo data={data} />
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <img
                  src="/assets/star.svg"
                  alt=""
                  className="icon-img-size me-2"
                />
                <span className="bold">{rating}</span>
                <span className="text-muted ms-1">
                  ({data?.reviews?.length || 0} reviews)
                </span>
              </div>
              <div className="pe-5">
                <img
                  src="/assets/waiting-time.svg"
                  alt=""
                  className="icon-img-size me-2"
                />
                <span className="bold">{data?.waiting_time}</span>
              </div>
            </div>
            <div>
              <img
                src="/assets/credit-card.svg"
                alt=""
                className="icon-img-size me-2"
              />
              <span>
                Online Payment{" "}
                {data?.online_payment === "1" ? "Available" : "Not Available"}
              </span>
            </div>
          </>
        )
      }
    </div >
  );
}
