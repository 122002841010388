import { Form, Row } from "react-bootstrap";
import Spinner, { SpinnerError } from "../Spinner";
import { useEffect, useState } from "react";

import Service from "./ServiceCard";
import { useSelector } from "react-redux";

export default function Services({ title }) {
  const [searched, setSearched] = useState([]);
  const [viewedServices, setViewedServices] = useState([]);
  const { services, status } = useSelector((state) => state.services);
  const [spinner, setSpinner] = useState(<Spinner height="100" />);

  useEffect(() => {
    if (status === "success") {
      setViewedServices(services);
      setSearched(services);
    } else if (status === "failed") {
      setSpinner(<SpinnerError />);
    }
  }, [services, status]);

  const handleSearch = (searchValue) => {
    if (searchValue !== "") {
      const filteredSearch = services.filter((s) =>
        s.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setSearched(filteredSearch);
    } else {
      setSearched(services);
    }
  };
  return (
    <div className="services">
      <div className="service-box">
        <div className="mb-4" dangerouslySetInnerHTML={{ __html: title }}></div>
        <div>
          <Form.Group className="my-4" controlId="formBasicEmail">
            <Form.Control
              type="text"
              placeholder="Search in services..."
              className="search"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Form.Group>
        </div>
      </div>
      {viewedServices.length > 0 ? (
        <div>
          <Row>
            {searched.map((s) => (
              <Service service={s} key={s.id} />
            ))}
          </Row>
        </div>
      ) : (
        <>{spinner}</>
      )}
    </div>
  );
}
