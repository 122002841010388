import useWindowWidth from "../../../Hooks/useWindowWidth";

export default function Pill({ bgColor, color, status }) {
  const [screenWidth] = useWindowWidth();

  return (
    <span
      style={
        screenWidth >= 992
          ? {
              backgroundColor: `${bgColor}`,
              padding: "0.5rem 1rem",
              borderRadius: "500px",
              color: `${color}`,
              fontWeight: "bold",
              display: "block",
              textAlign: "center",
              maxWidth: "150px",
            }
          : {
              backgroundColor: `${bgColor}`,
              padding: "0.5rem 1rem",
              borderRadius: "500px",
              color: `${color}`,
              fontWeight: "bold",
              fontSize: "80%",
              display: "block",
              textAlign: "center",
              maxWidth: "150px",
            }
      }
    >
      {status}
    </span>
  );
}
