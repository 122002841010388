import { Container } from "react-bootstrap";
import Login from "../components/auth/login";

export default function LoginPage() {
  return (
    <div className="">
      <div className="back-auth p-top-100 ">
        <Container>
          <Login />
        </Container>
      </div>
    </div>
  );
}
