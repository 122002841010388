import { useContext } from "react";
import { Col } from "react-bootstrap";
import { langContext } from "../../context/LangProvider";

export default function ContactInfo({ data }) {
  const { t } = useContext(langContext);
  return (
    <Col xs={12} lg={6} className="mb-4">
      <div className="d-flex flex-column">
        <h1>{t("Get in touch")}</h1>
        <div>
          <h3>{t("Phone")}</h3>
          <h5 style={{ letterSpacing: "0.2rem" }}>{data.phone}</h5>
        </div>
        <div>
          <h3>{t("E-Mail")}</h3>
          <a href="mailto:dentist@gmail.com">{data.email}</a>
        </div>
        <div>
          <h3>{t("Location")}</h3>
          <h5 class="bold">{data.location.name}</h5>
        </div>
        <div>
          <a
            className="direction"
            href={data.location.link}
            target="_blank"
            rel="noreferrer"
          >
            {t("Get Direction")}
          </a>
          <iframe
            src={data.location.embed}
            width={window.innerWidth >= 1024 ? "90%" : "100%"}
            height="200"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            title="map"
          ></iframe>
        </div>
      </div>
    </Col>
  );
}
