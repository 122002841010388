class CartService {
  static isInCart(cart, addedService) {
    return cart.find((item) => item.service.id === addedService.service.id);
  }
  static removeFromCart(cart, removedService, myCart) {
    return cart.filter((item) => item.service.id !== removedService.service.id);
  }
  static IsDeccreasingCartCount(existingProduct, addedService) {
    return existingProduct?.count > addedService?.count;
  }

  static getFees(cart = [], payload) {
    console.log("cart", cart);
    let fees = payload.extraFees;
    if (cart.length === 1) {
      if (payload.newService.count === 1) {
        fees += Number(cart[0].service.pivot.fees);
      } else {
        fees += Number(cart[0].service.pivot.package) * cart[0].count;
      }
    } else {
      cart.forEach((item) => {
        fees += Number(item.service.pivot.package) * item.count;
      });
    }

    return fees;
  }
}
export default CartService;
