import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { langContext } from "../../context/LangProvider";

export default function Direction({ data }) {
  const [activeDirection, setActiveDirection] = useState("");
  useEffect(() => {
    if (data) {
      setActiveDirection(data?.locations[0]?.area?.name);
    }
  }, [data]);
  const changeActiveDirection = (location) => {
    setActiveDirection(location.area.name);
    let changed_location = {
      lat: parseFloat(location.latitude) || 30.1,
      lng: parseFloat(location.longitude) || 30.2,
    };
    setCenter(changed_location);
    map.panTo(changed_location);
  };
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [center, setCenter] = useState({
    lat: parseFloat(data?.locations[0]?.latitude) || 30.1,
    lng: parseFloat(data?.locations[0]?.longitude) || 30.2,
  });
  const { t } = useContext(langContext);
  return (
    <div className="doc-direction">
      <h5 className="bold py-3">{t("Clinic Location")}</h5>
      <div className="map-cont">
        <div className="map">
          <LoadScript googleMapsApiKey="AIzaSyCO_n3f3EpqTUUT24PDn5l5T1Xwsawy7yw">
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "100%" }}
              center={center}
              zoom={8}
              onLoad={(map) => setMap(map)}
            >
              <>
                <Marker position={center} label={t("The Dentist")} />
              </>
            </GoogleMap>
          </LoadScript>
        </div>
        <div className="text">
          <h6 className="bold">{t("Dentist Clinics")}</h6>
          <span>
            {data?.locations.length > 0 ? (
              <>
                {data?.locations?.map((loc, i) => (
                  <div key={i}>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => changeActiveDirection(loc)}
                    >
                      {loc?.area?.name}
                    </span>
                  </div>
                ))}
              </>
            ) : (
              <div>{t("No Locations")}</div>
            )}
          </span>
          <a
            href={`https://www.google.com/maps/place/${activeDirection}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("Get Direction")}
          </a>
        </div>
      </div>
    </div>
  );
}
