import { Container } from "react-bootstrap";
import ForgotPasswordForm from "../components/auth/ForgotPassword";

export default function ForgotPassword() {
  return (
    <div className="">
      <div className="back-auth p-top-100 ">
        <Container>
          <ForgotPasswordForm />
        </Container>
      </div>
    </div>
  );
}
