import { Accordion } from "react-bootstrap";
import CheckBoxItem from "../CheckBoxItem";
import React, { useContext } from "react";
import { langContext } from "../../../../context/LangProvider";

export default function PatientCondition({
  selectedPatientCondition,
  setSelectedPatientCondition,
}) {
  const handleChangePatientCondition = (e) => {
    if (e.target.checked) {
      setSelectedPatientCondition([
        ...selectedPatientCondition,
        e.target.value,
      ]);
    } else {
      setSelectedPatientCondition(
        selectedPatientCondition.filter((ser) => ser !== e.target.value)
      );
    }
  };
  const { t } = useContext(langContext);
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>{t("Patient's Condition")}</Accordion.Header>
        <Accordion.Body>
          <CheckBoxItem
            checkBoxToggle={handleChangePatientCondition}
            label={t("Children")}
            value="children"
          />
          <CheckBoxItem
            checkBoxToggle={handleChangePatientCondition}
            label={t("Elders")}
            value="elders"
          />
          <CheckBoxItem
            checkBoxToggle={handleChangePatientCondition}
            label={t("Dental Emergency")}
            value="dental-emergency"
          />
          <div className="d-flex align-items-center">
            <CheckBoxItem
              checkBoxToggle={handleChangePatientCondition}
              label={t("Helped Individual")}
              value="helped-individual"
            />
            <img src="assets/helped-individual.svg" className="mx-1" alt="" />
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
