import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCardHeader,
  CCol,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CImage,
  CRow,
} from "@coreui/react";
import { cilMinus, cilPlus } from "@coreui/icons";
import { useCallback, useEffect, useState } from "react";
import CIcon from "@coreui/icons-react";
import Select from "react-select";
import getTotalFees from "../../Helpers/getTotalFees";
import moment from "moment";
import { statusOptions } from "../../Helpers/staticData";
import { useDropzone } from "react-dropzone";
import UserService from "../../services/userService";
let adultTeethOptions = [];
let childTeethOptions = [];
for (let i = 1; i <= 32; i++) {
  adultTeethOptions.push({ value: i, label: "tooth : " + i.toString() });
}
for (let i = 1; i <= 20; i++) {
  childTeethOptions.push({ value: i, label: "tooth : " + i.toString() });
}
export default function BookingEdit({ booking, setBooking, doctor, errors }) {
  const [totalFees, setTotalFees] = useState(0);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const onImagesDrop = useCallback(
    (acceptedFiles) => {
      setBooking({
        ...booking,
        new_gallery: acceptedFiles,
      });
    },
    [booking]
  );
  const {
    getRootProps: getGalleryRootProps,
    getInputProps: getGalleryInputProps,
    isDragActive: isPersonalGalleryDragActive,
  } = useDropzone({ accept: "image/*", onDrop: onImagesDrop });
  const removeImageFromGallery = (gallery, image_index) => {
    let tmpGallery = [...booking[gallery]];
    tmpGallery.splice(image_index, 1);
    setBooking({ ...booking, [gallery]: tmpGallery });
  };
  useEffect(() => {
    let totalFees = getTotalFees(booking?.services, booking?.fees);
    setTotalFees(totalFees);
  }, [booking]);
  console.log("history", booking?.patient_history);
  return (
    <CRow>
      <CCol xs="12">
        <CCard>
          <CCardHeader
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <CFormLabel className="my-0">Booking Info</CFormLabel>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12" md="6">
                <CFormLabel>Patient Name</CFormLabel>
                <CFormInput value={booking?.user?.name} disabled />
                <div className="text-danger">{errors?.user_id}</div>
              </CCol>
              <CCol xs="12" md="6">
                <CFormLabel>Examination Fees</CFormLabel>
                <CFormInput
                  type="number"
                  placeholder="Edit Fees"
                  value={booking?.fees}
                  disabled
                />
                <div className="text-danger">{errors?.user_id}</div>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="12" md="6">
                <CFormLabel>Provider Notes</CFormLabel>
                <CFormTextarea
                  placeholder="Edit Provider Notes"
                  value={booking?.provider_notes?.text}
                  onChange={(e) =>
                    setBooking({
                      ...booking,
                      provider_notes: {
                        ...booking?.provider_notes,
                        text: e.target.value,
                      },
                    })
                  }
                />
              </CCol>

              <CCol xs="12" md="6">
                <CFormLabel>Patient Notes</CFormLabel>
                <CFormTextarea
                  placeholder="Edit Patient Notes"
                  value={booking?.patient_notes?.text}
                  disabled
                />
              </CCol>
              {UserService.checkUserRole("Doctor") && (
                <CCol lg="12" className="mt-2">
                  <CFormLabel>Teeth notes</CFormLabel>
                  <CButton
                    onClick={() => {
                      if (!booking?.provider_notes.teeth) {
                        let temp = booking?.provider_notes;
                        temp.teeth = [];
                        setBooking({ ...booking, provider_notes: temp });
                      }
                      let tempProviderNotes = { ...booking?.provider_notes };
                      tempProviderNotes.teeth.push({ value: "", note: "" });
                      setBooking({
                        ...booking,
                        provider_notes: tempProviderNotes,
                      });
                    }}
                    variant="ghost"
                    size="sm"
                    color="primary"
                  >
                    <CIcon icon={cilPlus} />
                  </CButton>
                  <CButton
                    onClick={() => {
                      if (!booking?.provider_notes.teeth) {
                        let temp = booking?.provider_notes;
                        temp.teeth = [];
                        setBooking({ ...booking, provider_notes: temp });
                      }
                      let tempProviderNotes = { ...booking?.provider_notes };
                      tempProviderNotes.teeth.pop();
                      setBooking({
                        ...booking,
                        provider_notes: tempProviderNotes,
                      });
                    }}
                    variant="ghost"
                    size="sm"
                    color="secondary"
                  >
                    <CIcon icon={cilMinus} />
                  </CButton>

                  {booking?.provider_notes?.teeth &&
                    booking?.provider_notes.teeth.map((tooth, index) => {
                      return (
                        <CRow className="m-2">
                          <CCol>
                            <Select
                              value={{ label: tooth.value }}
                              options={groupedOptions}
                              onChange={(item) => {
                                let temp = { ...booking };
                                temp.provider_notes.teeth[index].value =
                                  item.value;
                                setBooking(temp);
                              }}
                              isSearchable={true}
                              placeholder="Select a tooth"
                            />
                          </CCol>
                          <CCol>
                            <CFormTextarea
                              onChange={(e) => {
                                let temp = { ...booking };
                                temp.provider_notes.teeth[index].note =
                                  e.target.value;
                                setBooking(temp);
                              }}
                              value={tooth.note}
                              placeholder="note"
                            />
                          </CCol>
                        </CRow>
                      );
                    })}
                </CCol>
              )}

              {/* <CCol xs="12" md="6">
                <CFormLabel>Meeting ID</CFormLabel>
                <CFormInput
                  placeholder="Edit Meeting ID"
                  value={booking?.provider_notes?.meeting_id}
                  onChange={(e) =>
                    setBooking({
                      ...booking,
                      provider_notes: {
                        ...booking?.provider_notes,
                        meeting_id: e.target.value,
                      },
                    })
                  }
                  onBlur={() => {
                    if (booking?.provider_notes?.meeting_id) {
                      const provider_notes = {
                        ...booking?.provider_notes,
                        meeting_id: booking?.provider_notes?.meeting_id
                          .split(" ")
                          .join(""),
                      };
                      setBooking({
                        ...booking,
                        provider_notes,
                      });
                    }
                  }}
                />
              </CCol> */}
              {/* <CCol xs="12" md="6">
                <CFormLabel>Meeting Password</CFormLabel>
                <CFormInput
                  placeholder="Edit Meeting Password"
                  value={booking?.provider_notes?.meeting_password}
                  onChange={(e) =>
                    setBooking({
                      ...booking,
                      provider_notes: {
                        ...booking?.provider_notes,
                        meeting_password: e.target.value,
                      },
                    })
                  }
                />
              </CCol> */}
            </CRow>

            <CCard>
              <CCardHeader>Upload images</CCardHeader>
              <CCardBody>
                <div
                  {...getGalleryRootProps()}
                  className="p-4 bg-light border border-dark border-dashed"
                >
                  <input {...getGalleryInputProps()} />
                  {isPersonalGalleryDragActive ? (
                    <p className="m-0 text-center">Drop files here</p>
                  ) : (
                    <p className="m-0 text-center">
                      Drag file or click to upload file
                    </p>
                  )}
                </div>
                <CCol>
                  <CRow>
                    <div className="d-flex flex-row">
                      {booking?.gallery &&
                        booking?.gallery.map((gallery_image, i) => (
                          <div className="m-2">
                            <CButton
                              key={i}
                              className="close-button"
                              onClick={(e) => {
                                e.preventDefault();
                                removeImageFromGallery("gallery", i);
                              }}
                            >
                              x
                            </CButton>
                            <img
                              onClick={() => {
                                window
                                  .open(gallery_image.original_url, "_blank")
                                  .focus();
                              }}
                              style={{ cursor: "pointer" }}
                              height="220"
                              src={gallery_image.original_url}
                              alt="item"
                            />
                          </div>
                        ))}

                      {booking?.new_gallery &&
                        booking?.new_gallery.map((gallery_image, i) => (
                          <div className="m-2">
                            <CButton
                              key={i}
                              className="close-button"
                              onClick={(e) => {
                                e.preventDefault();
                                removeImageFromGallery("new_gallery", i);
                              }}
                            >
                              x
                            </CButton>
                            <img
                              height="220"
                              src={URL.createObjectURL(gallery_image)}
                              alt="item"
                            />
                          </div>
                        ))}
                    </div>
                  </CRow>
                </CCol>
              </CCardBody>
            </CCard>
            <CRow>
              <CCol xs="12" md="6">
                <CFormLabel>Booking Status</CFormLabel>
                <Select
                  options={statusOptions}
                  onChange={(item) => {
                    setBooking({
                      ...booking,
                      status: item?.value,
                    });
                  }}
                  value={statusOptions.filter(
                    (status) => status.value === booking?.status
                  )}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.label}
                  isSearchable
                  placeholder="Select Booking Status"
                />
              </CCol>
              <CCol xs="12" md="6">
                <CFormLabel>Booking Time</CFormLabel>
                <CFormInput
                  type="datetime-local"
                  value={moment.utc(booking?.time).format("YYYY-MM-DDTHH:mm")}
                  disabled
                />
              </CCol>
              <CCol xs="12" md="6">
                <CFormLabel>Total Fees</CFormLabel>
                <CFormInput type="text" value={totalFees} disabled />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol xs="12" className="my-3">
        <CCard>
          <CCardHeader>
            <CRow className="align-items-center">
              <CCol>Booking Location</CCol>
              <CCol xs="auto">
                <CButton
                  onClick={() => {
                    let tmpLocation = booking?.location;
                    tmpLocation.push({});
                    setBooking({
                      ...booking,
                      location: tmpLocation,
                    });
                  }}
                  variant="ghost"
                  size="sm"
                  color="primary"
                ></CButton>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12">
                <CFormLabel>Locations</CFormLabel>
                <Select
                  options={doctor.locations}
                  isDisabled
                  value={doctor.locations.find(
                    (loc) => +loc.id === +booking?.location.id
                  )}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) =>
                    `${option.street} ${option.area.name}`
                  }
                  placeholder="Select Location"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol xs="12" className="my-3">
        <CCard>
          <CCardHeader>
            <CRow className="align-items-center">
              <CCol>Booking Services</CCol>
              <CCol xs="auto">
                <CButton
                  onClick={() => {
                    let tmpServices = booking?.services;
                    tmpServices.push({ pivot: { quantity: 1 } });
                    setBooking({
                      ...booking,
                      services: tmpServices,
                    });
                  }}
                  variant="ghost"
                  size="sm"
                  color="primary"
                >
                  <CIcon icon={cilPlus} />
                </CButton>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <div className="text-danger">{errors?.no_services}</div>

              {booking?.services.map((service, i) => (
                <CCol xs="auto" key={i} className="mb-3">
                  <CCard>
                    <CCardHeader>
                      <CRow className="align-items-center">
                        <CCol>Service {i + 1}</CCol>
                        <CCol xs="auto">
                          <CButton
                            onClick={() => {
                              let tmpServices = booking?.services;
                              tmpServices.splice(i, 1);
                              setBooking({
                                ...booking,
                                services: tmpServices,
                              });
                            }}
                            variant="ghost"
                            size="sm"
                            color="danger"
                          >
                            <CIcon icon={cilMinus} />
                          </CButton>
                        </CCol>
                      </CRow>
                    </CCardHeader>
                    <CCardBody>
                      <Select
                        options={doctor.services}
                        onChange={(item) => {
                          let tmpServices = [...booking?.services];
                          tmpServices[i] = {
                            ...item,
                            service_id: item && item.id,
                          };
                          setBooking({
                            ...booking,
                            services: tmpServices,
                          });
                        }}
                        value={service && service}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        isSearchable
                        placeholder="Select a service"
                      />

                      <CFormLabel>Fees</CFormLabel>
                      <CFormInput
                        type="number"
                        min={0}
                        placeholder="fees"
                        value={service.pivot?.fees}
                        disabled
                      />

                      <CFormLabel>Package</CFormLabel>
                      <CFormInput
                        type="number"
                        min={0}
                        placeholder="package"
                        value={service.pivot?.package}
                        disabled
                      />

                      <CFormLabel>Quantity</CFormLabel>
                      <CFormInput
                        type="number"
                        min={0}
                        placeholder="quantity"
                        value={service.pivot?.quantity}
                        onChange={(e) => {
                          let tmpServices = [...booking?.services];
                          tmpServices[i] = {
                            ...tmpServices[i],
                            pivot: {
                              ...service.pivot,
                              quantity: e.target.value,
                            },
                          };
                          setBooking({
                            ...booking,
                            services: tmpServices,
                          });
                        }}
                      />
                    </CCardBody>
                  </CCard>
                </CCol>
              ))}
            </CRow>
          </CCardBody>
        </CCard>
        {booking?.images && (
          <CCard className="my-4">
            <CCardHeader>Images</CCardHeader>
            <CCardBody>
              <CRow>
                <CCol lg="12">
                  {booking?.images?.map(() => {
                    return (
                      <CImage
                        style={{ cursor: "zoom-in" }}
                        onClick={() =>
                          openInNewTab(
                            "https://test-api-dentist.staging-apps.com/storage/services/ng1FR5PHrhwxru7OPEvNWO5muXbOHat90qNhTQyX.png"
                          )
                        }
                        src="https://test-api-dentist.staging-apps.com/storage/services/ng1FR5PHrhwxru7OPEvNWO5muXbOHat90qNhTQyX.png"
                      />
                    );
                  })}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        )}

        {booking?.patient_history && (
          <CCard>
            <CCardHeader>Patient history</CCardHeader>
            <CCardBody>
              <div className="h-full w-full flex-row grid bg-error">
                {booking.patient_history.map((object, index) => {
                  return (
                    <div className="d-flex">
                      <div
                        style={{ width: "45%" }}
                        className="d-flex flex-col gap-2 m-3"
                      >
                        {index + 1} :
                        <div>
                          <div className="d-flex">
                            <b>Doctor note : </b>
                            {object?.provider_notes?.text}
                          </div>
                          <div className="d-flex">
                            {object?.provider_notes?.teeth?.map(
                              (tooth, index) => {
                                return (
                                  <div>
                                    - <b>Tooth :</b>
                                    {tooth.value} <b>Tooth note :</b>
                                    {tooth.note}
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div className="d-flex">
                            <div>
                              <b>Date : </b>
                              {moment(object?.time.toString())
                                .format("DD/MM/YYYY")
                                .toString()}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ width: "45%" }}>
                        {object?.gallery?.length > 0 && (
                          <div>
                            <span className="bold">Image(s) :</span>
                            <div
                              className="d-flex flex-row w-100"
                              style={{ overflowX: "scroll" }}
                            >
                              {object?.gallery?.map((image) => {
                                return (
                                  <img
                                    onClick={() => {
                                      window.open(
                                        image?.original_url,
                                        "_blank",
                                        "noopener,noreferrer"
                                      );
                                    }}
                                    src={image?.original_url}
                                    style={{
                                      cursor: "pointer",
                                      width: "200px",
                                      height: "200px",
                                      display: "flex",
                                    }}
                                    className="m-2 icon-img-size border rounded"
                                  />
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </CCardBody>
          </CCard>
        )}
      </CCol>
    </CRow>
  );
}

let adultTeeth = [
  "",
  "UPPER RIGHT THIRD MOLAR",
  "UPPER RIGHT SECOND MOLAR",
  "UPPER RIGHT FIRST MOLAR",
  "UPPER RIGHT SECOND PREMOLAR",
  "UPPER RIGHT FIRST PREMOLAR",
  "UPPER RIGHT CANINE",
  "UPPER RIGHT LATERAL",
  "UPPER RIGHT CENTRAL",
  "UPPER LEFTCENTRAL",
  "UPPER LEFT LATERAL",
  "UPPER LEFT CANINE",
  "UPPER LEFT FIRSTPREMOLAR",
  "UPPER LEFT SECOND PREMOLAR",
  "UPPER LEFTFIRST MOLAR",
  "UPPER LEFT SECOND MOLAR",
  "UPPER LEFT THIRD MOLAR",
  "LOWER LEFT THIRD MOLAR",
  "LOWER LEFT SECOND MOLAR",
  "LOWER LEFT FIRST MOLAR",
  "LOWER LEFT SECOND PREMOLAR",
  "LOWER LEFT FIRST PREMOLAR",
  "LOWER LEFT CANINE",
  "LOWER LEFT LATERAL",
  "LOWERLEFT CENTRAL",
  "LOWER RIGHTCENTRAL",
  "LOWER RIGHTLATERAL",
  "LOWER RIGHT CANINE",
  "LOWER RIGHTFIRST PREMOLAR",
  "LOWER RIGHTSECONDPREMOLAR",
  "LOWER RIGHT FIRST MOLAR",
  "LOWER RIGHT SECONDMOLAR",
  "LOWER RIGHT THIRDMOLAR",
];
let childTeeth = [
  "",
  "A (UPPER RIGHT SECOND MOLAR)",
  "B (UPPER RIGHT FIRST MOLAR)",
  "C (UPPER RIGHT CANINE)",
  "D (UPPER RIGHT LATERAL)",
  "E (UPPER RIGHT CENTRAL)",
  "F (UPPER LEFT CENTRAL)",
  "G (UPPER LEFT LATERAL)",
  "H (UPPER LEFT CANINE)",
  "I (UPPER LEFT FIRST MOLAR)",
  "J (UPPER LEFT SECOND MOLAR)",
  "K (LOWER LEFT SECOND MOLAR)",
  "L (LOWER LEFT FIRST MOLAR)",
  "M (LOWER LEFT CANINE)",
  "M (LOWER LEFT LATERAL)",
  "N (LOWER LEFT CENTRAL)",
  "P (LOWER RIGHT CENTRAL)",
  "Q (LOWER RIGHT  LATERAL)",
  "R (LOWER RIGHT CANINE)",
  "S (LOWER RIGHT FIRST MOLAR)",
  "T (LOWER RIGHT SECOND MOLAR)",
];
let childOptions = [];
let adultOptions = [];
for (let i = 1; i < childTeeth.length; i++) {
  childOptions.push({ value: childTeeth[i], label: childTeeth[i] });
}
for (let i = 1; i < adultTeeth.length; i++) {
  adultOptions.push({
    value: i + " " + adultTeeth[i],
    label: i + " (" + adultTeeth[i] + ")",
  });
}

export const groupedOptions = [
  {
    label: "Adult",
    options: adultOptions,
  },
  {
    label: "Child",
    options: childOptions,
  },
];
