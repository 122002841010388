import React from "react";
import { useSelector } from "react-redux";

export default function LocationInfo({ data }) {
  const { cities } = useSelector((state) => state.cities);
  let foundAreas = [];

  return (
    <span>
      {data?.locations?.length > 0 ? (
        data?.locations?.length === 1 ? (
          <>
            {data.locations[0]?.street}
            {", "}
            {data.locations[0]?.street_2}
            {", "}
            {cities.map((city) => {
              let foundArea = city?.areas?.find(
                (area) => area?.id === parseInt(data?.locations[0]?.area_id)
              );
              return foundArea?.name;
            })}
          </>
        ) : (
          <>
            {cities?.forEach((city) => {
              city.areas.forEach((area) => {
                data?.locations.forEach((location) => {
                  if (area.id === parseInt(location.area_id)) {
                    foundAreas.push(area);
                  }
                });
              });
            })}
            {foundAreas?.slice(0, 2).map((s, i) => (
              <span key={i}>
                {i > 0 && <span className="mx-1">,</span>}
                {s.name}
              </span>
            ))}
            {data?.locations?.length > 2 && (
              <span className="mx-1"> +{data.locations.length - 2}</span>
            )}
          </>
        )
      ) : (
        "No Locations"
      )}
    </span>
  );
}
