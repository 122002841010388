import { Card, Col } from "react-bootstrap";

import { Link } from "react-router-dom";
import LocationInfo from "./../SingleDoctor/doc-info/LocationInfo";
import { useContext, useState } from "react";
import { langContext } from "../../context/LangProvider";

export default function Lab({ lab }) {
  const [image, setImage] = useState("assets/arrow-circle-right-sml.svg");
  console.log(lab);
  const { t } = useContext(langContext);
  return (
    <Col sm={12} md={6} lg={4}>
      <Card className="spec-card offersPage card-shadow">
        <div className="img-cont">
          <Card.Img
            className="top-img"
            src={lab?.logo?.original_url || "/assets/pattern.svg"}
            alt=""
          />
        </div>
        <Card.Body className="scan-card-body">
          <Card.Title className="mb-4 bold">{lab.name}</Card.Title>
          <div className="my-1">
            <img
              src="assets/location.svg"
              alt=""
              className="icon-img-size pb-1"
            />
            <LocationInfo data={lab} />
          </div>
          <div className="my-1 d-flex">
            <img src="/assets/star.svg" alt="" className="icon-img-size" />
            <span className="ms-2 bold">4.5</span>
            <span className="text-muted ms-1">(110 {t("reviews")})</span>
          </div>
          <div className="mt-4">
            <Link
              to={`/singlelab/${lab.id}`}
              className="d-flex justify-content-between bold"
            >
              <button
                className="pop-up2"
                onMouseLeave={() =>
                  setImage("/assets/arrow-circle-right-sml.svg")
                }
                onMouseOver={() =>
                  setImage("/assets/arrow-circle-right-sml2.svg")
                }
              >
                {t("View all Working Hours")}
                <img src={image} alt="" className="icon-img-size" />
              </button>
            </Link>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}
