import { clearCart } from "../../../redux/cart";
import { useDispatch } from "react-redux";

export default function Confirmation({ handleConfirm }) {
  const dispatch = useDispatch();
  return (
    <>
      <div>
        <div className="text-center" style={{ fontSize: "1.2rem" }}>
          <div>There are existing services from other provider .</div>
          Do you want to Remove them first?
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <button
          className="pop-up mx-3 my-3"
          style={{ padding: "0.5rem 1rem", borderRadius: "0.4rem" }}
          onClick={() => {
            dispatch(clearCart());
            handleConfirm(false);
          }}
        >
          Remove Services
        </button>
        <button
          className="pop-up mx-3 my-3"
          style={{ padding: "0.5rem 1rem", borderRadius: "0.4rem" }}
          onClick={() => handleConfirm(false)}
        >
          Close
        </button>
      </div>
    </>
  );
}
