export default function getTotalFees(services, examination_fee) {
  let totalFees = 0;
  services?.map((service) => {
    if (service.pivot.quantity > 1) {
      totalFees += service.pivot.quantity * service.pivot.package;
    } else {
      totalFees += +service.pivot.fees;
    }
  });
  totalFees += +examination_fee;
  return totalFees;
}

export function getTotalFeesAfterDiscount(services, examination_fee, discount) {
  let totalFees = 0;
  if (services) {
    services?.map((service) => {
      if (service?.pivot?.quantity > 1) {
        totalFees += +service?.pivot?.quantity * service?.pivot?.package;
      } else if (service.pivot.quantity == 1 || !service.pivot.quantity) {
        console.log("111");
        totalFees += +service?.pivot?.fees;
      }
    });
    totalFees += +examination_fee;

    totalFees = totalFees - discount;
  }
  console.log("getTotalFeesAfterDiscount", totalFees, services);
  return totalFees;
}
