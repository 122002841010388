import { createSlice } from "@reduxjs/toolkit";

const searchParamsSlice = createSlice({
  name: "searchParams",
  initialState: {
    searchParams: {
      services: [],
      areas: [],
      cities: [],
      date: [null, null],
    },
  },
  reducers: {
    setSearchParams: (state, action) => {
      state.searchParams = action.payload;
    },
    setDateRange: (state, action) => {
      state.searchParams.date = action.payload;
    },
    clearParams: (state, action) => {
      console.log("fire", action.payload);
      state.searchParams.date = action.payload;
    },
  },
});
export default searchParamsSlice.reducer;
export const { setSearchParams, setDateRange, clearParams } =
  searchParamsSlice.actions;
