import { useContext } from "react";
import { Modal } from "react-bootstrap";
import { langContext } from "../../../context/LangProvider";

export default function CancelAppointmentModal(props) {
  const { t } = useContext(langContext);
  return (
    <Modal
      {...props}
      size="md"
      className="doc-services"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("Cancel Appointment")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{t("Are you sure you want to cancel this appointment")}</h5>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="pop-up"
          style={{ padding: "0.5rem 1rem", borderRadius: "0.4rem" }}
          onClick={props.cancelAppointmentHandler}
        >
          {t("cancel")}
        </button>
        <button
          className="pop-up"
          style={{ padding: "0.5rem 1rem", borderRadius: "0.4rem" }}
          onClick={props.onHide}
        >
          {t("Close")}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
