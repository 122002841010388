import { useContext } from "react";
import { Accordion } from "react-bootstrap";
import { langContext } from "../../../../context/LangProvider";
import CheckBoxItem from "../CheckBoxItem";

export default function Entity({ selectedEntities, setSelectedEntities }) {
  const handleChangeEntity = (e) => {
    if (e.target.checked) {
      setSelectedEntities([...selectedEntities, e.target.value]);
    } else {
      setSelectedEntities(
        selectedEntities.filter((ser) => ser !== e.target.value)
      );
    }
  };
  const { t } = useContext(langContext);

  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>{t("Entity")}</Accordion.Header>
        <Accordion.Body>
          <CheckBoxItem
            checkBoxToggle={handleChangeEntity}
            label={t("Hospital")}
            value="hospital"
          />
          <CheckBoxItem
            checkBoxToggle={handleChangeEntity}
            label={t("Clinic")}
            value="clinic"
          />
          <CheckBoxItem
            checkBoxToggle={handleChangeEntity}
            label={t("Home Visit")}
            value="home-visit"
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
