import { Col, Row } from "react-bootstrap";

export default function AboutSection({ text, reversed, image }) {
  console.log(reversed);
  console.log('testttt: ', text);
  return (
    <Row className={`py-2 about-section ${reversed ? "rev" : ""}`}>
      <Col xs={12} lg={6}>
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
      </Col>
      <Col xs={12} lg={6}>
        <img src={image} alt="Who We Are" />
      </Col>
    </Row>
  );
}
