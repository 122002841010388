import { useEffect, useState } from "react";

import AppointDay from "./AppointDay";
import { Col } from "react-bootstrap";
import Slider from "react-slick";

export default function DaysSlider({ days, setViewdAppointmentsHandler }) {
  const [chosenDay, setChosenDay] = useState("");
  const [viewddays, setViewdDays] = useState([]);
  useEffect(() => {
    let openDays = days?.filter((day) => !day.closed);
    if (openDays?.length) {
      setViewdDays(openDays);
      setChosenDay(openDays[0].day);
    } else {
      setViewdDays([{ day: "closed", date: "" }]);
    }
  }, [days]);
  const settings = {
    speed: 500,
    slidesToShow: viewddays.length >= 3 ? 3 : viewddays.length,
    slidesToScroll: 1,
    nextArrow: <img src="/assets/arrow-right-lrg.svg" alt="" />,
    prevArrow: <img src="/assets/arrow-left-lrg.svg" alt="" />,
  };

  return (
    <Col sm={12}>
      <Slider {...settings} className="mySlider">
        {viewddays?.map((appoint, i) => (
          <AppointDay
            key={i}
            index={i}
            appoint={appoint}
            activeDay={appoint?.day === chosenDay}
            selectAppointments={() => {
              setViewdAppointmentsHandler(appoint);
              setChosenDay(appoint.day);
            }}
          />
        ))}
      </Slider>
    </Col>
  );
}
