import { createSlice } from "@reduxjs/toolkit";

const reviewsSlice = createSlice({
  name: "reviews",
  initialState: {
    reviews: [],
    status: false,
  },
  reducers: {
    getReviews: (state, action) => {
      state.reviews = action.payload.map((doc) => doc.reviews).flat();
      state.status = "success";
    },
  },
});
export default reviewsSlice.reducer;
export const { getReviews } = reviewsSlice.actions;
