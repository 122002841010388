import { cilCloudUpload, cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CCard, CCardBody, CCardFooter, CCardHeader, CCardImage, CCardSubtitle, CCardTitle, CCol, CFormInput, CRow } from "@coreui/react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { getServices } from "../../../redux/services";
import { servicesService } from "../../../services/servicesSerivce";
function SerivceEdit({ services }) {

    const navigate = useNavigate()
    const { id } = useParams();
    const [service, setService] = useState();
    const dispatch = useDispatch()
    const { services: allServices } = useSelector((state) => state.services);
    const [availableServices, setAvailableServices] = useState([])

    useEffect(() => {
        dispatch(getServices())
        let temp = []
        allServices.map((srv, j) => {
            if (!services.some((obj) => obj?.id == srv?.id)) {
                temp.push({ ...srv, description: srv?.description, image_url: srv?.image_url, id: srv?.id, label: srv?.name, value: srv?.id })
            }
        })
        setAvailableServices(temp)
    }, [services])
    useEffect(() => {
        let temp = services?.find((srv) => { return srv?.id == id })
        setService(temp)
    }, [services])
    function insertHandler() {
        let temp = []
        if (id == 'create') { // creating new service
            temp = services.map((srv) => { return { service_id: srv.id, fees: srv.pivot.fees, package: srv.pivot.package } });
            let tempService = { service_id: service?.id, package: service.pivot.package, fees: service.pivot.fees }
            temp.push(tempService)
        }
        else if (id) { // if there's id paramater in url , means we're updating the service 
            temp = services.map((srv) => {
                if (srv.id == id) { 
                    return {
                        service_id: service.id, fees: service.pivot.fees, package: service.pivot.package
                    }
                }
                else {
                    return { service_id: srv.id, fees: srv.pivot.fees, package: srv.pivot.package }
                }
            });
        }
        servicesService.updateServices(temp)

        window.location.reload();
        navigate(-1)
    }
    function deleteHandler() { 
        let temp = services.filter((obj) => obj.id != id).map((srv) => { return { service_id: srv.id, fees: srv.pivot.fees, package: srv.pivot.package } })
        servicesService.updateServices(temp)
        navigate(-1)
    }
    return (
        <div>
            <CCard>
                <CCardHeader>Service</CCardHeader>

                <CCardBody className="d-flex justify-content-center align-items-start flex-column">
                    {service && <CRow className="w-100 d-flex justify-content-between align-items-center" >
                        <CCol>
                            {id !== 'create' ? <CButton onClick={insertHandler} className="bg-success">Update <CIcon icon={cilCloudUpload} /></CButton> : <CButton onClick={insertHandler} className="bg-success">Insert <CIcon icon={cilCloudUpload} /></CButton>}
                        </CCol>
                        <CCol>
                            {id !== 'create' && <CButton onClick={deleteHandler} className="bg-danger">Delete  <CIcon icon={cilTrash} /></CButton>}
                        </CCol>
                    </CRow>}
                    <CRow className="m-2 w-100">
                        {id == 'create' && <Select onChange={(newValue) => {
                            let temp = availableServices.find((srv) => newValue?.id == srv?.id)
                            setService(temp)
                        }} value={service ? availableServices?.find((srv) => { return srv?.id === service.id }) : { label: "Choose a service", value: 'Choose one' }} options={availableServices} />}
                    </CRow>
                    {service && <CRow className="d-flex m-1 w-100">
                        <CCol lg="4" md="6">
                            <CCard className="m-1">
                                <CCardHeader>Image</CCardHeader>
                                <CCardBody>
                                    {service?.image_url ? <CCardImage src={service?.image_url} className={"w-100"} /> : <CCardSubtitle>No image available</CCardSubtitle>}
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol lg="4" md="6" className=" justify-content-center flex-column d-flex">
                            <CCard className="m-1">
                                <CCardHeader>Name</CCardHeader>
                                <CCardBody>
                                    <CCardSubtitle>{service?.name} </CCardSubtitle>
                                </CCardBody>
                            </CCard>
                            <CCard className="m-1">
                                <CCardHeader>Package</CCardHeader>
                                <CCardBody>
                                    <CFormInput placeholder="package" value={service?.pivot?.package} onChange={(e) => {
                                        setService({ ...service, pivot: { ...service?.pivot, package: e.target.value } })
                                    }} type="number" />
                                    {/* <CCardSubtitle>{service?.pivot?.package} </CCardSubtitle> */}
                                </CCardBody>
                            </CCard>
                            <CCard className="m-1">
                                <CCardHeader>Fees</CCardHeader>
                                <CCardBody>
                                    <CFormInput placeholder="fees" value={service?.pivot?.fees} onChange={(e) => {
                                        setService({ ...service, pivot: { ...service?.pivot, fees: e.target.value } })
                                    }} type="number" />
                                    {/* <CCardSubtitle>{service?.pivot?.fees}</CCardSubtitle> */}
                                </CCardBody>
                            </CCard>
                            <CCard className="m-1">
                                <CCardHeader>Description</CCardHeader>
                                <CCardBody>
                                    <CCardSubtitle>{service?.description} </CCardSubtitle>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>}
                </CCardBody>
            </CCard>
        </div >)
}
export default SerivceEdit;