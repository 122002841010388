import {
  CImage,
  CNavTitle,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react";
import { Link, useNavigate } from "react-router-dom";
import {
  changeDashboardNarrow,
  changeDashboardVisibilty,
} from "../../redux/dashboard";
import {
  cilAlbum,
  cilCalendar,
  cilLocationPin,
  cilUser,
  cilCommentBubble,
  cilMoney,
  cilAccountLogout,
  cilApplicationsSettings,
  cilSettings,
} from "@coreui/icons";
import { useDispatch, useSelector } from "react-redux";

import CIcon from "@coreui/icons-react";
import { getPages } from "../../redux/pages";
import { memo } from "react";
import { logout } from "../../redux/auth";
import UserService from "../../services/userService";

const SideBar = () => {
  const { visible, narrow } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateToHome = async () => {
    await dispatch(getPages("/home"));
    navigate("/");
  };
  return (
    <CSidebar
      position="fixed"
      visible={visible}
      onVisibleChange={(val) => {
        dispatch(changeDashboardVisibilty(val));
      }}
      narrow={narrow}
      style={{ height: "100vh" }}
    >
      <CSidebarBrand onClick={navigateToHome}>
        {narrow ? (
          <CImage
            height={40}
            src={"/assets/logo.svg"}
            className="c-sidebar-brand-minimized"
            alt="Logo"
          />
        ) : (
          <CImage
            width="75%"
            src={"/assets/logo-light.png"}
            className="c-sidebar-brand-full pt-3 pb-3"
            alt="Logo"
          />
        )}
      </CSidebarBrand>
      <CSidebarNav>
        <CNavTitle>Doctor's Dashboard</CNavTitle>
        <li className="c-sidebar-nav-item">
          <Link className="nav-link" to="/dashboard/bookings">
            <CIcon customClassName="nav-icon" icon={cilCalendar} />
            Bookings
          </Link>
        </li>
        <li className="c-sidebar-nav-item">
          <Link className="nav-link" to="/dashboard/locations">
            <CIcon customClassName="nav-icon" icon={cilLocationPin} />
            Locations
          </Link>
        </li>
        <li className="c-sidebar-nav-item">
          <Link className="nav-link" to="/dashboard/profile">
            <CIcon customClassName="nav-icon" icon={cilUser} />
            Profile
          </Link>
        </li>
        <li className="c-sidebar-nav-item">
          <Link className="nav-link" to="/dashboard/services">
            <CIcon customClassName="nav-icon" icon={cilAlbum} />
            Services
          </Link>
        </li>
        {UserService.checkUserRole("Doctor") && (
          <li className="c-sidebar-nav-item">
            <Link className="nav-link" to="/dashboard/faqs">
              <CIcon customClassName="nav-icon" icon={cilCommentBubble} />
              <i className="cis-comment-bubble-question"></i>
              FAQs
            </Link>
          </li>
        )}
        {UserService.checkUserRole("Doctor") && (
          <li className="c-sidebar-nav-item">
            <Link className="nav-link" to="/dashboard/offers">
              <CIcon customClassName="nav-icon" icon={cilMoney} />
              <i className="cis-comment-bubble-question"></i>
              Offers
            </Link>
          </li>
        )}
        <li className="c-sidebar-nav-item">
          <Link className="nav-link" to="/dashboard/security">
            <CIcon customClassName="nav-icon" icon={cilSettings} />
            <i className="cis-comment-bubble-question"></i>
            Password & Security
          </Link>
        </li>
        <li className="c-sidebar-nav-item">
          <Link onClick={() => dispatch(logout())} className="nav-link" to="/">
            <CIcon customClassName="nav-icon" icon={cilAccountLogout} />
            <i className="cis-comment-bubble-question"></i>
            Logout
          </Link>
        </li>
      </CSidebarNav>
      <CSidebarToggler onClick={() => dispatch(changeDashboardNarrow())} />
    </CSidebar>
  );
};
export default memo(SideBar);
