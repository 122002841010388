import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import { setDateRange } from "../../redux/search";
import { t } from "i18next";

export default function DateSelection({ selectDate, error }) {
  const { searchParams } = useSelector((state) => state.searchParams);
  const dispatch = useDispatch();
  console.log("searchParams.date", searchParams.date);
  const [startDate, endDate] = searchParams.date;
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    selectDate(searchParams.date);
  }, [searchParams.date]);

  console.log(startDate);
  return (
    <div className="search-select-box" onClick={() => setIsOpen(true)}>
      <img className="icon" src="/assets/booking-date.svg" alt="" />
      <img className="arrow" src="/assets/arrow-down.svg" alt="" />
      <DatePicker
        open={isOpen}
        onClickOutside={() => setIsOpen(false)}
        className="form-control date"
        placeholderText={t(`Select Date`)}
        selectsRange={true}
        dateFormat="dd/MM/yyyy"
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          console.log(update);
          dispatch(setDateRange(update));
        }}
        // withPortal
      />
      {error !== "" && <small className="error">{error}</small>}
    </div>
  );
}
