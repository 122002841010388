import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BookingEdit from "../components/dashboard/BookingEdit";
import { CButton } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import Header from "../components/dashboard/Header";
import SideBar from "../components/dashboard/SideBar";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { cilCloudUpload } from "@coreui/icons";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { appendItem } from "../Helpers/appendItem";

export default function DashboardBookingsEdit() {
  const { id } = useParams(),
    { visible, narrow } = useSelector((state) => state.dashboard),
    [errors, setErrors] = useState({}),
    [loading, setLoading] = useState(true),
    [updating, setUpdating] = useState(false),
    navigate = useNavigate(),
    [booking, setBooking] = useState(null),
    updateBooking = async () => {
      console.log(booking);
      if (!booking.services?.length) {
        setErrors({
          ...errors,
          no_services: "Please add at least one service",
        });
        toast.error("The given data was invalid");
        return;
      }
      setUpdating(true);
      try {
        const bookingData = {
          ...booking,
          services: booking.services.map((s) => s.pivot),
          gallery:
            booking?.gallery?.length > 0
              ? booking.gallery.map((p) => p.id)
              : [""],
          new_gallery:
            booking?.new_gallery?.length > 0 ? booking.new_gallery : [""],
        };

        let formData = new FormData();
        Object.keys(bookingData).forEach((key) => {
          appendItem(formData, key, bookingData[key]);
        });
        console.log(bookingData);
        await axios.post(
          process.env.REACT_APP_API_URL +
            `/doctor_dashboard/appointments/${id}`,
          formData,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        toast.success("Updated Successfully");
      } catch (error) {
        setErrors(error.response.data.errors);
        toast.error("The given data was invalid");
      } finally {
        setUpdating(false);
      }
    },
    {
      token,
      doctor,
      doctorStatus,
      status: userStatus,
    } = useSelector((state) => state.user);
  useEffect(() => {
    getHistory();
  }, []);
  useEffect(() => {
    if (userStatus === "success" && doctorStatus === "success") {
      if (!doctor) {
        navigate("/");
        return;
      }
      getHistory();
      getBookings();
      // getHistory()
    }
  }, [userStatus, doctorStatus, doctor]);
  async function getHistory() {
    let res = await axios.get(
      `${process.env.REACT_APP_API_URL}/doctor_dashboard/appointments/${id}`,
      {
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    setBooking(res?.data?.data);
    console.log("histooooooooory", res.data.data);
  }
  async function getBookings() {
    try {
      let res = await axios(
        process.env.REACT_APP_API_URL + `/doctor_dashboard/appointments`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      // setBooking(res.data.data.find((booking) => +booking.id === +id));
    } catch (error) {
      setErrors(error.errors);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }
  return (
    <div className="" style={{ minHeight: "100vh" }}>
      <SideBar />
      <div
        className="dashboard-container"
        style={
          window.innerWidth > 768 && visible
            ? narrow
              ? { marginLeft: "65px" }
              : { marginLeft: "256px" }
            : { marginLeft: "0" }
        }
      >
        <Header />
        {!loading ? (
          <div className="dashboard-wrapper">
            <BookingEdit
              booking={booking}
              setBooking={setBooking}
              doctor={doctor}
              errors={errors}
            />
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" />
          </div>
        )}
        <CButton
          onClick={updateBooking}
          type="submit"
          size="lg"
          color="success"
          disabled={updating}
          style={{ width: "fit-content", alignSelf: "center", color: "white" }}
        >
          Update <CIcon icon={cilCloudUpload} />
        </CButton>
      </div>
    </div>
  );
}
