import { Container } from "react-bootstrap";
import SignUp from "../components/auth/signup";

export default function SignUpPage() {
  return (
    <div className="">
      <div className="back-auth p-top-100 ">
        <Container>
          <SignUp />
        </Container>
      </div>
    </div>
  );
}
