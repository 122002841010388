import { useEffect } from "react";
import { useState } from "react";
import { createContext } from "react";
import { useTranslation } from "react-i18next";
export const langContext = createContext(null);

export const LangProvider = ({ children }) => {
  const [t, i18n] = useTranslation();
  const [lang, setlang] = useState("en");

  useEffect(() => {
    document.getElementsByTagName("html")[0].setAttribute("lang", lang);
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <langContext.Provider value={{ lang, setlang, t }}>
      {children}
    </langContext.Provider>
  );
};
