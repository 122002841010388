import { Form, Spinner } from "react-bootstrap";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AiFillUnlock } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";

export default function ResetPassword() {
  const [reseting, setResting] = useState(false);
  const [resetingText, setResetingText] = useState("Reset");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  let token = searchParams.get("token");
  let email = searchParams.get("email");
  const resetHandler = async (e) => {
    e.preventDefault();
    setResting(true);
    setResetingText(<Spinner animation="border" size="sm" />);
    setPasswordError("");
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_AUTH_URL}/password/reset`,
        {
          password,
          password_confirmation: confirmPassword,
          email,
          token,
        }
      );
      console.log(res);
      toast.success("Password Reseted Successfully");
      navigate("/login");
    } catch (err) {
      console.log(err.response.data);
      if (err.response.data) toast.error("reset password Failed");
      if (err.response.data.errors)
        setPasswordError(err.response.data.errors.password);
    } finally {
      setResting(false);
      setResetingText("Reset");
    }
  };
  return (
    <div className="auth-cont forgot">
      <div className="text-center">
        <AiFillUnlock size={150} color="#a99156" />
        <div style={{ fontSize: "1.2rem" }}>
          Please enter your new password.
        </div>
      </div>

      <div>
        <Form onSubmit={resetHandler}>
          <Form.Group
            className="mt-2 mb-1 d-flex justify-content-between align-items-center"
            controlId="password"
          >
            <Form.Label>Password</Form.Label>
            <Form.Control
              required
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
          </Form.Group>
          <Form.Group
            className="mt-2 mb-1 d-flex justify-content-between align-items-center"
            controlId="c-password"
          >
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              required
              name="c-password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm Password"
            />
          </Form.Group>
          <small className="text-danger">{passwordError}</small>
          <div className="d-flex justify-content-center align-items-center mt-4 pb-4">
            <button
              disabled={reseting}
              className="pop-up login"
              style={reseting ? { opacity: 0.5 } : { opacity: 1 }}
            >
              {resetingText}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
}
