import { useEffect, useState } from "react";

import { Form } from "react-bootstrap";
import Slider from "react-slick";

export default function SearchComp({
  data,
  placehold,
  loading,
  highlighter,
  all,
  allToggle,
  selectAll,
  selections,
}) {
  const [viewdData, setViewdData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [serviceSelection, setServiceSelection] = useState([]);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  //---------function devides any array into arrays of 6 elements
  const slideDevider = (data) => {
    let j = 0;
    let tmpViewdData = [];
    for (let i = 0; i < data.length; i += 20) {
      const everyCollection = data.slice(i, 20 + i);
      tmpViewdData[j] = everyCollection;
      j++;
    }
    setViewdData(tmpViewdData);
  };
  useEffect(() => {
    slideDevider(data);
  }, [data]);
  const showMenu = () => {
    setShow(true);
  };
  const hideMenu = () => {
    setShow(false);
    setPage(1);
  };
  useEffect(() => {
    setServiceSelection(selections);
  }, [selections]);

  const makeSelection = (item) => {
    highlighter(item, data);
    setSearchValue("");
  };

  const searchHandler = (e) => {
    let tmpSearchValue = e.target.value;
    setSearchValue(tmpSearchValue);
    const filteredSearch = data.filter((s) =>
      s.name.toLowerCase().startsWith(tmpSearchValue.toLowerCase())
    );
    slideDevider(filteredSearch);
  };
  const settings = {
    beforeChange: (current, next) => setPage(next + 1),
    arrows: true,
    speed: 500,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <img src="assets/arrow-right-lrg.svg" alt="" />,
    prevArrow: <img src="assets/arrow-left-lrg.svg" alt="" />,
  };
  return (
    <div className="search-select-box">
      {show && <div className="over-lay-click" onClick={hideMenu}></div>}
      {/* to show overlay only when menu is open */}
      <div onClick={showMenu}>
        <img className="icon" src="assets/search-sml.svg" alt="" />
        <img className="arrow" src="assets/arrow-down.svg" alt="" />
        <div className="selection-box">
          {serviceSelection.slice(0, 1).map((ser, i) => (
            <span key={i}>
              {ser.name.length > 20
                ? ser.name.substring(0, 20) + ".."
                : ser.name}
            </span>
          ))}
          {serviceSelection.length > 1 && (
            <span>+ {serviceSelection.length - 1}</span>
          )}
        </div>
        <Form.Group>
          <Form.Control
            value={searchValue}
            placeholder={serviceSelection.length > 0 ? "" : placehold}
            type="text"
            onChange={searchHandler}
          />
        </Form.Group>
      </div>
      <div
        className="menu"
        style={show ? { display: "block" } : { display: "none " }}
      >
        <h6
          onClick={selectAll}
          className={`${allToggle ? "all-selected" : ""} text-center`}
          style={{ padding: "5px", cursor: "pointer" }}
        >
          {all}
        </h6>
        {viewdData.length === 0 ? (
          <h6 className="text-center">{loading}</h6>
        ) : (
          <div className="menu__body">
            <Slider {...settings} className="mySlider">
              {viewdData.map((collection, i) => (
                <div
                  key={i}
                  className="d-flex justify-content-between flex-wrap slide"
                >
                  {collection.map((collectionItem) => (
                    <span
                      style={{ cursor: "pointer" }}
                      key={collectionItem.id}
                      onClick={() => makeSelection(collectionItem)}
                      className={
                        selections.includes(collectionItem) ? "active" : ""
                      }
                    >
                      {collectionItem.name}
                    </span>
                  ))}
                </div>
              ))}
            </Slider>
            <div className="page-num">
              <span>{page}</span>/<span>{viewdData.length}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
