import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import UserService from "../services/userService";

export const getLoginStatus = createAsyncThunk(
  "user/getLoginStatus",
  async () => {
    console.log("now");
    let response = await UserService.getMyProfile();
    return response;
  }
);

export const getLoggedInDoctor = createAsyncThunk(
  "user/getLoggedInDoctor",
  async () => {
    try {
      console.log("start async");
      let res = await UserService.getDoctorProfile();
      console.log("end async", res);
      return res;
    } catch (error) {
      console.log("end async", error.message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    profile: null,
    loginStatus: false,
    token: null,
    status: "loading",
    doctor: null,
    doctorStatus: "loading",
    loggedInDoctor: null,
  },
  reducers: {
    login: (state, action) => {
      state.loginStatus = true;
      state.user = action.payload.user;
      state.token = action.payload.token.access_token;
      UserService.login(action.payload.token.access_token);
    },
    logout: (state, action) => {
      state.loginStatus = false;
      state.user = null;
      state.profile = null;
      state.token = null;
      UserService.logout();
    },
    editProfile: (state, action) => {
      state.profile = action.payload.profile;
      state.user = action.payload;
    },
    setUserDoctor: (state, action) => {
      console.log("users", action.payload);
      state.doctor = action.payload;
    },
  },
  extraReducers: {
    [getLoggedInDoctor.fulfilled]: (state, action) => {
      console.log("doctor>>>", action.payload.user);
      state.loggedInDoctor = action.payload.user;
      state.doctor = action.payload.user;
    },
    [getLoggedInDoctor.pending]: (state, action) => {
      state.loggedInDoctor = "pending";
    },
    [getLoggedInDoctor.rejected]: (state, action) => {
      state.loggedInDoctor = "rejected";
      console.log("doctor>>>", state);
    },
    [getLoginStatus.pending]: (state) => {
      state.doctorStatus = "loading";
      state.status = "loading";
    },
    [getLoginStatus.fulfilled]: (state, action) => {
      state.loginStatus = action.payload.loginStatus;
      state.user = action.payload.user;
      state.profile = action.payload.profile;
      state.token = action.payload.token;
      state.status = "success";
      if (UserService.checkUserRole("Doctor")) {
        state.doctorStatus = "success";
        return;
      }
      if (UserService.checkUserRole("Lab")) {
        state.doctorStatus = "success";
        return;
      }
      state.doctorStatus = "failed";
    },
    [getLoginStatus.rejected]: (state) => {
      state.doctorStatus = "failed";
      state.status = "failed";
    },
  },
});
export default userSlice.reducer;
export const { login, editProfile, logout, setUserDoctor } = userSlice.actions;
