import { Col, Container, Row } from "react-bootstrap";

import Searchbar from "../searchbar/Searchbar";
import Slider from "react-slick";

export default function Hero({ slides, bgColor, searchBar_visibility }) {
  const settings = {
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "ease-in-out",
  };
  return (
    <div className="hero-wrapper">
      <Slider {...settings} className="hero-container">
        {slides.map((s, index) => (
          <div key={index}>
            <div
              className="hero-backGround"
              style={{
                backgroundImage: `url(${s?.image})`,
              }}
            >
              <Container>
                <Row className="gx-0 p-top-100">
                  <Col xs={12}>
                    <div
                      dangerouslySetInnerHTML={{ __html: `${s?.text}` }}
                      className="hero-text"
                    ></div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        ))}
      </Slider>
      <div className={`searchbar-box ${searchBar_visibility ? "" : "d-none"}`}>
        <Searchbar activeTab="appointment" />
      </div>
    </div>
  );
}
