//Layout

import "./navbar.css";
import "./footer.css";
import "./global.css";
import "./hero.css";
import "./offer.css";
import "./topServices.css";
import "./scanlabs.css";
import "./specialists.css";
import "./testo.css";
import "./successBooking.css";
import "./labs.css";
import "./offers.css";
import "./doctors.css";
import "./contact.css";
import "./about.css";
import "./auth.css";
import "./labinfo.css";
import "./services.css";
import "./searchbar.css";
import "./patient-profile.css";
import "./faq.css";
import "./sidefilter.css";
import "./doctorServices.css";
import "./reviews.css";
import "./gallery.css";
import "./docinfo.css";
import "./booking-card.css";
import "./dashboard.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "bootstrap/dist/js/bootstrap.bundle";
import "rc-rate/assets/index.css";
import "react-datepicker/dist/react-datepicker.css";
import "rc-slider/assets/index.css";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "react-toastify/dist/ReactToastify.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./coreui.scss";
import "./switch-button.css";

export default function CssImports() {
  return null;
}
