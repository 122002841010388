import { Card, Col, Row } from "react-bootstrap";
import { clearCart, setCurrentAppointment } from "../../../redux/cart";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";

import CancelAppointmentModal from "./CancelAppointmentModal";
import { Link } from "react-router-dom";
import Pill from "./Pill";
import Rate from "rc-rate";
import ReviewModal from "./ReviewModal";
import {
  cancelMyAppointment,
  cancelMyAppointments,
  getMyAppointments,
} from "../../../redux/myappointments";
import { getDoctors } from "../../../redux/doctors";
import { toast } from "react-toastify";
import AppointmentModal from "./AppointmentDetailsModal";
import { langContext } from "../../../context/LangProvider";

export default function AppointmentCard({ appointment, viewToggler }) {
  const [numOfStars, setNumOfStars] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const { doctors } = useSelector((state) => state.doctors);
  const { labs } = useSelector((state) => state.labs);
  const [provider, setProvider] = useState(null);
  const [providerType, setProviderType] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [showAppointmentDetails, setShowAppointmentDetails] = useState(false);
  const [showCancelAppointmentModal, setShowCancelAppointmentModal] =
    useState(false);
  const addDoctorRating = (value) => {
    setNumOfStars(value);
    setModalShow(true);
  };
  const { t } = useContext(langContext);
  useEffect(() => {
    if (appointment.provider_type.includes("Lab")) {
      setProviderType("lab");
      setProvider(
        labs.find((lab) => lab.id === parseInt(appointment.provider_id))
      );
    } else if (appointment.provider_type.includes("Doctor")) {
      setProviderType("doctor");
      setProvider(
        doctors.find((doc) => doc.id === parseInt(appointment.provider_id)) // ???????????
      );
    } else {
      setProviderType("user");
      setProvider("user");
    }
  }, [doctors]);

  const dispatch = useDispatch();
  const setAppointment = () => {
    dispatch(setCurrentAppointment(appointment));
    dispatch(clearCart());
    dispatch(getDoctors());
  };
  const setAppointmentWithConsultation = () => {
    if (appointment?.provider_notes?.meeting_id && appointment.status == 1) {
      // status=1 , means it's confirmed
      dispatch(setCurrentAppointment(appointment));
      dispatch(clearCart());
      dispatch(getDoctors());
      window.location.assign(
        `${process.env.REACT_APP_ZOOM_URL}/?meeting_id=${appointment.provider_notes.meeting_id}&meeting_password=${appointment.provider_notes.meeting_password}&signature=${appointment.zoom_signature}&user_name=${user.name}`
      );
    } else {
      if (appointment.status == 3) {
        toast.warning("This consultation sessions is completed.");
      } else if (appointment.status == 0) {
        toast.warning("This consultation sessions is waiting for approval.");
      } else if (appointment.status == 2) {
        toast.warning("This consultation sessions is has been cancelled.");
      } else if (!appointment?.provider_notes?.meeting_id) {
        toast.warning(
          "No meeting id has been set for this consulation session."
        );
      }
    }
  };
  const cancelAppointment = () => {
    setShowCancelAppointmentModal(true);
  };
  const cancelAppointmentHandler = () => {
    dispatch(cancelMyAppointments(appointment.id));
    setShowCancelAppointmentModal(false);
  };
  const cancelAppointmentDetails = () => {
    setShowAppointmentDetails(false);
  };
  return viewToggler === "Consultation" &&
    provider &&
    (providerType === "doctor" || providerType === "lab") ? (
    <Card className="custom-card card-shadow">
      <div className="card-row">
        {/* <Link
          onClick={setAppointment}
          to={
            providerType === "doctor"
              ? `/doctorProfile/${provider.id}/myAppointment`
              : `/singlelab/${provider.id}/myAppointment`
          }
          style={{ color: "black", textDecoration: "none" }}
        > */}
        <div
          onClick={() => {
            setAppointment();
            setShowAppointmentDetails(true);
          }}
          className="card-div"
        >
          <div>
            <img
              className="appointment-doctor mx-2"
              alt=""
              src={
                providerType === "doctor"
                  ? provider.profile_picture?.original_url ||
                    "/assets/noAvatar.png"
                  : provider.logo?.original_url || "/assets/pattern.svg"
              }
            />
          </div>
          <div>
            <div className="card-col-2-div">
              {providerType === "doctor" ? t("Doctor ") : "Lab "}{" "}
              <strong className="mx-1">{provider.name}</strong>
            </div>
            {providerType === "doctor" && (
              <div className="card-col-2-div">
                {t("Specialist for:")}
                <strong>{provider.speciality.name}</strong>
              </div>
            )}

            <div className="card-col-2-div">
              <img
                src="/assets/date.svg"
                alt="date-logo"
                style={{ marginRight: "0.5rem" }}
              />
              <strong>{`${new Date(appointment.time).getDay() + 1}/${new Date(
                appointment.time
              ).getMonth()}/${new Date(
                appointment.time
              ).getFullYear()} ${new Date(
                appointment.time
              ).toLocaleTimeString()}`}</strong>
            </div>
          </div>
          <div style={{ textAlign: "end" }}>
            {appointment.status === 1 ? (
              <Pill bgColor="teal" color="#fff" status={t("Confirmed")} />
            ) : appointment.status === 2 ? (
              <Pill bgColor="#fbd9d6" color="#ee5f54" status={t("Canceled")} />
            ) : appointment.status === 3 ? (
              <Pill bgColor="#d6eddc" color="#34a853" status={t("Completed")} />
            ) : (
              <Pill
                bgColor="#f7b204"
                color="rgb(58 52 34)"
                status={t("Pending")}
              />
            )}
          </div>
        </div>
        {/* </Link> */}

        <span className="custom-divider"></span>
        <div className="custom-card-footer-container mt-3">
          {appointment.status == 3 && (
            <div className="custom-card-footer">
              <span style={{ marginRight: "1rem" }}>
                {t("How was your visit?")}
              </span>
              <Rate
                disabled={submitted || appointment.status === 0}
                count={5}
                value={numOfStars}
                onChange={(value) => addDoctorRating(value)}
                character={<i className="fas fa-star"></i>}
              />
            </div>
          )}
          <div className="card-col-2-div">
            {/* <Link
              // onClick={() => {
              //   dispatch(setCurrentAppointment(appointment))
              //   setShowAppointmentDetails(true)
              // }}
              // to={
              //   // providerType === "doctor"
              //   //   ? `/doctorProfile/${provider.id}/myAppointment`
              //   //   : `/singlelab/${provider.id}/myAppointment`
              //   "/"
              // }
              style={{ color: "black", textDecoration: "none" }}
            > */}
            <button
              onClick={() => {
                setAppointment();
                setShowAppointmentDetails(true);
              }}
              className="pop-up AppointcustomBtn cancel custom-card-button py-2"
            >
              {t("More Details")}
              <img
                src="/assets/arrow-circle-right-sml2.svg"
                alt=""
                className=" mx-1 icon-img-size"
              />
            </button>
            {/* </Link> */}
          </div>
        </div>
        {appointment.status !== 3 && appointment.status !== 2 ? (
          <div className="d-flex justify-content-center align-items-center">
            <button
              disabled={appointment.status === 2}
              onClick={cancelAppointment}
              className={
                "pop-up AppointcustomBtn custom-card-button p-2 w-50 my-2"
              }
            >
              {t("cancel")}
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
      <ReviewModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        rating={numOfStars}
        appointment_id={appointment.id}
        setSubmitted={setSubmitted}
      />
      <AppointmentModal
        appointment={appointment}
        show={showAppointmentDetails}
        onHide={() => setShowAppointmentDetails(false)}
        cancelAppointmentHandler={cancelAppointmentDetails}
      />
      <CancelAppointmentModal
        show={showCancelAppointmentModal}
        onHide={() => setShowCancelAppointmentModal(false)}
        cancelAppointmentHandler={cancelAppointmentHandler}
      />
    </Card>
  ) : viewToggler === "Appointments" &&
    providerType === "user" &&
    // appointment?.provider_notes?.meeting_id && // replaced it with true
    true ? (
    <Card className="custom-card card-shadow">
      <div className="card-row">
        <div
          className="card-div"
          onClick={() => {
            // appointment?.provider_notes?.meeting_id &&
            setAppointmentWithConsultation();
          }}
        >
          <div>
            <img className="appointment-doctor" alt="" src="/assets/logo.svg" />
          </div>
          <div>
            <span>{t("Consultation Session")}</span>

            <div className="card-col-2-div">
              <img
                src="/assets/date.svg"
                alt="date-logo"
                style={{ marginRight: "0.5rem" }}
              />
              <strong>{new Date(appointment.time).toLocaleString()}</strong>
            </div>
          </div>
          <div style={{ textAlign: "end" }}>
            {appointment.status === 1 ? (
              <Pill bgColor="teal" color="#fff" status="Confirmed" />
            ) : appointment.status === 2 ? (
              <Pill bgColor="#fbd9d6" color="#ee5f54" status="Canceled" />
            ) : appointment.status === 3 ? (
              <Pill bgColor="#d6eddc" color="#34a853" status="Completed" />
            ) : (
              <Pill bgColor="#f7b204" color="rgb(58 52 34)" status="Pending" />
            )}
          </div>
        </div>

        <span className="custom-divider"></span>

        {appointment.status !== 3 && (
          <div className="d-flex justify-content-center mt-2 align-items-center">
            <button
              disabled={appointment.status === 2}
              onClick={cancelAppointment}
              className="pop-up AppointcustomBtn custom-card-button p-2 w-50 my-2 disabled"
            >
              {t("cancel")}
            </button>
          </div>
        )}
      </div>

      <CancelAppointmentModal
        show={showCancelAppointmentModal}
        onHide={() => setShowCancelAppointmentModal(false)}
        cancelAppointmentHandler={cancelAppointmentHandler}
      />
    </Card>
  ) : null;
}
