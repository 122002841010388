import { useContext } from "react";
import { langContext } from "../../../context/LangProvider";

export default function PatientBtn() {
  const { t } = useContext(langContext);
  return (
    <div style={{ paddingBottom: "1rem", marginTop: "2rem" }}>
      <button
        className="pop-up customBtn save"
        style={{ marginRight: "1rem" }}
        type="submit"
      >
        {t("Save")}
      </button>
    </div>
  );
}
