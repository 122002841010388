import React, { useEffect, useState } from "react";
import Header from "../components/dashboard/Header";
import Sidebar from "../components/dashboard/SideBar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SerivceEdit from "../components/SingleDoctor/services/ServiceEdit";
import { doctorDashBoardService } from "../services/doctorDashboardService";
import { Spinner } from "react-bootstrap";

export default function DashboardServiceEdit() {

    useEffect(() => {

    }, [])
    const initValues = {
        services: [],
    };

    const navigate = useNavigate();
    const { visible, narrow } = useSelector((state) => state.dashboard);
    const {
        token,
        user,
        status: userStatus,
    } = useSelector((state) => state.user);
    const { doctors, status: doctorsStatus } = useSelector(
        (state) => state.doctors
    );
    const [doctor, setDoctor] = useState(initValues);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true)
        async function getLoggedInDoctor() {
            setLoading(true)
            let res = await doctorDashBoardService.getDoctor().then(async (r) => {
                const data = await r.json()
                setDoctor(data.data)
                setLoading(false)
            })
            console.log(res);
        }
        getLoggedInDoctor()

    }, [])
   
    useEffect(() => {
        // setLoading(false)
        if (!doctor) navigate("/");
    }, [doctor]);
    return (
        <div className="" style={{ minHeight: "100vh" }}>
            <Sidebar />
            <div
                className="dashboard-container"
                style={
                    window.innerWidth > 768 && visible
                        ? narrow
                            ? { marginLeft: "65px" }
                            : { marginLeft: "256px" }
                        : { marginLeft: "0" }
                }
            >
                <Header />
                <div className="dashboard-wrapper">
                    {loading ?
                        <div className="d-flex w-100 h-100 justify-content-center align-items-center"><Spinner animation="grow" /></div>
                        : <SerivceEdit services={doctor.services} doctor={doctor} />}
                </div>
            </div>
        </div>
    );
}
