import { Container } from "react-bootstrap";
import OfferSlider from "../components/AllOffers/offersSlider";
import OffersList from "../components/AllOffers/offers";

export default function Offers() {
  const slides = [
    {
      text: "<h1>Sale Up to <span>50%</span> on Teeth Whitening</h1>",
      image: "/assets/offers-page.png",
      btnText: "Book Now",
      visibility: false,
    },
    {
      text: "<h1>Sale Up to <span>50%</span> on Teeth Whitening</h1>",
      image: "/assets/offers-page.png",
      btnText: "Book Now",
      visibility: false,
    },
  ];

  return (
    <div className=" p-top-50">
      <Container>
        <div className="pt-5 pb-2">
          <OfferSlider slides={slides} />
        </div>
        <OffersList numPerPage={10} />
      </Container>
    </div>
  );
}
