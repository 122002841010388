import { getIntervalsBetweenShifts, isItToday } from "doitfast";

import arrangeDaysFromToday from "./arrangDaysFromToday";

export const getProviderDetails = (page, id, dataExistId, data) => {
  let dataDetails;
  if (page === "doctor") {
    if (id) dataDetails = data.find((d) => +d.id === +id); //to get the doctor data if in doctor profile
    if (dataExistId) dataDetails = data.find((d) => +d.id === +dataExistId); //to give the doctor data to cart if outside doctor profile
  }
  if (page === "lab") {
    if (id) dataDetails = data.find((d) => +d.id === +id); //to get the lab data if in lab profile
    if (dataExistId) dataDetails = data.find((d) => +d.id === +dataExistId); //to give the lab data to cart if outside lab profile
  }
  return dataDetails;
};
export const getLocationsOfCurrentDetailsPage = (
  dataDetails,
  dataName,
  setappointmentVisibilty,
  setViewdDays,
  setSelectedLocation,
  setViewdAppointments
) => {
  let locations = dataDetails.locations?.map((loc, index) => {
    let location;
    //single location so no selection
    if (dataDetails.locations.length === 1) {
      setappointmentVisibilty("");
      console.log("newprovider", dataDetails);
      let sortedAppointments = arrangeDaysFromToday([
        ...dataDetails.locations[0].availabilities?.filter(
          (availability) => availability.closed === false
        ),
      ]);
      if (sortedAppointments.length) {
        let isToday = isItToday(sortedAppointments[0].date);
        if (dataName === "lab") {
          if (
            isToday &&
            +sortedAppointments[0].close_hour.substring(0, 2) <
              new Date().getHours() + 1
          ) {
            sortedAppointments = sortedAppointments.slice(1);
          }
          isToday = false;
        }
        let viewdAppointments;
        if (sortedAppointments.length) {
          viewdAppointments = getIntervalsBetweenShifts(
            sortedAppointments[0].open_hour,
            sortedAppointments[0].close_hour,
            "00:30",
            isToday
          );
        } else {
          viewdAppointments = [];
        }

        setViewdAppointments(viewdAppointments);
      }
      setViewdDays(sortedAppointments);
      location = {
        value: index,
        label: `${loc.street}, ${loc.street_2}, ${loc?.area?.name}`,
        id: loc.area.id,
      };
      setSelectedLocation(loc);
    } else {
      console.log("newprovider", dataDetails);
      location = {
        value: index,
        label: loc.area.name,
        id: loc.area.id,
        location: loc,
      };
    }

    return location;
  });
  return locations;
};
