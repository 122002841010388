import { useState } from "react"
import { CButton } from "@coreui/react";
import Nestable from "react-nestable";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { faqsService } from "../../services/faqsService";
import { useNavigate } from "react-router-dom";
const DoctorFAQs = () => {
    const [inTrashedPage, setInTrashedPage] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const [FAQs, setFAQs] = useState([])
    function addFAQ() {
        navigate('edit')
        // setFAQs([...FAQs, { question: '', answer: '', order: FAQs.length + 1, id: Math.floor(Math.random() * 1000) }])
    }
    
    const fetchDataWithTrashed = () => {
        if (!inTrashedPage) {
            fetchData(1);
            setInTrashedPage(true);
        }
        // setFields([...fields, { key: "Actions", label: "Actions", sorter: false }]);
    };
    const fetchDataWithNoTrashed = () => {
        if (inTrashedPage) {
            fetchData(0);
            setInTrashedPage(false);
        }
        // setFields([...fields.slice(0, -1)]);
    };
    const truncateAnswer = (answer) => {
        if (answer?.length > 50) {
            answer = answer.substr(0, 50) + "...";
        }
        return answer;
    };
    const DeletePage = async (id) => {
        try {
            setLoading(true);
            const json = await faqsService?.deleteFAQ(id, 1); /////////////
            if (json.success) {
                toast.success(json.message);
                setFAQs(FAQs.filter((page) => page.id !== id));
            }
            setLoading(false);
        } catch (error) {
            toast.warning("delete page error");
            console.log("error", error);
        }
    };
    const sortData = (data) => {
        return data?.sort((a, b) => (a.order > b.order ? 1 : -1));
    };
    useEffect(() => {
        fetchData(0);
    }, []);
    const fetchData = async (trashedState) => {
        try {
            setLoading(true);
            const json = await faqsService.getDoctorsFAQs()
            const sortedData = sortData(json.data);
            if (json.success) {
                if (trashedState === 1) {
                    setFAQs(sortedData.filter((faq) => faq.deleted_at));
                } else {
                    setFAQs(sortedData); // to filter out any faq that's not general
                }
            }
            setLoading(false);
        } catch (error) {
            console.log("error", error);
        }
    };
    function renderItem({ item }) {
        return (
            <div
                onClick={() => {
                    if (!inTrashedPage) {
                        navigate(`edit?id=${item.id}`, { state: { id: item.id, question: item.question, answer: item.answer, order: item.order } })
                        // history.push(`/faqs/edit/${item.id}`);
                    }
                }}
                style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "1rem",
                }}
            >
                <div>
                    <h5>{truncateAnswer(item.question)}</h5>
                    <p style={{ marginBottom: 0 }}>{truncateAnswer(item.answer)}</p>
                </div>
                {inTrashedPage ? (
                    <div>
                        <CButton
                            color="danger"
                            className="mx-1"
                            onClick={() => DeletePage(item.id)}
                        >
                            Delete
                        </CButton>
                        <CButton
                            color="success"
                            className="mx-1"
                        >
                            Restore
                        </CButton>
                    </div>
                ) : (
                    <>
                    </>
                )}
            </div>
        )
    }
    useEffect(() => {
        console.log('faqs : ', FAQs);
    }, [FAQs])
    return (
        <div>
            <div className="d-flex justify-content-end ">
                <CButton
                    className="border-dark mx-3 px-3"
                    variant="outline"
                    size="sm"
                    color="info"
                    onClick={() => addFAQ()}
                >
                    <b>+</b> Add FAQ
                </CButton>
            </div>
            {FAQs?.length > 0 ?
                <Nestable
                    items={FAQs}
                    renderItem={renderItem}
                    maxDepth={0}
                    onChange={({ items }) => {
                        if (!items) return
                        let tempItems = items?.map((item, index) => {
                            return { ...item, order: index };
                        });
                        const newItems = JSON.parse(JSON.stringify(tempItems));
                        setFAQs(newItems);
                        tempItems.map((item) => {
                            let id = item.id;
                            let data = new FormData();
                            Object.keys(item).forEach((key) => {
                                data.append(key, item[key]);
                            });
                            faqsService.updateFAQ(data, id, 0);
                        });
                    }}
                /> : <div className="m-3">No available FAQs yet...</div>}
        </div >)
}
export default DoctorFAQs